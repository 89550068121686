import React from 'react'
import { connect } from 'react-redux'
import logo from '../../static/experiencestream_logo.jpg'
import { IApplicationState } from '../../Store'
import EmployeContextProgression from './Punch/EmployeeContextProgression'
import QuestionnaireController from './QuestionnaireController'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => {
  return {
    displayContent: state.display.content,
    questionnaireId: state.questionnaire.questionnaireInfo.id,
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const Content = (props: IAllProps) => {

  let content = <div />

  const containerStyle = props.media.isAtLeastTablet
    ? { width: '100%', flexGrow: 1, display: 'flex', height: '100px' }
    : { margin: '0px 5vw' }

  if (props.questionnaireId === -1) {
    content =
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        <img src={`${logo}`} />
      </div>
  } else if (props.displayContent === 0) {
    content = <QuestionnaireController />
  } else if (props.displayContent === 1) {
    content = <EmployeContextProgression />
  }

  return (
    <div style={containerStyle}>
      {content}
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(Content)
