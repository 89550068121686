import React, { CSSProperties, RefObject } from 'react'

import '../../css/button.css'

interface IProps {
  onClick: () => void
  style?: CSSProperties
  className?: string
  children?: React.ReactNode
  selected?: boolean
}

type refType =
  | string
  | ((instance: HTMLButtonElement | null) => void)
  | RefObject<HTMLButtonElement>
  | null
  | undefined

const Button = React.forwardRef((props: IProps, ref: refType) => (
  <button
    ref={ref}
    onClick={props.onClick}
    style={{
      ...props.style,
    }}
    className={`${props.className} button ${props.selected ? 'selected' : ''}`}
  >
    {props.children}
  </button>
))

export default Button
