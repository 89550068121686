import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { IApplicationState } from '../../Store'
import Button from '../Shared/Button'

interface IProps {
  isOpen: boolean
  handleClose?: () => void
  children: React.ReactNode
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> & IProps

const Popup = (props: IAllProps) => {
  const popup = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgb(0, 0, 0, .5)',
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          top: 0,
          zIndex: 10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            borderRadius: '10px',
            backgroundColor: 'var(--BackgroundPrimary)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 0 10px 0 white',
            padding: '3vh 3vw',
            maxWidth: '85vw',
          }}
        >
          {props.children}
          {
            props.handleClose &&
            <div style={{ marginTop: props.media.isAtLeastTablet ? '3vh' : '5vh' }}>
              <Button
                onClick={() => props.handleClose && props.handleClose()}
                style={{ fontSize: props.media.isAtLeastTablet ? '2vh' : '4vh', fontWeight: 'bold' }}
              >
                <FormattedMessage id='close' />
              </Button>
            </div>
          }
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {props.isOpen && popup()}
    </Fragment>
  )
}

export default connect(
  mapStateToProps,
  null,
)(Popup)
