import React, { CSSProperties } from 'react'
import { connect } from 'react-redux'
import changentLogo from '../../static/changent_logo.jpg'
import { IApplicationState } from '../../Store'
import BranchList from './BranchList'
import LoginForm from './LoginForm'
import OrganizationList from './OrganizationList'
import QuestionnaireList from './QuestionnaireList'

const mainContainer: CSSProperties = {
  position: 'relative',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'left',
  borderRadius: '5px',
  backgroundColor: `var(--BackgroundPrimary)`,
  boxShadow: `0 0 50px 5px var(--BackgroundSecondary)`,
}

const contentContainer: CSSProperties = {
  width: '100%',
  padding: '25px',
  height: '100%',
  boxSizing: 'border-box',
}

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    displayState: state.display,
    languageState: state.language,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const ContextSelectionModal = (props: IAllProps) => {
  let modalContent

  switch (props.displayState.modalPage) {
    case 0:
      modalContent = <LoginForm />
      break
    case 1:
      modalContent = <OrganizationList />
      break
    case 2:
      modalContent = <BranchList />
      break
    case 3:
      modalContent = <QuestionnaireList />
      break
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
      <img src={changentLogo} draggable={false} style={{ position: 'absolute', top: '15vh' }} />
      <div style={{ ...mainContainer }}>
        <div style={{ ...contentContainer }}>
          {modalContent}
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(ContextSelectionModal)
