import ILocation from '../models/ILocation'
import IToken from '../models/IToken'
import { IAuth } from '../Store/Auth'
import { get, put } from './fetch'

interface IServerResponse {
  items: ILocation[]
}

export interface IServerSelectionResponse extends IToken {
  needToSelectBranch: boolean,
}

// Validate if user is SysAdmin
export const validateCanChooseOrg = (): Promise<boolean> =>
  get({ path: `Authentication/Validate/${10000}` })
    .then(res => res.json())
    .then(result => result)

export const getOrganizations =
  (
    options: { startIndex: number, size: number } = { startIndex: 0, size: 20 },
    url: string,
    auth: IAuth,
  ): Promise<ILocation[]> =>
    get({
      hostname: url,
      path: `Location/Organizations/List?startIndex=${options.startIndex}&pageSize=${options.size}`,
      auth,
    })
      .then(res => res.json())
      .then((organizations: IServerResponse) =>
        organizations.items.map(item => ({
          id: item.id,
          name: item.name,
        })),
      )

export const updateSelectedOrganization = (id: number, url: string, auth: IAuth) =>
  put({
    hostname: url,
    path: 'contextselection/selectorganization',
    data: id,
    auth,
  })
    .then(res => res.json())
    .then((selection: IServerSelectionResponse) => {
      return selection
    })
