export interface IEmployeeRatings {
  averageRatingToday: number
  bestRating: number
  totalRatingToday: number
  worstRating: number
  punch: PunchEnum
}

export enum PunchEnum {
  PunchedIn,
  PunchedOut,
}
