import { Language } from '../models/Language'

export const getTranslation = (
  resources: Map<string, string>,
  currentLanguage: Language,
  availableLanguages: Language[],
) => {

  const wanted = resources.get(currentLanguage)

  if (wanted) return wanted

  return availableLanguages.reduce((acc, curr) => {
    if (acc) return acc

    const resWithCurrLang = resources.get(curr)

    if (resWithCurrLang) return resWithCurrLang

    return ''
  }, '')

}
