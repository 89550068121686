export type Coupon = IAppreciationCouponsClient | IEvangelistCouponsClient | IServiceRecoveryCouponsClient

export enum CouponType {
  AppreciationCoupon,
  EvangelistCoupon,
  ServiceRecoveryCoupon,
}

export type ClientResources = Map<string, { body: string, footer: string }>
type ServerResources = Array<{
  body: string,
  footer: string,
  culture: string,
}>

export interface ICoupon<T> {
  id: number
  alwaysPrintWhenConditionMet: boolean
  couponExpires: boolean
  barCodeValue: string
  daysValidAmount: number
  displayOrder: number
  organizationLogo: string
  resourcesList: T
  couponType: CouponType
  printed: boolean | undefined
  success: boolean | undefined
}

export type AppreciationCoupon = IAppreciationCoupons<ClientResources>
interface IAppreciationCoupons<T> extends ICoupon<T> {
  printInterval: number
}

export type EvangelistCoupon = IEvangelistCoupons<ClientResources>
interface IEvangelistCoupons<T> extends ICoupon<T> {
  questionIds: number[]
}

export type ServiceRecoveryCoupon = IServiceRecoveryCoupons<ClientResources>
interface IServiceRecoveryCoupons<T> extends ICoupon<T> {
  questionIds: number[]
}

export interface IAppreciationCouponsClient extends IAppreciationCoupons<ClientResources> {
}

export interface IAppreciationCouponsServer extends IAppreciationCoupons<ServerResources> {
}

export interface IEvangelistCouponsClient extends IEvangelistCoupons<ClientResources> {
}

export interface IEvangelistCouponsServer extends IEvangelistCoupons<ServerResources> {
}

export interface IServiceRecoveryCouponsClient extends IServiceRecoveryCoupons<ClientResources> {
}

export interface IServiceRecoveryCouponsServer extends IServiceRecoveryCoupons<ServerResources> {
}

export const typeofIAppreciationCoupons = (
  coupon: any,
): coupon is IAppreciationCouponsClient => {
  return coupon.couponType === CouponType.AppreciationCoupon
}

export const typeofIEvangelistCoupons = (
  coupon: ICoupon<ClientResources>,
): coupon is IEvangelistCouponsClient => {
  return coupon.couponType === CouponType.EvangelistCoupon
}

export const typeofIServiceRecoveryCoupons = (
  coupon: ICoupon<ClientResources>,
): coupon is IServiceRecoveryCouponsClient => {
  return coupon.couponType === CouponType.ServiceRecoveryCoupon
}
