import { Action, Dispatch, Reducer } from 'redux'
import { IApplicationState } from '..'
import { getCustomColorsFromKey } from '../../fetchers/onTheGo'
import { getCustomStyle } from '../../fetchers/style'
import { EsColor } from '../../models/colors/IEsColor'
import { EsColors } from '../../models/colors/IEsColors'
import IOnTheGoKey from '../../models/IOnTheGoKey'
import { setCSSFromStyleKeyType } from '../../utils/color'

export interface IStyleState {
  style: EsColors | undefined
}

interface IChangeDefaultStyle {
  type: 'CHANGE_STYLE'
  colors: EsColors
}

export type KnownAction = IChangeDefaultStyle

export const styleActionCreators = {
  changeCustomStyle: (key: IOnTheGoKey<number, string>) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const appState = getState()
      const auth = appState.contextSelection.tempAuth || appState.auth.currentAuth
      const isOnTheGo = appState.onTheGo.isOnTheGo

      const customStylePromise = isOnTheGo && key.onTheGoKey
        ? getCustomColorsFromKey(key.onTheGoKey)
        : getCustomStyle(key.key, undefined, auth)

      return customStylePromise
        .then(styles => {
          dispatch({ type: 'CHANGE_STYLE', colors: styles })
        })
    },
  changeStyle: (colors: EsColors) =>
    ({ type: 'CHANGE_STYLE', colors }),
}

const defaultState: IStyleState = {
  style: undefined,
}

export const reducer: Reducer<IStyleState> =
  (state: IStyleState | undefined, incomingAction: Action): IStyleState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction

    switch (action.type) {
      case 'CHANGE_STYLE':
        const upcomingStyle = action.colors

        const displayColors: EsColor[] = []
        for (let i = 0; i < upcomingStyle.colorCount; i++) {
          displayColors.push(upcomingStyle.getColorForKey(i))
        }

        setCSSFromStyleKeyType(displayColors)
        return {
          ...state,
          style: action.colors,
        }
      default:
        return state
    }
  }
