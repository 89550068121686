import React from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { cancelLastPunch, IClockDigitsResponse } from '../../../fetchers/punch'
import { PunchEnum } from '../../../models/IEmployee'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../Store/Display'
import { actionCreators as PunchActionCreators } from '../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import Button from '../../Shared/Button'
import PictureCardImage from '../Questionnaire/PictureQuestion/PictureCard/PictureCardImage'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    code: state.questionnaire.punch.accessCode,
    clockIndexes: state.questionnaire.punch.clock.indexes,
    ratings: state.questionnaire.punch.punchedEmployeeRatings,
    employee: state.questionnaire.punch.selectedEmployee,
    language: state.language,
    serverAddress: state.contextSelection.serverAddress,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const EmployeeConnected = (props: IAllProps) => {

  const backToQuestions = () => {
    props.displayActions.changePunchProgressionPage(0)
    props.displayActions.changeContent(0)
  }

  const cancelPunch = () => {
    if (!props.employee) return

    cancelLastPunch(
      {
        employeeId: props.employee.employeeId,
        accessCode: props.code,
        clockDigitResult: { targetClockDigitIndexes: props.clockIndexes } as IClockDigitsResponse,
        punchTime: new Date(),
        punchType: PunchEnum.PunchedOut,
        sendOffline: !window.navigator.onLine,
      },
    ).then(() => backToQuestions())
  }

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        color: 'white',
      }}
    >
      <p>Vous êtes maintenant... <span>CONNECTÉ</span></p>
      <PictureCardImage
        picture={props.employee}
        serverAddress={props.serverAddress}
        style={{
          width: '10%',
          borderRadius: '5%',
        }}
      />
      <p>{props.employee && props.employee.name}</p>
      <p>Quel sera votre résultat aujourd'hui?</p>
      <p>Votre nom et photo seront maintenant montrés aux clients lorsqu'ils nous denneront leurs impressions.</p>
      <p>Aller! Vas-y champion(ne)!</p>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          onClick={() => cancelPunch()}
          style={{
            fontSize: '22px',
            padding: '6px 85px',
          }}
        >
          Oops!
        </Button>
        <Button
          onClick={() => {
            if (props.employee) props.punchActions.punchEmployee(props.employee.employeeId)
            backToQuestions()
          }}
          style={{
            fontSize: '22px',
            padding: '6px 85px',
          }}
        >
          Continuer
        </Button>
      </div>

    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeConnected)
