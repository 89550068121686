import React, { CSSProperties } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { IEmployeeDepartment, IEmployeeDepartmentQuestion } from '../../../../models/IQuestion'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../../Store/Display'
import { currentAnswerActionCreators } from '../../../../Store/Questionnaire/CurrentAnswer'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import QuestionRow from '../QuestionRow'
import DepartmentButton from './DepartmentButton'

const mapStateToProps = (state: IApplicationState) => {
  return {
    currentLanguage: state.language.currentLanguage,
    isAtLeastTablet: state.display.media.isAtLeastTablet,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    question: IEmployeeDepartmentQuestion,
  }

const EmployeeDepartmentQuestionPage = (props: IAllProps) => {

  const questionRowStyle: CSSProperties = props.isAtLeastTablet
    ? {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      height: 'fit-content',
      minHeight: '7.5vh',
    }
    : {
      display: 'flex',
      margin: '20px auto 0 auto',
      height: 'unset',
      minHeight: '8vh',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
    }

  const mapEmployeeDepartments = (dept: IEmployeeDepartment) =>
    <DepartmentButton
      key={dept.id}
      department={dept}
      question={props.question}
      onClick={() => {
        props.currentAnswerActions.changeSelectedDepartment(dept.id)
        props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
      }}
    />

  return (
    <QuestionRow
      questionName={props.question.name}
    >
      <div
        style={questionRowStyle}
      >
        {
          props.question.groups.map(mapEmployeeDepartments)
        }
      </div>
    </QuestionRow>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeDepartmentQuestionPage)
