import { addLocaleData } from 'react-intl'
import locale_en from 'react-intl/locale-data/en'
import locale_fr from 'react-intl/locale-data/fr'
import locale_zh from 'react-intl/locale-data/zh'

import IMessage from '../models/IMessage'
import messages_en from '../static/locales/en-CA/translation.json'
import messages_fr from '../static/locales/fr-CA/translation.json'
import messages_zh from '../static/locales/zh-CN/translation.json'

export enum Language {
  FR = 'fr-CA',
  EN = 'en-CA',
  ZH = 'zh-CN',
}

addLocaleData([...locale_en, ...locale_fr, ...locale_zh])

const messages: IMessage = {
  'en-CA': messages_en,
  'fr-CA': messages_fr,
  'zh-CN': messages_zh,
}

export const getMessages = () => {
  return messages
}
