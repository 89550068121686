import React from 'react'

const DownArrowSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='118.556'
    height='129.972'
    viewBox='0 0 118.556 129.972'
  >
    <path
      style={{ fill: 'var(--GeneralFontColor)' }}
      d='M29.519,40v70.694H0l59.871,59.278,58.685-59.278H89.037V40Z'
      transform='translate(0 -40)'
    />
  </svg>
)

export default DownArrowSvg
