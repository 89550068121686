import React, { CSSProperties, RefObject, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../css/slide-show.css'
import { IApplicationState } from '../../Store'
const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    contextSelectionState: state.contextSelection,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {

  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    pictureId: number,
    style: CSSProperties,
    contentRef?: (instance: HTMLImageElement | null) => void
    defaultImage?: string,
  }

type refType =
  | string
  | ((instance: HTMLImageElement | null) => void)
  | RefObject<HTMLImageElement>
  | null
  | undefined

const Image = React.forwardRef((props: IAllProps, ref: refType) => {
  const [displayImage, setDisplayImage] = useState(true)

  const imgSrc = displayImage
    ? props.pictureId >= 0
      ? `${props.contextSelectionState.serverAddress}/api/images/${props.pictureId}?width=400&height=400`
      : props.defaultImage
    : props.defaultImage

  const removeImage = () => setDisplayImage(false)

  return (
    <img
      draggable={false}
      style={{
        ...props.style,
      }}
      src={imgSrc}
      onError={() => removeImage()}
      ref={ref}
    />
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(Image)
