import QuestionResultType from './enums/QuestionResultType'
import { Picture } from './IPicture'
import { IPictureQuestion } from './IPictureQuestion'

export type Question =
  | IAnswerQuestion
  | IPictureQuestion
  | IEmployeeDepartmentQuestion

export type QuestionResult =
  | IAnswerChoice
  | Picture

export enum QuestionResultSentiment {
  Bad,
  Neutral,
  Good,
}

export const typeOfAnswerQuestion = (
  question: IQuestion,
): question is IAnswerQuestion => {
  return 'answerChoices' in question
}

export const typeOfPictureQuestion = (
  question: IQuestion,
): question is IPictureQuestion => {
  return 'pictures' in question
}

export const typeOfEmployeeDepartmentQuestion = (
  question: IQuestion,
): question is IEmployeeDepartmentQuestion => {
  return 'groups' in question
}

export interface IAnswerChoice {
  type: QuestionResultType,
  id: number,
  displayOrder: number,
  name: Map<string, string>,
  report: Map<string, string>,
  answerChoiceType: QuestionResultSentiment,
}

export interface IAnswerQuestion extends IQuestion {
  answerChoices: IAnswerChoice[]
}

export interface IEmployeeDepartment {
  id: number
  title: Map<string, string>
}

export interface IEmployeeDepartmentQuestion extends IQuestion {
  groups: IEmployeeDepartment[]
}

export interface IQuestion {
  id: number
  name: Map<string, string>
  displayOrder: number
}
