import he from 'he'
import React, { CSSProperties, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import { ICustomPicture, Picture } from '../../../../../models/IPicture'
import { IPictureQuestion } from '../../../../../models/IPictureQuestion'
import { IApplicationState } from '../../../../../Store/index'
import { getCssColorForValue, RGBColor } from '../../../../../utils/color'
import PictureCardImage from '../PictureCard/PictureCardImage'
import VerticalSlider from './VerticalSlider'

const colors = [
  // Red
  [255, 0, 0],
  // Yellow
  [255, 255, 0],
  // Green
  [0, 128, 0],
] as [RGBColor, RGBColor, RGBColor]

const mapStateToProps = (state: IApplicationState) => {
  return {
    selectedPicture: state.questionnaire.selectedPicture,
    answers: state.questionnaire.currentAnswer.answers,
    currentLanguage: state.language.currentLanguage,
    media: state.display.media,
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & {
    question: IPictureQuestion
    picture: Picture,
    setRating: (rating: number | undefined) => void,
  }

const PictureCard = (props: IAllProps) => {
  const questionResults = props.answers.get(props.question)
  const answer =
    questionResults &&
    questionResults.find(x => x.id === props.picture.id) as ICustomPicture

  const [sliderValue, setSliderValue] = useState(50)

  useEffect(() => {
    const answerValue = (answer && answer.value != null)
      ? answer.value
      : 50

    setSliderValue(answerValue)
  }, [answer])

  const selectedColor = getCssColorForValue(
    0,
    100,
    props.selectedPicture.id === props.picture.id
      ? sliderValue
      : answer && answer.value || 0,
    colors,
  )

  const imageStyle: CSSProperties = props.media.isAtLeastTablet
    ? {
      width: 'calc(12.5vh - 10px)',
      height: 'calc(12.5vh - 10px)',
    }
    : {
      width: 'calc(20vh - 10px)',
      height: 'calc(20vh - 10px)',
    }

  const zoomInPictureStyle: CSSProperties = props.media.isAtLeastTablet
    ? {
      width: '25vh',
      left: 'calc(50% - 25vh / 2)',
    }
    : {
      width: '40vh',
      left: 'calc(50% - 40vh / 2)',
    }

  return (
    <div
      onClick={() => props.setRating(undefined)}
      className='picture-card'
    >
      <div className='relative-container'>
        <CSSTransition
          in={props.selectedPicture.mounted && props.selectedPicture.id === props.picture.id}
          timeout={{ enter: 750, exit: 750 }}
          classNames='fade'
        >
          {
            !(
              props.selectedPicture.id === props.picture.id &&
              props.selectedPicture.zoomed &&
              props.selectedPicture.zoomCompleted
            )
              ? <></>
              : <>
                <div className='picture-title'>
                  <h2><FormattedMessage id='pic-question-1' /></h2>
                  <h5><FormattedMessage id='pic-instruction' /></h5>
                </div>
                <div
                  className='picture-slider'
                >
                  <VerticalSlider
                    min={0}
                    max={100}
                    value={sliderValue}
                    colors={colors}
                    onChange={setSliderValue}
                    onAfterChange={() => props.setRating(sliderValue)}
                  />
                </div>
              </>
          }
        </CSSTransition>

        <PictureCardImage
          picture={props.picture}
          style={{
            borderRadius: '50%',
            ...imageStyle,
            ...(
              (
                answer ||
                (
                  props.selectedPicture.zoomed &&
                  props.selectedPicture.zoomCompleted &&
                  props.selectedPicture.id === props.picture.id
                )
              )
                ? {
                  border: '5px solid',
                  borderColor: selectedColor,
                }
                : {
                  margin: '5px',
                }
            ),
          }}
        />
        <h1
          style={{
            margin: '0px auto',
            ...(
              !(props.selectedPicture.zoomed && props.selectedPicture.id === props.picture.id)
                ? {
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: 'inherit',
                  textOverflow: 'ellipsis',
                  fontSize: '2.5vh',
                }
                : {
                  ...zoomInPictureStyle,
                  position: 'relative',
                  wordBreak: 'break-word',
                }
            ),
            ...(
              (
                answer ||
                (
                  props.selectedPicture.zoomed &&
                  props.selectedPicture.zoomCompleted &&
                  props.selectedPicture.id === props.picture.id
                )
              )
                ? {
                  color: selectedColor,
                }
                : {}
            ),
          }}
        >
          {
            typeof props.picture.name === 'string'
              ? he.decode(props.picture.name)
              : he.decode(
                props
                  .picture
                  .name
                  .get(props.currentLanguage) || '',
              )
          }
        </h1>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true },
)(PictureCard)
