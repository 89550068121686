import { IEmployeeRatings, PunchEnum } from '../models/IEmployee'
import { IEmployeePicture } from '../models/IPicture'
import { IAuth } from '../Store/Auth'
import { get, post } from './fetch'

interface IEmployeePictureServerResonse {
  employeeId: number
  id: number
  imageSrc: number
  name: string
  accessCode: string
}

export interface IClockDigitsResponse {
  targetClockDigitIndexes: number[]
}

export interface IPunchRequest {
  employeeId: number
  accessCode: string
  clockDigitResult: IClockDigitsResponse
  sendOffline: boolean
  punchTime: Date
  punchType: PunchEnum
}

interface IPunchResponse {
  employee: {
    averageRatingToday: number
    bestRating: number
    totalRatingToday: number
    worstRating: number,
  }
  punch: PunchEnum
  success: boolean
}

export const getEmployeeSelectionList = (id: number, auth?: IAuth): Promise<IEmployeePicture[]> =>
  get({
    path: `experiencestream3/${id}/punch`,
    auth,
  })
    .then(res => res.json())
    .then((employees: IEmployeePictureServerResonse[]) =>
      employees.map((employee: IEmployeePictureServerResonse) => ({
        employeeId: employee.employeeId,
        id: employee.id,
        name: employee.name,
        imageSrc: employee.imageSrc,
        accessCode: employee.accessCode,
      }) as IEmployeePicture))

export const getPunchedInEmployees = (id: number, auth?: IAuth): Promise<IEmployeePicture[]> =>
  get({
    path: `experiencestream3/${id}/punch/in`,
    auth,
  })
    .then(res => res.json())
    .then((employees: IEmployeePictureServerResonse[]) =>
      employees.map((employee: IEmployeePictureServerResonse) => ({
        employeeId: employee.employeeId,
        id: employee.id,
        name: employee.name,
        imageSrc: employee.imageSrc,
        accessCode: employee.accessCode,
      }) as IEmployeePicture))

export const getClockIndexes = (auth?: IAuth): Promise<number[]> =>
  get({
    path: `experiencestream3/punch/indexes`,
    auth,
  })
    .then(res => res.json())
    .then((indexes: IClockDigitsResponse) => indexes.targetClockDigitIndexes)

export const punchEmployee = (data: IPunchRequest) =>
  post({
    path: `experiencestream3/punch/punchemployee`,
    data,
  })
    .then(res => res.json())
    .then((ratings: IPunchResponse) => {
      return ratings.success
        ? {
          averageRatingToday: ratings.employee.averageRatingToday,
          bestRating: ratings.employee.bestRating,
          totalRatingToday: ratings.employee.totalRatingToday,
          worstRating: ratings.employee.worstRating,
          punch: ratings.punch,
        } as IEmployeeRatings
        : false
    })

export const cancelLastPunch = (data: IPunchRequest) =>
  post({
    path: `experiencestream3/punch/cancel`,
    data,
  })
    .then(res => res.json())
