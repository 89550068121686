import React, { CSSProperties, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { bindActionCreators, Dispatch } from 'redux'
import '../../../../css/answer-choice.css'
import IAnswerChoiceQuestion from '../../../../models/IAnswerChoiceQuestion'
import { IAnswerChoice } from '../../../../models/IQuestion'
import { IApplicationState } from '../../../../Store/index'
import { currentAnswerActionCreators } from '../../../../Store/Questionnaire/CurrentAnswer'
import QuestionRow from '../QuestionRow'
import AnswerChoiceAlternateMessage from './AnswerChoiceAlternateMessage'
import AnswerChoiceButton from './AnswerChoiceButton'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => ({
  answers: state.questionnaire.currentAnswer.answers,
  languageState: state.language,
  displayState: state.display,
  areYouStillThereDisplayed: state.display.areYouStillThereIsDisplayed,
  media: state.display.media,
  questionsSize: state.questionnaire.questions.questions.length,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
) => ({
  currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
})

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    question: IAnswerChoiceQuestion,
    canAnswer: boolean,
    canRemove: boolean,
    isAtLastQuestion: boolean,
  }

const AnswerChoiceQuestion = (props: IAllProps) => {
  const [animationEnded, setAnimationEnded] = useState(false)

  const selectAnswer = (answer: IAnswerChoice) => {
    if (!props.canAnswer) return

    props.currentAnswerActions.changeAnswer(props.question, answer)
  }

  const mapAnswers = (answerChoice: IAnswerChoice) =>
    <AnswerChoiceButton
      key={answerChoice.id}
      answerChoice={answerChoice}
      question={props.question}
      onClick={selectAnswer}
    />

  useEffect(() => {
    if (props.areYouStillThereDisplayed || props.answers.size !== 0) return

    setAnimationEnded(false)
  }, [props.areYouStillThereDisplayed])

  const questionRowStyle: CSSProperties = props.media.isAtLeastTablet
    ? {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
      height: '100%',
      minHeight: '7.5vh',
    }
    : {
      display: 'flex',
      margin: '20px auto 0 auto',
      height: 'unset',
      minHeight: '8vh',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'auto',
      overflowX: 'hidden',
    }

  const needToBeRemoved = () => {
    const questionIndex = Array
      .from(props.answers.keys())
      .findIndex(x => x === props.question)

    return props.media.isAtLeastTablet
      ? questionIndex < props.answers.size - 1 && questionIndex !== -1
      : questionIndex !== -1
  }

  const selectedAnswers = props.answers && props.answers.get(props.question)
  const selectedAnswer = selectedAnswers && selectedAnswers[0]

  return (
    <div
      className='question answer-choice-question'
      style={{
        display: (props.isAtLastQuestion || needToBeRemoved()) && props.canRemove
          ? 'none'
          : 'flex',
        opacity: props.canAnswer ? 1 : 0.5,
        pointerEvents: props.canAnswer ? 'auto' : 'none',
      }}
    >
      {
        selectedAnswer &&
        <CSSTransition
          in={animationEnded}
          timeout={1000}
          classNames='answer-choice-question-fade-in'
          mountOnEnter
        >
          <AnswerChoiceAlternateMessage selectedAnswer={selectedAnswer} />
        </CSSTransition>
      }
      <CSSTransition
        in={selectedAnswer == null}
        timeout={1000}
        classNames='answer-choice-question-fade-in'
        onExit={() => setAnimationEnded(true)}
        unmountOnExit
      >
        <QuestionRow
          questionName={props.question.name}
        >
          <div
            style={questionRowStyle}
          >
            {
              props
                .question
                .answerChoices
                .concat()
                .sort((a1, a2) => a1.displayOrder < a2.displayOrder ? -1 : 1)
                .map(mapAnswers)
            }
          </div>
        </QuestionRow>
      </CSSTransition>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnswerChoiceQuestion)
