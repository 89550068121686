import { EsColor } from '../models/colors/IEsColor'
import AlphaHEX from '../models/enums/AlphaHex'
import StyleKeyType from '../models/enums/StyleKeyType'
import { DarkerToneAmount, LighterToneAmount } from '../models/enums/ToneDiffAmount'

export type RGBColor = [number, number, number]

export const getCssColorForValue = (
  min: number,
  max: number,
  value: number,
  rgbColors: [RGBColor, RGBColor, RGBColor],
) => {
  const centerValue = (max - min) / 2

  // INFO (alexsass) : This his only to determinate the max color.
  // Since that color may change. These will not be use in the end for the
  // slider values. Since we need 2 specific colors when we try to derminate
  // the values.
  const minColor = value > centerValue
    ? rgbColors[1]
    : rgbColors[0]

  const maxColor = value > centerValue
    ? rgbColors[2]
    : rgbColors[1]

  return hexToCss(
    pickHex(
      maxColor,
      minColor,
      value > centerValue
        ? (value - centerValue) / centerValue
        : value / centerValue,
    ),
  )
}

export const pickHex = (
  rgbColorStart: RGBColor,
  rgbColorEnd: RGBColor,
  percentage: number,
) => {
  const p = percentage > 1
    ? percentage / 100
    : percentage

  const w = p * 2 - 1
  const w1 = (w / 1 + 1) / 2
  const w2 = 1 - w1

  const rgb = [
    Math.round(rgbColorStart[0] * w1 + rgbColorEnd[0] * w2),
    Math.round(rgbColorStart[1] * w1 + rgbColorEnd[1] * w2),
    Math.round(rgbColorStart[2] * w1 + rgbColorEnd[2] * w2),
  ]

  return rgb as RGBColor
}

export const hexToCss = (color: RGBColor) => {
  const length = color.length

  return color.reduce(
    (acc, current, index) => {
      if (index !== length - 1) {
        return acc + `${current},`
      } else {
        return acc + `${current})`
      }
    },
    'rgb(',
  )
}

export const lightenDarkenColor = (color: string | null, amount: DarkerToneAmount | LighterToneAmount) => {
  if (!color) return null

  const isLightOrDark = isLightOrDarkToneOfColor(color)

  if (isLightOrDark === 'dark' && amount < 0) amount *= -1
  else if (isLightOrDark === 'light' && amount > 0) amount *= -1

  let usePound = false

  if (color[0] === '#') {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)

  // tslint:disable-next-line: no-bitwise
  let r = (num >> 16) + amount

  if (r > 255) r = 255
  else if (r < 0) r = 0

  // tslint:disable-next-line: no-bitwise
  let b = ((num >> 8) & 0x00FF) + amount

  if (b > 255) b = 255
  else if (b < 0) b = 0

  // tslint:disable-next-line: no-bitwise
  let g = (num & 0x0000FF) + amount

  if (g > 255) g = 255
  else if (g < 0) g = 0

  // tslint:disable-next-line: no-bitwise
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

const isLightOrDarkToneOfColor = (color: string) => {

  // Variables for red, green, blue values
  let r
  let g
  let b
  let hsp
  // tslint:disable-next-line: radix
  const colorHEX = parseInt('0x' + color.slice(1))

  // tslint:disable-next-line: no-bitwise
  r = colorHEX >> 16 & 255
  // tslint:disable-next-line: no-bitwise
  g = colorHEX >> 8 & 255
  // tslint:disable-next-line: no-bitwise
  b = colorHEX & 255
  // }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b),
  )

  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5
    ? 'light'
    : 'dark'
}

export const setCSSFromStyleKeyType = (style: EsColor[]) => {
  const addColor = (varName: string, color: string | null) => {
    if (!color) return

    document
      .documentElement
      .style
      .setProperty(
        `--${varName}`,
        color,
      )
  }

  const colorOrNull = (styleKeyType: StyleKeyType) => {
    const color = style.find(x => x.keyType === styleKeyType)
    return color ? color.color : null
  }

  const addAlpha = (color: string | null, amount: AlphaHEX) => {
    return color
      ? `${color}${amount}`
      : null
  }

  /* Set style variables for each StyleKeyType */
  style.forEach((value, key) => addColor(`${StyleKeyType[key]}`, value.color))

  /* Additionnal style variables */

  // --BackgroundPrimaryAlpha50
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundPrimary]}Alpha50`,
    addAlpha(colorOrNull(StyleKeyType.BackgroundPrimary), AlphaHEX['50%']),
  )

  // --BackgroundPrimary2
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundPrimary]}2`,
    lightenDarkenColor(colorOrNull(StyleKeyType.BackgroundPrimary), DarkerToneAmount['50%']),
  )

  // --BackgroundPrimary2Alpha50
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundPrimary]}2Alpha50`,
    addAlpha(lightenDarkenColor(colorOrNull(StyleKeyType.BackgroundPrimary), DarkerToneAmount['50%']), AlphaHEX['50%']),
  )

  // --BackgroundPrimaryAlpha0
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundPrimary]}Alpha0`,
    addAlpha(colorOrNull(StyleKeyType.BackgroundPrimary), AlphaHEX['0%']),
  )

  // --BackgroundPrimaryAlpha90
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundPrimary]}Alpha90`,
    addAlpha(colorOrNull(StyleKeyType.BackgroundPrimary), AlphaHEX['90%']),
  )

  // --BackgroundSecondary2
  addColor(
    `${StyleKeyType[StyleKeyType.BackgroundSecondary]}2`,
    lightenDarkenColor(colorOrNull(StyleKeyType.BackgroundSecondary), LighterToneAmount['40%']),
  )

  // --ButtonSecondary2
  addColor(
    `${StyleKeyType[StyleKeyType.ButtonSecondary]}2`,
    lightenDarkenColor(colorOrNull(StyleKeyType.ButtonSecondary), DarkerToneAmount['60%']),
  )
}

export const isExperienceStreamColor = (colorMap: EsColor[]) => {
  const getDefaultColor = (key: StyleKeyType) => {
    switch (StyleKeyType[key]) {
      case StyleKeyType[StyleKeyType.BackgroundPrimary]:
        return '#003200'
      case StyleKeyType[StyleKeyType.BackgroundSecondary]:
        return '#001e00'
      case StyleKeyType[StyleKeyType.ButtonPrimary]:
        return '#ffff00'
      case StyleKeyType[StyleKeyType.ButtonSecondary]:
        return '#ffa500'
      case StyleKeyType[StyleKeyType.GeneralFontColor]:
        return '#ffffff'
      case StyleKeyType[StyleKeyType.ButtonFontColor]:
        return '#664400'
    }
  }

  let isMatch = true

  colorMap.forEach(value => {
    if (getDefaultColor(value.keyType) !== value.color) {
      isMatch = false
    }
  })

  return isMatch
}
