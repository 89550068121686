import { Action, Dispatch, Reducer } from 'redux'
import { IApplicationState } from '..'
import { getQuestionsFromKey } from '../../fetchers/onTheGo'
import { getQuestions } from '../../fetchers/questions'
import IOnTheGoKey from '../../models/IOnTheGoKey'
import { IEmployeeDepartmentQuestion, Question, typeOfEmployeeDepartmentQuestion } from '../../models/IQuestion'
import { SelectedPictureBarType } from '../../models/IQuestionnaire'
import { QuestionnaireContentPage } from '../Display'

export interface IQuestionsState {
  departmentQuestion: IEmployeeDepartmentQuestion | undefined
  questions: Question[]
  pictureQuestionType: SelectedPictureBarType
}

interface IChangeQuestions {
  type: 'CHANGE_QUESTIONS'
  questions: Question[]
}
interface IChangeEmployeeDepartmentQuestion {
  type: 'CHANGE_EMPLOYEE_DEPARTMENT_QUESTION'
  question: IEmployeeDepartmentQuestion | undefined
}
interface IChangePictureQuestionType {
  type: 'CHANGE_PICTURE_QUESTION_TYPE'
  pictureType: SelectedPictureBarType
}
interface IResetQuestions {
  type: 'RESET_QUESTIONS'
}

export type KnownAction =
  | IChangeQuestions
  | IChangeEmployeeDepartmentQuestion
  | IChangePictureQuestionType
  | IResetQuestions

export const questionsActionCreators = {
  changeQuestions: (key: IOnTheGoKey<number, string>, pictureType: SelectedPictureBarType) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const appState = getState()
      const serverAddress = appState.contextSelection.serverAddress
      const auth = appState.contextSelection.tempAuth || appState.auth.currentAuth
      const isOnTheGo = appState.onTheGo.isOnTheGo

      const questionsPromise = isOnTheGo && key.onTheGoKey
        ? getQuestionsFromKey(key.onTheGoKey)
        : getQuestions(key.key, serverAddress, auth)

      return questionsPromise
        .then((questions: Question[]) => {
          const departmentQuestions = questions.filter(typeOfEmployeeDepartmentQuestion)
          const otherQuestions = questions.filter(x => !typeOfEmployeeDepartmentQuestion(x))

          let departmentQuestion: IEmployeeDepartmentQuestion | undefined =
            departmentQuestions && departmentQuestions[0]

          if (departmentQuestion && departmentQuestion.groups.length === 0) {
            departmentQuestion = undefined
          }

          if (departmentQuestion) {
            dispatch({
              type: 'CHANGE_QUESTIONNAIRE_CONTENT',
              content: QuestionnaireContentPage.EmployeeDepartment,
            })
          }

          dispatch({
            type: 'CHANGE_EMPLOYEE_DEPARTMENT_QUESTION',
            question: departmentQuestion,
          })
          dispatch({
            type: 'CHANGE_QUESTIONS',
            questions: otherQuestions
              .concat()
              .sort((q1, q2) => q1.displayOrder < q2.displayOrder ? -1 : 1),
          })
          dispatch({ type: 'CHANGE_PICTURE_QUESTION_TYPE', pictureType })
        })
    },
  resetQuestions: () =>
    ({ type: 'RESET_QUESTIONS' }),
}

const defaultState: IQuestionsState = {
  departmentQuestion: undefined,
  questions: [],
  pictureQuestionType: SelectedPictureBarType.None,
}

export const reducer: Reducer<IQuestionsState> =
  (state: IQuestionsState | undefined, incomingAction: Action): IQuestionsState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_QUESTIONS':
        return {
          ...state,
          questions: action.questions,
        }
      case 'CHANGE_EMPLOYEE_DEPARTMENT_QUESTION':
        return {
          ...state,
          departmentQuestion: action.question,
        }
      case 'CHANGE_PICTURE_QUESTION_TYPE':
        return {
          ...state,
          pictureQuestionType: action.pictureType,
        }
      case 'RESET_QUESTIONS':
        return defaultState
      default:
        return state
    }
  }
