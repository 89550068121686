import { appStore } from '..'
import { IAuth } from '../Store/Auth'

const getUrl = (
  path: string,
  url?: string,
  isResource?: boolean,
) => {
  const state = appStore.getState()

  const serverAddress = url != null
    ? url
    : state.contextSelection.serverAddress

  if (isResource) {
    return serverAddress.endsWith('/')
      ? `${serverAddress}${path}`
      : `${serverAddress}/${path}`
  }

  return serverAddress.endsWith('/')
    ? `${serverAddress}api/${path}`
    : `${serverAddress}/api/${path}`
}

const getHeader = () => {
  const headers = new Headers()

  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')

  return headers
}

const shouldRefreshToken = (expiration: string) => {
  const expirationDate = new Date(expiration)
  expirationDate.setMinutes(expirationDate.getMinutes() - 2)

  return expirationDate <= new Date()
}

const refreshToken = (rToken: string, isCurrentAuth: boolean) => {
  const headers = getHeader()
  headers.append('Authorization', `Bearer ${rToken}`)

  const options = {
    headers,
    method: 'GET',
    mode: 'cors' as 'cors',
  }

  return fetch(getUrl('Login/token/refresh'), options)
    .then(res => res.json())
    .then((connectionInfo: IAuth) => {
      if (isCurrentAuth) {
        appStore.dispatch({ type: 'CHANGE_CURRENT_AUTH', auth: connectionInfo })
      }

      return connectionInfo
    })
}

const customFetch = (
  path: string,
  data: unknown,
  method: string,
  url?: string,
  specifiedAuth?: IAuth,
  sendTokenInRequest = true,
  isResource = false,
): Promise<Response> => {
  const auth = specifiedAuth
    ? specifiedAuth
    : appStore.getState().auth.currentAuth

  // If the token is expired, we should not try to make the real request yet
  // since it is going to fail for sure. Instead, we do the request to
  // reset the token. Once that is done, we can process with the original
  // request.
  if (auth && shouldRefreshToken(auth.expiration)) {
    return refreshToken(auth.refreshToken, specifiedAuth === undefined)
      .then(newAuth => customFetch(path, data, method, url, newAuth))
      .catch(err => (console.error('Not able to refresh token, error: ' + err), err))
  }

  const headers = getHeader()

  if (auth && sendTokenInRequest) {
    headers.append('Authorization', `Bearer ${auth.token}`)
  }

  let options: RequestInit = {
    headers,
    method,
    mode: 'cors' as 'cors',
  }

  if (method === 'POST' || method === 'PUT') {
    options = {
      ...options,
      body: JSON.stringify(data),
    }
  }

  return fetch(getUrl(path, url, isResource), options)
    .catch(res => {
      // TODO (alexsass) : Here we should check first if the error
      // was related to the authentication of the user or if it was
      // something else.
      if (auth) {
        return refreshToken(auth.refreshToken, specifiedAuth !== undefined)
          .then(newAuth => customFetch(path, data, method, url, newAuth))
      } else return res
    })
}

interface IRequestParams {
  auth?: IAuth
  hostname?: string
  path: string
  sendTokenInRequest?: boolean
  isResource?: boolean
}

// tslint:disable-next-line: no-empty-interface
export interface IGetRequestParams extends IRequestParams { }
export interface IPostRequestParams extends IRequestParams {
  data: unknown
}
export interface IPutRequestParams extends IRequestParams {
  data: unknown
}

export const get = (params: IGetRequestParams) =>
  customFetch(
    params.path,
    {},
    'GET',
    params.hostname,
    params.auth,
    params.sendTokenInRequest,
    params.isResource,
  )

export const post = (params: IPostRequestParams) =>
  customFetch(
    params.path,
    params.data,
    'POST',
    params.hostname,
    params.auth,
    params.sendTokenInRequest,
    params.isResource,
  )

export const put = (params: IPutRequestParams) =>
  customFetch(
    params.path,
    params.data,
    'PUT',
    params.hostname,
    params.auth,
    params.sendTokenInRequest,
    params.isResource,
  )
