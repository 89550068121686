// tslint:disable: max-line-length
import ShellConnecter from '../dependencies/shell-connecter'
import ICouponResultDto from '../models/dtos/ICouponResultDto'
import IQuestionnaireResultDto from '../models/dtos/IQuestionnaireResultDto'
import { IAnswerChoiceQuestionResultDto, ICustomPictureChoiceQuestionResultDto, IEmployeePictureChoiceQuestionResultDto, IQuestionResultDto } from '../models/dtos/IQuestionResultDto'
import QuestionResultType from '../models/enums/QuestionResultType'
import IOnTheGoKey from '../models/IOnTheGoKey'
import { ICustomPicture, IEmployeePicture } from '../models/IPicture'
import { IPictureQuestion } from '../models/IPictureQuestion'
import { IAnswerQuestion, IEmployeeDepartmentQuestion } from '../models/IQuestion'
import { IAnswerChoice, Question, QuestionResult } from '../models/IQuestion'
import { IResultToSave } from '../models/IResultToSave'
import { read, save } from '../utils/localStorage'
import { post } from './fetch'
import { updateVisit } from './onTheGo'
// tslint:enable: max-line-length

export const mapAnswerQuestionToLocalStorage = (question: IAnswerQuestion) => {
  return {
    ...question,
    name: Array.from(question.name),
    answerChoices: question.answerChoices.map(answer => (
      {
        ...answer,
        name: Array.from(answer.name),
        report: Array.from(answer.report),
      }
    )),
  }
}

export const mapPictureQuestionToLocalStorage = (question: IPictureQuestion) => {
  return {
    ...question,
    name: Array.from(question.name),
    pictures: question.pictures.map(picture => {
      // TODO (olibou): fix type for the return value being different of the actual type
      let returnPicture: any = {
        ...picture,
      }

      if (!(typeof picture.name === 'string')) {
        returnPicture = {
          ...returnPicture,
          name: Array.from(picture.name),
        }
      }

      if (!(typeof picture.report === 'string')) {
        returnPicture = {
          ...returnPicture,
          report: Array.from(picture.report),
        }
      }

      return returnPicture
    }),
  }
}

export const mapEmployeeDepartmentQuestionToLocalStorage = (question: IEmployeeDepartmentQuestion) => ({
  ...question,
  name: Array.from(question.name),
  groups: question.groups.map(x => ({
    id: x.id,
    title: Array.from(x.title),
  })),
})

const mapToQuestionResultDto = (
  culture: string,
  question: Question,
  questionResult: QuestionResult,
): (IQuestionResultDto | undefined) => {
  if (questionResult.type === QuestionResultType.AnswerChoice) {
    const answerChoice = questionResult as IAnswerChoice
    return {
      id: question.id,
      culture,
      report: answerChoice.report.get(culture),
      type: answerChoice.type,
      questionName: question.name.get(culture),
      answerChoiceId: answerChoice.id,
      answerChoiceName: answerChoice.name.get(culture),
    } as IAnswerChoiceQuestionResultDto
  } else if (questionResult.type === QuestionResultType.CustomPictureChoice) {
    const customPicture = questionResult as ICustomPicture
    return {
      id: question.id,
      culture,
      report: customPicture.report.get(culture),
      type: customPicture.type,
      pictureBarId: question.id,
      pictureBarName: question.name.get(culture),
      pictureId: customPicture.id,
      pictureName: customPicture.report.get(culture),
      rating: customPicture.value,
      ratingIsDefaultValue: false,
    } as ICustomPictureChoiceQuestionResultDto
  } else if (questionResult.type === QuestionResultType.EmployeePictureChoice) {
    const employeePicture = questionResult as IEmployeePicture
    return {
      id: question.id,
      culture,
      report: employeePicture.report,
      type: employeePicture.type,
      employeeId: employeePicture.employeeId,
      rating: employeePicture.value,
      ratingIsDefaultValue: false,
    } as IEmployeePictureChoiceQuestionResultDto
  }
}

const questionnaireResultMapper = (
  resultToSave: IResultToSave,
): IQuestionnaireResultDto => {
  const questionResultsDto = Array.from(resultToSave.questionnaireResults)
    .reduce(
      (acc, questionResult) => {
        const question = questionResult[0]
        const results = questionResult[1]

        const qrDto = results
          .map(result => mapToQuestionResultDto(
            resultToSave.language,
            question,
            result,
          ))
          .filter(x => !!x) as IQuestionResultDto[]

        return [
          ...acc,
          ...qrDto,
        ]
      },
      [] as IQuestionResultDto[],
    )

  return {
    questionnaireId: resultToSave.questionnaireId,
    computerFingerPrint: resultToSave.deviceId,
    responseTimeSec: resultToSave.responseTime,
    responseCulture: resultToSave.language,
    dateRecorded: new Date(),
    clientResponseGuid: resultToSave.clientResponseGuid,
    surveyKey: resultToSave.tellUsMoreSurveyKey || null,
    questionResults: questionResultsDto,
    couponResults: resultToSave.couponResults,
    phoneNumber: resultToSave.phoneNumber,
    groupId: resultToSave.selectedDepartment,
    tempVisitKey: resultToSave.tempVisitKey,
  }
}

export const mapToResultToSave = (
  deviceId: string,
  questionnaireId: number,
  questionnaireResults: Map<Question, QuestionResult[]>,
  couponResults: ICouponResultDto[],
  language: string,
  responseTime: number,
  tellUsMoreSurveyKey: string | undefined,
  phoneNumber: string | undefined,
  clientResponseGuid: string,
  selectedDepartment: number | undefined,
  tempVisitKey?: string,
) => ({
  deviceId,
  questionnaireId,
  questionnaireResults,
  couponResults,
  language,
  responseTime,
  tellUsMoreSurveyKey,
  phoneNumber,
  clientResponseGuid,
  selectedDepartment,
  tempVisitKey,
}) as IResultToSave

// tslint:disable-next-line: no-empty
let modifyFileCurrentPromise: Promise<unknown> = Promise.resolve()

const modifyFileChainExecution = <T>(exec: () => Promise<T>) => {
  let doResolve: ((value?: {} | PromiseLike<{}> | undefined) => void) | undefined

  const newPromise = new Promise(resolve => { doResolve = resolve })

  const promise = modifyFileCurrentPromise
    .then(() => exec())
    .finally(() => doResolve && doResolve())

  modifyFileCurrentPromise = newPromise

  return promise
}

// tslint:disable-next-line: no-empty
let sendRequestCurrentPromise: Promise<unknown> = Promise.resolve()

export const sendResponse = (
  result: IResultToSave,
  key: IOnTheGoKey<number, string>,
) => {
  const dto = questionnaireResultMapper(result)

  return modifyFileChainExecution(() =>
    read('clientresponses')
      .then((dtos: IQuestionnaireResultDto[]) => {
        const toSave = dtos
          ? [...dtos, dto]
          : [dto]

        return save('clientresponses', toSave)
      }),
  )
    .then(() => {
      let doResolve: ((value?: {} | PromiseLike<{}> | undefined) => void) | undefined

      const newPromise = new Promise(resolve => { doResolve = resolve })

      const promise = sendRequestCurrentPromise
        .then(() => read('clientresponses'))
        .then((results: IQuestionnaireResultDto[]) => {
          if (!results || results.length === 0) return
          return tryToSend(results, key)
        })
        .finally(() => doResolve && doResolve())

      sendRequestCurrentPromise = newPromise

      return promise
    })
}

const removeResponse = (sentDtos: IQuestionnaireResultDto[]) =>
  modifyFileChainExecution(() =>
    read('clientresponses')
      .then((dtos: IQuestionnaireResultDto[]) => {
        const withoutSentOne = dtos
          .filter(x => !sentDtos.find(dto => x.clientResponseGuid === dto.clientResponseGuid))

        return save('clientresponses', withoutSentOne)
      }),
  )

const doSendDto = (dto: IQuestionnaireResultDto, key: IOnTheGoKey<number, string>) =>
  post({
    path: key.onTheGoKey
      ? `experiencestreams/onthego/${key.onTheGoKey}/questionnaire/responses`
      : `experiencestream/responses/send`,
    data: dto,
  })
    .then(res => {
      if (!res.ok) {
        return res
          .json()
          .then((content: { message: string }) => {
            // If the response was already sent, we should remove it.
            if (content.message === 'DuplicateResponse') {
              ShellConnecter.logger.logMessage(formatDtoForLogs(dto, true, true))
              return dto
            } else {
              ShellConnecter.logger.logMessage(formatDtoForLogs(dto, false, false))
              throw new Error(res.statusText)
            }
          })
          .catch(() => {
            ShellConnecter.logger.logMessage(formatDtoForLogs(dto, false, false))
            throw new Error(res.statusText)
          })
      }

      if (key.onTheGoKey && dto.tempVisitKey) {
        updateVisit(key.onTheGoKey, dto.tempVisitKey, dto.clientResponseGuid)
      }

      ShellConnecter.logger.logMessage(formatDtoForLogs(dto, true, false))
      return dto
    })

const tryToSend = (
  results: IQuestionnaireResultDto[],
  key: IOnTheGoKey<number, string>,
) => {
  const tail = <T>([x, ...xs]: T[]) => xs

  return doSendDto(results[0], key)
    .then(() => tail(results).map(result => doSendDto(result, key)))
    .then(() => removeResponse(results))
    .catch(error => {
      // We were not able to send the response to the server. Stop here.
      // Maybe we will try again later.
      ShellConnecter.logger.logMessage(error)
    })
}

const formatDtoForLogs = (dto: IQuestionnaireResultDto, success: boolean, dup: boolean) =>
  `${success ? 'Successfully sent' : 'Failed to send'} ${dup ? 'duplicate' : 'answer'}
  [Guid=${dto.clientResponseGuid}]
  [deviceId=${dto.computerFingerPrint}]
  [recordedOn=${dto.dateRecorded}]
  [questionnaireId=${dto.questionnaireId}]
  [responseTime=${dto.responseTimeSec}]
  [culture=${dto.responseCulture}]`
