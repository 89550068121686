import { faCamera, faLink, faMobileAlt, faPrint, faQrcode, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import he from 'he'
import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import '../../../css/coupon.css'
import CouponRetrieveMode from '../../../models/enums/CouponRetrieveMode'
import { IApplicationState } from '../../../Store'
import { actionCreators as ConnectionActionCreators } from '../../../Store/Connection/Connection'
import { actionCreators as ContextSelectionActionCreators } from '../../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { couponsActionCreators } from '../../../Store/Questionnaire/Coupon'
import { currentAnswerActionCreators } from '../../../Store/Questionnaire/CurrentAnswer'
import { emptyLastPageResource } from '../../../Store/Questionnaire/LastPage'
import { actionCreators as UserClickActionCreators } from '../../../Store/UserClick'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import Popup from '../../Modal/Popup'
import Button from '../../Shared/Button'
import RetrieveCouponsPicture from './RetrieveCouponPicture/RetrieveCouponsPicture'
import RetrieveCouponsPrint from './RetrieveCouponPrint/RetrieveCouponsPrint'
import RetrieveCouponsQR from './RetrieveCouponsQR'
import RetrieveCouponsSMS from './RetrieveCouponsSMS'
import RetrieveCouponsURL from './RetrieveCouponsURL'

const mapStateToProps = (state: IApplicationState) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
    currentLanguage: state.language.currentLanguage,
    ClientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
    questionnaireContent: state.display.questionnaireContent,
    couponRetrieveModes: state.questionnaire.coupons.couponRetrieveModes,
    currentAnswers: state.questionnaire.currentAnswer.answers,
    availableCoupons: state.questionnaire.coupons.coupons,
    clientCount: state.questionnaire.lastPage.clientCount,
    questionnaireGuid: state.questionnaire.questionnaireInfo.experienceStreamGuid,
    lastPageInfo: state.questionnaire.lastPage.resourcesMap,
    isPrintAvailable: state.connection.isPrinterAvailable,
    media: state.display.media,
    couponsToRetrieve: state.questionnaire.coupons.couponsToRetrieve,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    connectionActions: bindActionCreators(ConnectionActionCreators, dispatch),
    contextSelectionAction: bindActionCreators(ContextSelectionActionCreators, dispatch),
    userClickActions: bindActionCreators(UserClickActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    couponActions: bindActionCreators(couponsActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const CouponRetrieve = (props: IAllProps) => {

  const makeHasPopup = (
    mode: string,
    element: JSX.Element,
    modeIcon: IconDefinition | undefined,
    modeMessageKey: string,
    popupShowing: [number, React.Dispatch<React.SetStateAction<number>>],
    index: number,
  ) => {
    return (
      <Fragment key={mode}>
        <Popup
          isOpen={popupShowing[0] === index}
        >
          {element}
        </Popup>
        <Button
          className={`retrieve-button ${mode === CouponRetrieveMode.Print ? 'print' : ''}`}
          onClick={() => {
            props.couponActions.changeCouponsHasPrint(true)
            popupShowing[1](index)
          }}
        >
          {modeIcon && <FontAwesomeIcon icon={modeIcon} />}
          <div>
            <FormattedMessage id={modeMessageKey} />
          </div>
        </Button>
      </Fragment>
    )
  }

  const mapCouponsModes = () => {
    let retrieveModes = [...props.couponRetrieveModes]

    if (!props.isPrintAvailable) retrieveModes = retrieveModes.filter(x => x !== CouponRetrieveMode.Print)

    const printIndex = retrieveModes.findIndex(x => x === CouponRetrieveMode.Print)

    if (printIndex !== -1) {
      const print = retrieveModes.splice(printIndex, 1)
      const start = retrieveModes.splice(0, retrieveModes.length / 2)
      const end = retrieveModes.splice(0)
      retrieveModes = [...start, ...print, ...end]
    }

    return retrieveModes.map((mode, i, modes) => {
      let modeComponent: JSX.Element
      let modeIcon: IconDefinition | undefined
      let modeMessageKey: string

      const isPopup = modes.length > 1 ||
        mode === CouponRetrieveMode.Picture ||
        mode === CouponRetrieveMode.SMS
      const close = () => popupToShow[1](-1)

      switch (mode) {
        case CouponRetrieveMode.Print:
          modeComponent = <RetrieveCouponsPrint close={close} isPopup={isPopup} />
          modeIcon = faPrint
          modeMessageKey = 'btn-mode-print'
          break
        case CouponRetrieveMode.SMS:
          modeComponent = <RetrieveCouponsSMS close={close} isPopup={isPopup} />
          modeIcon = faMobileAlt
          modeMessageKey = 'btn-mode-sms'
          break
        case CouponRetrieveMode.URL:
          modeComponent = <RetrieveCouponsURL close={close} isPopup={isPopup} />
          modeIcon = faLink
          modeMessageKey = 'btn-mode-url'
          break
        case CouponRetrieveMode.QR:
          modeComponent = <RetrieveCouponsQR close={close} isPopup={isPopup} />
          modeIcon = faQrcode
          modeMessageKey = 'btn-mode-qr'
          break
        case CouponRetrieveMode.Picture:
          modeComponent = <RetrieveCouponsPicture close={close} />
          modeIcon = modes.length > 1 ? faCamera : undefined
          modeMessageKey = modes.length > 1 ? 'btn-mode-preview' : 'btn-mode-preview2'
          break
        default:
          throw Error(`${mode} is an unsupported coupon retrieve mode`)
      }

      if (isPopup) {
        modeComponent = makeHasPopup(mode, modeComponent, modeIcon, modeMessageKey, popupToShow, i)
      } else {
        props.couponActions.changeCouponsHasPrint(true)
      }

      return modeComponent
    })
  }

  const popupToShow = useState(-1)
  const [mappedCouponsModes] = useState(mapCouponsModes())

  const lastPageInfoTextLines = props
    .lastPageInfo
    .get(props.currentLanguage) ||
    emptyLastPageResource

  return (
    <div className='coupon-container'>
      <div className='coupon-retrieve-msg'>
        <FormattedMessage id='grats-coupons' />
        <span>{he.decode(lastPageInfoTextLines.tagLine)}</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p className='coupon-retrieve-msg-retrieve'>
          {
            mappedCouponsModes.length > 1 &&
            (
              props.couponsToRetrieve.length === 1
                ? <FormattedMessage id='retrieve-coupon-single' />
                : <FormattedMessage id='retrieve-coupon-multi' />
            )
          }
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          {
            mapCouponsModes().map(mode => mode)
          }
        </div>
      </div>

      <Button
        className='next'
        onClick={() => props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)}
      >
        <FormattedMessage id='next' />
      </Button>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponRetrieve)
