import React, { CSSProperties, Fragment, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import '../../../css/tellUsMore.css'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { currentAnswerActionCreators } from '../../../Store/Questionnaire/CurrentAnswer'
import { lastPageActionCreators } from '../../../Store/Questionnaire/LastPage'
import { questionnaireInfoActionCreators } from '../../../Store/Questionnaire/QuestionnaireInfo'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import { printTellUsMoreCoupon } from '../../../utils/printer'
import Popup from '../../Modal/Popup'
import QrCodeSvg from './QrCodeSvg'
import QrCodeTellUsMore from './QrCodeTellUsMore'
import UrlSvg from './UrlSvg'
import UrlTellUsMore from './UrlTellUsMore'

const mainDivStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  color: 'var(--GeneralFontColor)',
  fontSize: '7vh',
  fontFamily: 'JustAnotherHand',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
    surveyCode: state.questionnaire.lastPage.tellUsMoreSurveyKey,
    questionnaireId: state.questionnaire.questionnaireInfo.id,
    currentLanguage: state.language.currentLanguage,
    couponToSend: state.questionnaire.coupons.couponsToRetrieve,
    isPrinterAvailable: state.connection.isPrinterAvailable,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    lastPageActions: bindActionCreators(lastPageActionCreators, dispatch),
    questionnaireInfoActions: bindActionCreators(questionnaireInfoActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { children: React.ReactNode }

const TellUsMore = (props: IAllProps) => {

  const [isQrTellUsMoreOpen, setIsQrTellUsMoreOpen] = useState(false)
  const [isUrlTellUsMoreOpen, setIsUrlTellUsMoreOpen] = useState(false)

  const handleClose = () => {
    setIsQrTellUsMoreOpen(false)
    setIsUrlTellUsMoreOpen(false)
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
  }

  useEffect(() => {
    if (!props.isPrinterAvailable || !props.surveyCode) return

    const logo = props.lastPageActions.getLogo()
    printTellUsMoreCoupon(
      props.serverAddress,
      props.surveyCode,
      props.currentLanguage,
      logo,
    )
  }, [])

  return (
    <Fragment>
      <Popup
        isOpen={isQrTellUsMoreOpen}
        handleClose={() => handleClose()}
      >
        <QrCodeTellUsMore />
      </Popup>
      <Popup
        isOpen={isUrlTellUsMoreOpen}
        handleClose={() => handleClose()}
      >
        <UrlTellUsMore />
      </Popup>
      <div style={mainDivStyle}>
        {
          !props.isPrinterAvailable &&
          <div>
            <p style={{ color: 'var(--ButtonPrimary)' }}>
              <FormattedMessage id='tell-us-more-choice' />
            </p>
            <div
              style={{
                width: '50vw',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <span><QrCodeSvg onClick={() => setIsQrTellUsMoreOpen(true)} /></span>
              <span><UrlSvg onClick={() => setIsUrlTellUsMoreOpen(true)} /></span>
            </div>
          </div>
        }
        {
          !!props.isPrinterAvailable &&
          <div>
            <p className='tell-us-more-title' style={{ color: 'var(--ButtonPrimary)' }}>
              <FormattedMessage id='tell-us-more-printing-1' />
            </p>
            <p className='tell-us-more-title'>
              <FormattedMessage id='tell-us-more-printing-2' />
            </p>
          </div>
        }
      </div>
    </Fragment>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TellUsMore)
