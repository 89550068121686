import HashIds from 'hashids'

export const generateCode = (screenId: number) => {
  const hashIdSalt = '1B7786D22F95DE8B61FDEA5E1DF14'
  const minHashLength = 18

  const currentTime = new Date()

  const hashIds = new HashIds(hashIdSalt, minHashLength, 'ABCDEFGHJKMNPQRSTUVWXYZ23456789')

  return hashIds.encode(screenId, currentTime.getTime())
}

export const formatCode = (code: string) => {
  const formatedCode = code.slice(0, 2) + '-'
    + code.slice(2, 6) + '-'
    + code.slice(6, 10) + '-'
    + code.slice(10, 14) + '-'
    + code.slice(14, 18)

  return formatedCode
}
