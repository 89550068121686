import he from 'he'
import jsbarcode from 'jsbarcode'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import '../../../../css/coupon-preview.css'
import { Coupon } from '../../../../models/ICoupon'
import { IApplicationState } from '../../../../Store'
import { lastPageActionCreators } from '../../../../Store/Questionnaire/LastPage'
import { bindActionCreators } from '../../../../utils/bindActionCreators'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    currentLanguage: state.language.currentLanguage,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    lastPageActions: bindActionCreators(lastPageActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    coupon: Coupon,
  }

const CouponPreview = (props: IAllProps) => {

  const couponRes = () => {
    const resources = props.coupon.resourcesList.get(props.currentLanguage)

    return {
      body: resources ? he.decode(resources.body) : '',
      footer: resources ? he.decode(resources.footer) : '',
    }
  }

  const formattedExpirationDate = (daysToAdd: number) => {
    const expirationDate = new Date()
    expirationDate.setDate(expirationDate.getDate() + daysToAdd)
    const expirationDateString = expirationDate.toLocaleString(
      props.currentLanguage,
      {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      },
    )

    return expirationDateString
  }

  const separator = () => <div style={{ margin: '0 10% 0 10%', width: '80%', height: '2px', background: '#9C9C9C' }} />

  useEffect(() => {
    if (props.coupon.barCodeValue) {
      jsbarcode(
        '#barcode',
        props.coupon.barCodeValue,
        {
          format: 'CODE39',
        },
      )
    }
  }, [])

  return (
    <div
      className='coupon-preview-container'
    >
      <p className='coupon-printed-date'>
        {formattedExpirationDate(0)}
      </p>
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          margin: '10px 0 10px 0',
          height: '10%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={props.lastPageActions.getLogo()}
          style={{ height: '5vh' }}
        />
      </div>
      {separator()}
      <div className='coupon-preview-body'>{couponRes().body}</div>
      {separator()}
      <div className='coupon-preview-footer'>{couponRes().footer}</div>
      {separator()}
      <div style={{ marginTop: '15px' }}>
        {
          props.coupon.couponExpires &&
          <div className='coupon-expiration'>
            <div>
              <FormattedMessage id='valid-for' />
              <span> {props.coupon.daysValidAmount} </span>
              <FormattedMessage id='days' />
            </div>
            <div>
              <span>(</span>
              <FormattedMessage id='expires-on' />
              <span> {formattedExpirationDate(props.coupon.daysValidAmount)})</span>
            </div>
          </div>
        }
        {
          props.coupon.barCodeValue &&
          <div style={{ fontSize: 'initial' }}>
            <div style={{ height: '50px', overflow: 'hidden' }}>
              <img id='barcode' style={{ width: '100%' }} />
            </div>
            <p className='coupon-barcode-number' style={{ margin: 0 }}>{props.coupon.barCodeValue}</p>
          </div>
        }
      </div>

    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CouponPreview)
