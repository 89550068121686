import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import '../../../../css/languageDropdown.css'
import { Language } from '../../../../models/Language'
import { IApplicationState } from '../../../../Store'
import { languageActionCreators } from '../../../../Store/Language'
import Button from '../../../Shared/Button'

const mapStateToProps = (state: IApplicationState) => {
  return {
    languageState: state.language,
    media: state.display.media,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    languageActions: bindActionCreators(languageActionCreators, dispatch),
  }
}

const getTranslation = (props: IAllProps) => {
  if (!props.media.isAtLeastTablet) {
    return 'lang-abr-' + props.lang
  }

  return 'lang-' + props.lang
}

type IAllProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
  & {
    lang: Language,
    isOpen: boolean,
    onClick: () => void,
  }

const LanguageDropdownOption = (props: IAllProps) => {

  return (
    <Button
      onClick={() => {
        props.languageActions.changeCurrentLanguage(props.lang)
        props.onClick()
      }}
      className='dropdown-content-item'
      selected={props.languageState.currentLanguage === props.lang && props.isOpen}
    >
      <FormattedMessage id={getTranslation(props)} />
    </Button>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageDropdownOption)
