import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { IApplicationState, reducers } from '.'
import { loadState } from '../utils/localStorage'

const configureStore = (initialState?: IApplicationState) => {
  const middleware = [
    thunk,
  ]

  const rootReducer = combineReducers({
    ...reducers,
  })

  const enhancers = [
    devToolsEnhancer(window),
  ]

  const loadedState = loadState()

  return createStore(
    rootReducer,
    loadedState ? loadedState : initialState,
    compose(applyMiddleware(...middleware), ...enhancers),
  )
}

export default configureStore
