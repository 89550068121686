import { EsColor } from '../models/colors/IEsColor'
import { EsColors, IEsColorsDto } from '../models/colors/IEsColors'
import StyleKeyType from '../models/enums/StyleKeyType'
import { IAuth } from '../Store/Auth'
import { get } from './fetch'

export const mapStyleServerResponse = (
  colors: EsColor[],
): Map<StyleKeyType, string> =>
  new Map(
    colors.map(values => [values.keyType, values.color]) as Array<[StyleKeyType, string]>,
  )

export const getCustomStyle = (
  id: number,
  url?: string,
  auth?: IAuth,
): Promise<EsColors> =>
  get({
    hostname: url,
    path: `experiencestreams/colors/${id}`,
    auth,
  })
    .then(res => res.json())
    .then((x: IEsColorsDto) => new EsColors(x))

export const getDefaultStyle = (url: string): Promise<EsColors> =>
  get({
    hostname: url,
    path: `experiencestreams/colors/system`,
    auth: undefined,
  })
    .then(res => res.json())
    .then((x: IEsColorsDto) => new EsColors(x))
