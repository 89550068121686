import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IApplicationState } from '..'
import { languageActionCreators } from '../Language'
import { couponsActionCreators } from '../Questionnaire/Coupon'
import { currentAnswerActionCreators } from '../Questionnaire/CurrentAnswer'
import { lastPageActionCreators as LastPageActionCreators } from '../Questionnaire/LastPage'
import { questionnaireActionCreators } from '../Questionnaire/Questionnaire'
import { questionnaireInfoActionCreators } from '../Questionnaire/QuestionnaireInfo'
import { questionsActionCreators } from '../Questionnaire/Questions'
import { actionCreators as SelectedPictureActionCreators } from '../Questionnaire/SelectedPicture'
import { defaultState as initialSelectedPictureState } from '../Questionnaire/SelectedPicture'
import { actionCreators as ConnectionActionCreators } from './Connection'

export const setupProxyEvents = (proxy: any) =>
  (dispatch: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState) => {

    proxy.on('QuestionnaireUpdated', (id: number) => {
      const currentState = getState()
      const currentAuth = currentState.auth.currentAuth

      if (currentAuth) {
        dispatch(currentAnswerActionCreators.resetAnswers())
        dispatch(questionnaireActionCreators.changeQuestionnaire({ key: id }))
      }
    })

    proxy.on('DeviceUpdated', (id: number) => {
      const currentState = getState()
      const currentQuestionnaireId = currentState.questionnaire.questionnaireInfo.id

      if (id !== currentQuestionnaireId) {
        const currentAuth = currentState.auth.currentAuth

        if (currentAuth) {
          dispatch(currentAnswerActionCreators.resetAnswers())

          if (id === -1) {
            dispatch(LastPageActionCreators.resetLastPageInfo())
            dispatch(languageActionCreators.resetLanguage())
            dispatch(questionsActionCreators.resetQuestions())
            dispatch(SelectedPictureActionCreators.changeSelectedPicture(initialSelectedPictureState))
            dispatch(couponsActionCreators.resetCoupons())
            dispatch(questionnaireInfoActionCreators.resetQuestionnaireInfos())
          } else {
            dispatch(questionnaireActionCreators.changeQuestionnaire({ key: id }))
          }
        }
      }
    })
  }

export const setupConnectionEvents = (connection: any) =>
  (dispatch: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState) => {

    /* Remove proxy is disconnected */
    connection.disconnected(() => {
      dispatch(ConnectionActionCreators.changeProxy(undefined))
      dispatch(ConnectionActionCreators.changeIsSocketActive(false))
    })
  }
