import ILocation from '../models/ILocation'
import IToken from '../models/IToken'
import { IAuth } from '../Store/Auth'
import { get, put } from './fetch'

interface IServerResponse {
  items: ILocation[]
}

export const getBranches =
  (
    options: { startIndex: number, size: number } = { startIndex: 0, size: 20 },
    url: string,
    auth: IAuth,
  ): Promise<ILocation[]> =>
    get({
      hostname: url,
      path: `Location/Branches/List?startIndex=${options.startIndex}&pageSize=${options.size}`,
      auth,
    })
      .then(res => res.json())
      .then((branches: IServerResponse) =>
        branches.items.map(item => ({
          id: item.id,
          name: item.name,
        })),
      )

export const updateSelectedBranch = (id: number, url: string, auth: IAuth): Promise<IToken> =>
  put({
    hostname: url,
    path: 'contextselection/selectbranch',
    auth,
    data: id,
  })
    .then(res => res.json())
    .then((token: IToken) => token)
