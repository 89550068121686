import { Question } from './IQuestion'

export type Questionnaire = IQuestionnaire | null

export enum SelectedPictureBarType {
  Employee,
  Custom,
  None,
}

export default interface IQuestionnaire {
  id: number,
  name: string,
  description: string,
  published: boolean,
  tellUsMoreActivated: boolean,
  experienceStreamGuid: string,
  questions?: Question[],
  pictureType: SelectedPictureBarType
  enabledCultures: string[]
  enabledCouponRetrieveModes: string[]
}
