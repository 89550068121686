import React, { Component } from 'react'

export enum ColorState {
  First = 0,
  Second = 1,
  Third = 2,
  Fourth = 3,
}

const colorMapping = new Map<ColorState, [string, string]>([
  [ColorState.First, ['#00FF01', '#56FF00']],
  [ColorState.Second, ['#00FF1B', '#fff600']],
  [ColorState.Third, ['#08C0BF', '#00F3BC']],
  [ColorState.Fourth, ['#D32DFE', '#8B74FF']],
])

interface IProps {
  initialColorState: ColorState
}

interface IState {
  currentColor: [string, string] | undefined
  currentColorState: ColorState,
  flash: NodeJS.Timeout | null
}

const rotationTime = 500

class FlashingLight extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      currentColor: colorMapping.get(props.initialColorState),
      currentColorState: props.initialColorState,
      flash: null,
    }
  }

  public componentDidMount = () => this.startRotating()

  public componentWillUnmount = () => {
    if (this.state.flash) {
      clearTimeout(this.state.flash)
    }
  }

  public render = () =>
    <figure
      style={{
        borderRadius: '50%',
        width: 30,
        height: 30,
        margin: '0 5px',
        ...(
          this.state.currentColor
            ? {
              backgroundImage:
                `linear-gradient(to right, ${this.state.currentColor[0]}, ${this.state.currentColor[1]})`,
            }
            : {}
        ),
      }}
    />

  private startRotating = () => {
    this.setState(previousState => ({
      ...previousState,
      flash: setTimeout(
        () => {
          this.rotateColor()
          this.startRotating()
        },
        rotationTime,
      ),
    }))
  }

  private rotateColor = () => {
    const getNextColorState = (current: ColorState): ColorState => {
      if (current === ColorState.Fourth) return ColorState.First

      return current + 1
    }

    const newColorState = getNextColorState(this.state.currentColorState)

    this.setState(previousState => ({
      ...previousState,
      currentColor: colorMapping.get(newColorState),
      currentColorState: newColorState,
    }))
  }
}

export default FlashingLight
