import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import ShellConnecter from '../../dependencies/shell-connecter'
import { IApplicationState } from '../../Store'
import { actionCreators as ConnectionActionCreators } from '../../Store/Connection/Connection'
import { actionCreators as ContextSelectionActionCreators } from '../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../Store/Display'
import { currentAnswerActionCreators } from '../../Store/Questionnaire/CurrentAnswer'
import { lastPageActionCreators } from '../../Store/Questionnaire/LastPage'
import { actionCreators as UserClickActionCreators } from '../../Store/UserClick'
import { bindActionCreators } from '../../utils/bindActionCreators'

interface ITimeoutSetup {
  time: number,
  timeoutFunc: () => void,
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
    currentLanguage: state.language.currentLanguage,
    ClientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
    questionnaireContent: state.display.questionnaireContent,
    couponsToSend: state.questionnaire.coupons.couponsToRetrieve,
    isOnTheGo: state.onTheGo.isOnTheGo,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    connectionActions: bindActionCreators(ConnectionActionCreators, dispatch),
    contextSelectionAction: bindActionCreators(ContextSelectionActionCreators, dispatch),
    userClickActions: bindActionCreators(UserClickActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    lastPageActions: bindActionCreators(lastPageActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    children: React.ReactChild,
  }

const OnClickContainer = (props: IAllProps) => {
  const questionnaireTimeoutSetup = {
    time: 8000,
    timeoutFunc: () => {
      props.displayActions.changeDisplayAreYouStillThereIsDisplayed(true)
    },
  }

  const couponRetrieveTimeoutSetup = {
    time: 7000,
    timeoutFunc: () => {
      props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)
    },
  }

  const lastPageTimeoutSetup = {
    time: 4000,
    timeoutFunc: () => {
      props.currentAnswerActions.sendAnswers(props.couponsToSend)
      props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
    },
  }

  const tellUsMoreTimeoutSetup = {
    time: 7000,
    timeoutFunc: () => {
      props.currentAnswerActions.sendAnswers(props.couponsToSend)
      props.lastPageActions.changeTellUsMoreSurveyKey(undefined)
      props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
    },
  }

  const [userCanClick, setUserCanClick] = useState(true)
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | undefined>(undefined)
  const [timeoutSetup, setTimeoutSetup] = useState<ITimeoutSetup | null>(questionnaireTimeoutSetup)
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)

  const clearClickTimeout = () => {
    setUserCanClick(false)
    setTimeout(() => setUserCanClick(true), 1000)

    if (clickTimeout) {
      clearTimeout(clickTimeout)
      setClickTimeout(undefined)
    }

    props.userClickActions.userClick(true)
  }

  const startClickTimeout = () => {
    if (!timeoutSetup) return

    const timeOutSetupFunc = timeoutSetup.timeoutFunc
    const timeoutSetupTime = timeoutSetup.time

    const timeout = setTimeout(
      () => {
        setClickTimeout(undefined)
        timeOutSetupFunc()
        props.userClickActions.userClick(false)
      },
      timeoutSetupTime,
    )

    setClickTimeout(timeout)
  }

  const doMouseMovement = (logMessage: string) => {
    if (!userCanClick) return

    ShellConnecter.logger.logMessage(logMessage)

    clearClickTimeout()
    startClickTimeout()
  }

  const userMouseDown = () => {
    setIsMouseDown(true)
    doMouseMovement('Mouse down detected')
  }

  const userMouseUp = () => {
    setIsMouseDown(false)
    doMouseMovement('Mouse up detected')
  }

  const userMouseMove = () => {
    if (!isMouseDown) return
    doMouseMovement('Mouse move detected')
  }

  useEffect(() => {
    if (props.isOnTheGo) return

    switch (props.questionnaireContent) {
      case QuestionnaireContentPage.Questionnaire:
        setTimeoutSetup(questionnaireTimeoutSetup)
        break
      case QuestionnaireContentPage.CouponRetrieve:
        setTimeoutSetup(couponRetrieveTimeoutSetup)
        break
      case QuestionnaireContentPage.LastPage:
        setTimeoutSetup(lastPageTimeoutSetup)
        break
      case QuestionnaireContentPage.TellUsMore:
        setTimeoutSetup(tellUsMoreTimeoutSetup)
        break
      default:
        setTimeoutSetup(null)
        break
    }
  }, [props.questionnaireContent])

  useEffect(() => {
    clearClickTimeout()
    startClickTimeout()
  }, [timeoutSetup])

  useEffect(() => {
    if (!props.isOnTheGo) return

    setTimeoutSetup(null)
  }, [props.isOnTheGo])

  return (
    <div
      onMouseDown={userMouseDown}
      onMouseMove={userMouseMove}
      onMouseUp={userMouseUp}

      onTouchStart={userMouseDown}
      onTouchMove={userMouseMove}
      onTouchEnd={userMouseUp}
    >
      {props.children}
    </div >
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OnClickContainer)
