import React, { ChangeEvent, Component, CSSProperties, KeyboardEvent } from 'react'
import { FormattedMessage } from 'react-intl'

interface IState {
  inputType: string,
  color: string
  inputValue: string,
  inputSelected: boolean,
  errorOpacity: number,
  validInputValue?: boolean
}

interface IProps {
  type: string,
  name: string,
  required: boolean,
  placeHolder: string,
  color: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  defaultValue?: string,
  submitOnEnterDown?: () => void
  validationRegex?: { regex: RegExp, errorMessage: string },
  style?: CSSProperties
}

const matFormField: CSSProperties = {
  width: '100%',
  margin: '15px 0',
  display: 'inline-block',
  position: 'relative',
  textAlign: 'left',
  fontSize: 'inherit',
  fontWeight: 'bold',
  lineHeight: '1.125',
}

const matFormFieldWrapper: CSSProperties = {
  paddingBottom: '1.25em',
  position: 'relative',
}

const matFormFieldFlex: CSSProperties = {
  display: 'inline-flex',
  alignItems: 'baseline',
  boxSizing: 'border-box',
  width: '100%',
}

const matFormFieldInfix: CSSProperties = {
  padding: '.5em 0',
  display: 'block',
  position: 'relative',
  flex: 'auto',
  minWidth: 0,
  width: '180px',
}

const matFormFieldLabelWrapper: CSSProperties = {
  position: 'absolute',
  left: '0',
  boxSizing: 'content-box',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  pointerEvents: 'none',
  top: '-.84375em',
  paddingTop: '.84375em',
}

const matInputElement: CSSProperties = {
  font: 'inherit',
  background: '0 0',
  border: 'none',
  outline: 0,
  padding: 0,
  margin: 0,
  width: '100%',
  maxWidth: '100%',
  verticalAlign: 'bottom',
  textAlign: 'inherit',
}

const matFormFieldSuffix: CSSProperties = {
  whiteSpace: 'nowrap',
  flex: 'none',
  position: 'relative',
}

const fa: CSSProperties = {
  display: 'inline-block',
  fontSize: 'inherit',
  textRendering: 'auto',
  verticalAlign: 'initial!important',
  cursor: 'pointer',
}

const matFormFieldUnderline: CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, .7)',
  bottom: '1.25em',
  height: '1px',
  position: 'absolute',
  width: '100%',
  pointerEvents: 'none',
  transitionProperty: 'transform, color',
  transitionDuration: '0.4s, 0.4s',
  transitionTimingFunction: 'cubic-bezier(.25, .8, .25, 1)',
  transitionDelay: '0s, 0s',
}

const matFormFieldLabel: CSSProperties = {
  position: 'absolute',
  left: '0',
  font: 'inherit',
  pointerEvents: 'none',
  width: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  top: '1.28125em',
  transformOrigin: '0 0',
  display: 'block',
  transitionProperty: 'transform, color, width',
  transitionDuration: '0.4s, 0.4s, 0.4s',
  transitionTimingFunction: 'cubic-bezier(.25, .8, .25, 1)',
  transitionDelay: '0s, 0s, 0s',
}

const matFormFieldSubscriptWrapper: CSSProperties = {
  marginTop: '.54166667em',
  top: 'calc(100% - 1.66666667em)',
  position: 'absolute',
  boxSizing: 'border-box',
  width: '100%',
  overflow: 'hidden',
  opacity: 0,
  transition: 'opacity .4s ease-in-out',
}

class InputTextField extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      inputSelected: false,
      inputType: props.type,
      color: 'white',
      inputValue: props.defaultValue ? props.defaultValue : '',
      errorOpacity: 0,
    }
  }

  public render() {
    return (
      <div style={{ ...matFormField, ...this.props.style }}>
        <div style={{ ...matFormFieldWrapper }}>
          <div style={{ ...matFormFieldFlex }}>
            <div style={{ ...matFormFieldInfix }}>
              <input
                type={this.state.inputType}
                name={this.props.name}
                required={this.props.required}
                style={{ ...matInputElement, caretColor: this.state.color, color: 'white' }}
                onBlur={event => this.onInputBlur(event)}
                onChange={event => {
                  this.onInputChange(event)
                  if (this.props.onChange) this.props.onChange(event)
                }}
                onFocus={this.onInputFocus}
                autoComplete='off'
                value={this.state.inputValue}
                onKeyDown={this.submitOnKeyDown}
              />
              < span style={{ ...matFormFieldLabelWrapper }}>
                <label
                  style={{
                    ...matFormFieldLabel,
                    color: this.state.color,
                    transform: this.state.inputSelected || this.state.inputValue
                      ? 'translateY(-1.34373em) scale(.75)' : '',
                  }}
                >
                  <FormattedMessage id={this.props.placeHolder} />
                  {
                    this.props.required && <span style={{ color: this.state.color }} > *</span>
                  }
                </label>
              </span>
            </div>
            {
              this.props.type === 'password' &&
              <div style={{ ...matFormFieldSuffix }}>
                <span style={{ ...fa, color: 'white' }} onClick={this.showPassword}>👁</span>
              </div>
            }
          </div>
          <div
            style={{
              ...matFormFieldUnderline,
              transform: this.state.inputSelected ? 'scaleY(2)' : '',
              backgroundColor: this.state.color,
            }}
          />
          {
            (!this.state.inputValue || this.state.validInputValue === false) &&
            <div style={{ ...matFormFieldSubscriptWrapper, opacity: this.state.errorOpacity }}>
              {this.displayErrorMessage()}
            </div>
          }
        </div>
      </div >

    )
  }

  private displayErrorMessage = () => {
    if (this.props.validationRegex) {
      if (!this.state.inputValue) {
        return <div style={{ color: this.state.color, fontSize: '75%' }}>
          Ce champs est requis
      </div>
      } else {
        return <div style={{ color: this.state.color, fontSize: '75%' }}>
          {this.props.validationRegex.errorMessage}
        </div>
      }
    } else if (this.props.required) {
      return <div style={{ color: this.state.color, fontSize: '75%' }}>
        Ce champs est requis
      </div>
    }
  }

  private submitOnKeyDown = (keyEvent: KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === 'Enter' && this.props.submitOnEnterDown) this.props.submitOnEnterDown()
  }

  private onInputBlur = (focusEvent: React.FocusEvent) => {
    if (!this.state.inputValue && this.props.required) {
      this.setState({ color: 'red', inputSelected: false, errorOpacity: 1 })
    } else {
      if (this.props.validationRegex) {
        const valid = this.props.validationRegex.regex.test(this.state.inputValue)

        if (!valid) this.setState({ color: 'red', inputSelected: false, errorOpacity: 1, validInputValue: false })
        else this.setState({ color: 'white', validInputValue: true })
      } else {
        this.setState({ color: 'white' })
      }
    }
  }

  private onInputChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (!changeEvent.currentTarget.value && this.props.required) {
      this.setState({ color: 'red', inputValue: changeEvent.currentTarget.value, errorOpacity: 1 })
    } else {
      if (this.props.validationRegex) {
        const valid = this.props.validationRegex.regex.test(changeEvent.currentTarget.value)

        if (!valid) {
          this.setState({
            color: 'red',
            errorOpacity: 1,
            validInputValue: false,
            inputValue: changeEvent.currentTarget.value,
          })
        } else this.setState({ color: this.props.color, inputValue: changeEvent.currentTarget.value, errorOpacity: 0 })
      } else {
        this.setState({ color: this.props.color, inputValue: changeEvent.currentTarget.value, errorOpacity: 0 })
      }
    }
  }

  private showPassword = () => {
    if (this.state.inputType === 'password') this.setState({ inputType: 'text' })
    else this.setState({ inputType: 'password' })
  }

  private onInputFocus = () => {
    if (this.state.validInputValue && this.state.inputValue || this.state.color === 'white') {
      this.setState({ inputSelected: true, color: this.props.color })
    } else {
      this.setState({ inputSelected: true, color: 'red' })
    }

  }
}

export default InputTextField
