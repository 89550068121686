import { Action, Reducer } from 'redux'
import { Language } from '../models/Language'

export interface ILanguageState {
  currentLanguage: Language
  availableLanguages: Language[]
}

interface IChangeCurrentLanguage {
  type: 'CHANGE_CURRENT_LANGUAGE'
  language: Language
}
interface IChangeAvailableLanguage {
  type: 'CHANGE_AVAILABLE_LANGUAGE'
  languages: Language[]
}
interface IResetLanguage {
  type: 'RESET_LANGUAGE'
}

export type KnownAction = IChangeCurrentLanguage | IChangeAvailableLanguage
  | IResetLanguage

export const languageActionCreators = {
  changeCurrentLanguage: (language: Language) =>
    ({ type: 'CHANGE_CURRENT_LANGUAGE', language }),
  changeAvailableLanguage: (languages: Language[]) =>
    ({ type: 'CHANGE_AVAILABLE_LANGUAGE', languages }),
  resetLanguage: () =>
    ({ type: 'RESET_LANGUAGE' }),
}

const defaultState: ILanguageState = {
  currentLanguage: Language.EN,
  availableLanguages: [Language.EN],
}

export const reducer: Reducer<ILanguageState> =
  (state: ILanguageState = defaultState, incomingAction: Action): ILanguageState => {
    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_CURRENT_LANGUAGE':
        return {
          ...state,
          currentLanguage: action.language,
        }
      case 'CHANGE_AVAILABLE_LANGUAGE':
        return {
          ...state,
          currentLanguage: action.languages[0],
          availableLanguages: action.languages,
        }
      case 'RESET_LANGUAGE':
        return defaultState
      default:
        return state
    }
  }
