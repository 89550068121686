import he from 'he'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'

import '../../../../css/slide-show.css'
import { Picture } from '../../../../models/IPicture'
import { IApplicationState } from '../../../../Store'
import PictureCardImage from './PictureCard/PictureCardImage'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    languageState: state.language,
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> & {
  picture: Picture,
  index: number,
  maxDisplayedPicture: number,
  onClick: (id: number) => void,
}

const AnimatedCard = (props: IAllProps) => {
  const pictureCardContainer = React.createRef<HTMLDivElement>()
  const pictureCard = React.createRef<HTMLDivElement>()
  const name = React.createRef<HTMLHeadingElement>()

  const [fadding, setFadding] = useState<boolean>(props.index < 1)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    if (props.index > 0) setFadding(true)
    if (props.index >= props.maxDisplayedPicture) setFadding(false)
  }, [])

  useEffect(() => {
    if (props.index < 1) setFadding(false)
  }, [mounted])

  useEffect(() => {
    setFadding(props.index > 0 && props.index <= props.maxDisplayedPicture)
  }, [props.index])

  const getPictureCard = () => {
    return <CSSTransition
      in={fadding}
      timeout={{ enter: 2000, exit: 2000 }}
      classNames='fade-in'
    >
      <div
        className='animated-card'
        ref={pictureCard}
      >
        <PictureCardImage
          picture={props.picture}
          className='animated-card'
          style={props.media.isAtLeastTablet ?
            {
              height: '12.5vh',
              width: '12.5vh',
              borderRadius: '50%',
            } :
            {
              height: '30vH',
              width: '30vH',
              maxWidth: '150px',
              maxHeight: '150px',
              borderRadius: '50%',
            }}
        />
        <h1
          ref={name}
          className='animated-picture-text'
        >
          {
            typeof props.picture.name === 'string'
              ? he.decode(props.picture.name)
              : he.decode(
                props
                  .picture
                  .name
                  .get(props.languageState.currentLanguage) || '',
              )
          }
        </h1>
      </div>
    </CSSTransition>
  }

  return (
    <div
      className='animated-card-container'
      ref={pictureCardContainer}
      style={{
        left: `${props.index * 205 + 5}px`,
      }}
      onClick={() => props.onClick(props.picture.id)}
    >
      {
        getPictureCard()
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(AnimatedCard)
