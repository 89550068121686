import he from 'he'
import React from 'react'
import { connect } from 'react-redux'

import { IApplicationState } from '../../../Store/index'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => ({
  currentLanguage: state.language.currentLanguage,
})

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & {
    children: React.ReactNode,
    questionName: Map<string, string>,
  }

const QuestionRow = (props: IAllProps) =>
  <div
    className='question-content'
  >
    <h1
      className='question-title'
    >
      {he.decode(props.questionName.get(props.currentLanguage) || '')}
    </h1>
    {props.children}
  </div>

export default connect(
  mapStateToProps,
  null,
)(QuestionRow)
