import ILastPageInfo, { ILastPageInfoServer } from '../models/ILastPageInfo'
import { IAuth } from '../Store/Auth'
import { get } from './fetch'

export const mapLastPageInfoServerResponse = (
  informations: ILastPageInfoServer,
): ILastPageInfo => {
  const resourcesMapped = informations
    .resourcesList
    .map(res =>
      [res.culture, { thankYouComment: res.thankYouComment, tagLine: res.tagLine }],
    ) as Array<[string, { thankYouComment: string, tagLine: string }]>

  return (
    {
      customLogo: informations.customLogo,
      defaultLogo: informations.defaultLogo,
      tellUsMoreSurveyKey: undefined,
      resourcesMap: new Map(resourcesMapped),
    }
  )
}

export const getInformations = (id: number, url?: string, auth?: IAuth): Promise<ILastPageInfo> =>
  get({
    hostname: url,
    path: `experiencestreams/${id}/lastpage`,
    auth,
  })
    .then(res => res.json())
    .then(mapLastPageInfoServerResponse)
