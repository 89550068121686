import React, { CSSProperties, UIEvent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { getQuestionnaires } from '../../fetchers/questionnaires'
import IQuestionnaire from '../../models/IQuestionnaire'
import { IApplicationState } from '../../Store'
import { authActionCreators } from '../../Store/Auth'
import { actionCreators as ConnectionActionCreators } from '../../Store/Connection/Connection'
import { actionCreators as ContextSelectionActionCreators } from '../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../Store/Display'
import { questionnaireActionCreators } from '../../Store/Questionnaire/Questionnaire'
import { bindActionCreators } from '../../utils/bindActionCreators'

const cultureStyle: CSSProperties = {
  backgroundColor: 'var(--ButtonPrimary)',
  margin: '0 4px',
  padding: 2,
  borderRadius: '5px',
  textAlign: 'center',
  width: '20px',
  height: '20px',
}

const matFormFieldUnderline: React.CSSProperties = {
  height: '1px',
  width: '100%',
  pointerEvents: 'none',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    contextSelectionState: state.contextSelection,
    connectionState: state.connection,
    questionnaireState: state.questionnaire,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    authActions: bindActionCreators(authActionCreators, dispatch),
    contextSelectionActions: bindActionCreators(ContextSelectionActionCreators, dispatch),
    connectionActions: bindActionCreators(ConnectionActionCreators, dispatch),
    questionnaireActions: bindActionCreators(questionnaireActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const QuestionnaireList = (props: IAllProps) => {
  const [questionnaires, setQuestionnaires] = useState([] as IQuestionnaire[])
  const [fetching, setFetching] = useState(false)
  const [startIndex, setStartIndex] = useState(0)

  const mapContent = (item: IQuestionnaire) =>
    <div key={item.id}>
      <div
        onClick={() => onComplete(item)}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <div style={{ marginRight: '10px' }}>
          <p>➤</p>
        </div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
            <div>
              <p>{item.name}</p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginRight: '20px',
                color: 'var(--ButtonFontColor)',
                alignItems: 'center',
              }}
            >
              {
                item.enabledCultures.map(mapCultures)
              }
            </div>
          </div>
          <p style={{ fontSize: '75%', marginTop: 0 }}>{item.description}</p>
        </div>
      </div>
      <div style={{ ...matFormFieldUnderline, backgroundColor: 'var(--GeneralFontColor)' }} />
    </div>

  const mapCultures = (culture: string) =>
    <p
      key={culture}
      style={{ ...cultureStyle }}>
      {culture.split('-')[0]}
    </p>

  const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
    const divScroll = event.currentTarget
    const ratio = ((divScroll.offsetHeight + divScroll.scrollTop) / divScroll.scrollHeight) * 100

    if (!fetching && ratio >= 80) fetchQuestionnaire()
  }

  const fetchQuestionnaire = () => {
    const tempAuth = props.contextSelectionState.tempAuth
    const serverAddress = props.contextSelectionState.serverAddress

    if (!tempAuth) return

    setFetching(true)

    getQuestionnaires(
      { startIndex, size: 10 },
      serverAddress,
      tempAuth,
    )
      .then(quests => {
        if (questionnaires.length === 0 && quests.length === 1) {
          onComplete(quests[0])
        } else {
          setQuestionnaires([...questionnaires, ...quests])
          setFetching(quests.length < 10 ? true : false)
          setStartIndex(startIndex + quests.length)
        }
      })
  }

  const onComplete = (questionnaire: IQuestionnaire) => {
    const tempAuth = props.contextSelectionState.tempAuth
      ? { ...props.contextSelectionState.tempAuth }
      : undefined

    if (!tempAuth) return

    props
      .questionnaireActions
      .changeQuestionnaire({ key: questionnaire.id })
      .then(() => props.contextSelectionActions.changeTempAuth())
      .then(() =>
        props
          .authActions
          .changeCurrentAuth({
            token: tempAuth.token,
            expiration: tempAuth.expiration,
            refreshToken: tempAuth.refreshToken,
            refreshTokenExpiration: tempAuth.refreshTokenExpiration,
          }),
      )
      .then(() => props.connectionActions.connectLiveConnection())
      .then(() =>
        props.questionnaireActions.questionnaireChanged(
          questionnaire.experienceStreamGuid,
        ),
      )
      .then(() => {
        props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
        props.displayActions.changeModalPage(0)
        props.displayActions.changeModalIsDisplayed(false)
      })
  }

  useEffect(() => {
    fetchQuestionnaire()
  }, [])

  return (
    <>
      <div style={{ height: '25%' }}>
        <h2 style={{ margin: 0 }}><FormattedMessage id='questionnaire' /></h2>
        <p style={{ marginBottom: '1.25em', marginTop: 0 }}><FormattedMessage id='questionnaire-desc' /></p>
      </div>
      <div
        style={{ height: '75%', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
        onScroll={event => onScrollHandler(event)}>
        {questionnaires.map(mapContent)}
      </div>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionnaireList)
