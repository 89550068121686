import React from 'react'

interface IProps {
  onClick?: () => void
}

const QrCodeSvg = (props: IProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='121'
    height='122'
    viewBox='0 0 121 122'
    onClick={() => props.onClick && props.onClick()}
  >
    <defs>
      <linearGradient
        id='a'
        x1='0.5'
        y1='-0.138'
        x2='0.5'
        y2='1.191'
        gradientUnits='objectBoundingBox'
      >
        <stop
          offset='0'
          stopColor='var(--ButtonPrimary)'
        />
        <stop
          offset='1'
          stopColor='var(--ButtonSecondary)'
        />
      </linearGradient>
    </defs>
    <path
      style={{
        fill: 'url(#a)',
      }}
      // tslint:disable-next-line: max-line-length
      d='M40.568,11.059H12.4V39.23h28.17Zm7.043-7.043V46.272H5.356V4.017ZM19.441,18.1H33.526V32.187H19.441Zm91.553-7.043H82.824V39.23h28.17V11.059Zm7.043-7.043V46.272H75.781V4.017h42.255ZM89.867,18.1h14.085V32.187H89.867Zm-49.3,63.383H12.4v28.17h28.17Zm7.043-7.043V116.7H5.356V74.442ZM19.441,88.528H33.526v14.085H19.441ZM54.654,4.017H61.7v7.043H54.654ZM61.7,11.059h7.043V18.1H61.7ZM54.654,18.1H61.7v7.043H54.654ZM61.7,25.144h7.043v7.043H61.7Zm-7.043,7.043H61.7V39.23H54.654ZM61.7,39.23h7.043v7.043H61.7Zm-7.043,7.043H61.7v7.043H54.654Zm0,14.085H61.7V67.4H54.654ZM61.7,67.4h7.043v7.043H61.7Zm-7.043,7.043H61.7v7.043H54.654ZM61.7,81.485h7.043v7.043H61.7Zm-7.043,7.043H61.7V95.57H54.654ZM61.7,95.57h7.043v7.043H61.7Zm-7.043,7.043H61.7v7.043H54.654Zm7.043,7.043h7.043V116.7H61.7Zm49.3-49.3h7.043V67.4h-7.043Zm-98.6,0h7.043V67.4H12.4Zm7.043-7.043h7.043v7.043H19.441Zm-14.085,0H12.4v7.043H5.356Zm28.17,0h7.043v7.043H33.526Zm7.043,7.043h7.043V67.4H40.568Zm7.043-7.043h7.043v7.043H47.611Zm21.128,7.043h7.043V67.4H68.739Zm7.043-7.043h7.043v7.043H75.781Zm7.043,7.043h7.043V67.4H82.824Zm7.043-7.043h7.043v7.043H89.867Zm7.043,7.043h7.043V67.4H96.909Zm7.043-7.043h7.043v7.043h-7.043Zm7.043,21.128h7.043v7.043h-7.043Zm-42.255,0h7.043v7.043H68.739ZM75.781,67.4h7.043v7.043H75.781Zm7.043,7.043h7.043v7.043H82.824Zm14.085,0h7.043v7.043H96.909Zm7.043-7.043h7.043v7.043h-7.043Zm7.043,21.128h7.043V95.57h-7.043Zm-42.255,0h7.043V95.57H68.739Zm7.043-7.043h7.043v7.043H75.781Zm14.085,0h7.043v7.043H89.866Zm7.043,7.043h7.043V95.57H96.909Zm7.043-7.043h7.043v7.043h-7.043Zm7.043,21.128h7.043v7.043h-7.043ZM75.781,95.57h7.043v7.043H75.781Zm7.043,7.043h7.043v7.043H82.824Zm7.043-7.043h7.043v7.043H89.866Zm7.043,7.043h7.043v7.043H96.909Zm-21.128,7.043h7.043V116.7H75.781Zm14.085,0h7.043V116.7H89.866Zm14.085,0h7.043V116.7h-7.043Z'
      transform='translate(-5.356 -4.017)'
    />
    <rect
      style={{
        fill: 'none',
      }}
      width='121'
      height='122'
      transform='translate(0)'
    />
  </svg>
)

export default QrCodeSvg
