import React, { CSSProperties, RefObject } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../../../../css/slide-show.css'
import { isEmployeePicture, Picture } from '../../../../../models/IPicture'
import DefaultUserImage from '../../../../../static/user_default.png'
import { IApplicationState } from '../../../../../Store'
import Image from '../../../../Shared/Image'

const mapStateToProps = (_: IApplicationState, __: { params: string }) => {
  return {}
}

const mapDispatchToProps = (_: Dispatch) => {
  return {}
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    picture: Picture,
    style: CSSProperties,
    contentRef?: (instance: HTMLImageElement | null) => void,
  }

type refType =
  | string
  | ((instance: HTMLImageElement | null) => void)
  | RefObject<HTMLImageElement>
  | null
  | undefined

const PictureCardImage = React.forwardRef((props: IAllProps, ref: refType) => {

  const defaultImage = isEmployeePicture(props.picture)
    ? DefaultUserImage
    // INFO (alexsass) : This his an empty image. It his used since an
    // image needs to have a src.
    : 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

  return (
    <Image
      pictureId={props.picture.imageSrc}
      style={props.style}
      contentRef={props.contentRef}
      defaultImage={defaultImage}
      ref={ref}
    />
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(PictureCardImage)
