import React from 'react'
import { connect } from 'react-redux'
import '../../../../css/punch.css'
import { IEmployeePicture } from '../../../../models/IPicture'
import codeClock from '../../../../static/punch_clock_border.png'
import { IApplicationState } from '../../../../Store'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    clock: state.questionnaire.punch.clock,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> & {
  employee: IEmployeePicture,
}

const Clock = (props: IAllProps) => {
  return (
    // tslint:disable
    <svg width='100%' height='100%' viewBox='0 0 400 400' preserveAspectRatio='xMidYMid meet' >
      <rect id='svgEditorBackground' x='0' y='0' width='100%' height='100%' style={{ fill: 'none', stroke: 'none' }} />
      <image
        x='0.4664583206176758'
        y='95.54563903808594'
        width='400'
        height='400'
        id='e4_image'
        preserveAspectRatio='xMidYMid meet'
        href={codeClock}
        style={{
          stroke: 'black',
          strokeWidth: '1px',
          fill: 'rosybrown'
        }}
        transform='matrix(0.943633 0 0 0.946922 10.7584 -73.5123)' />
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='254.5' y='58' id='e6_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[0]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='357.5' y='133' id='e1_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[1]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='411.5' y='193' id='e2_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[2]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='488.5' y='284' id='e3_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[3]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='421.5' y='378' id='e4_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[4]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='347.5' y='439' id='e5_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[5]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='259.5' y='518' id='e8_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[6]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='158.5' y='438' id='e9_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[7]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='96.5' y='386' id='e10_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[8]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='26.5' y='285' id='e11_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[9]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='88.5' y='196' id='e12_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[10]}</text>
      <text style={{ fill: 'yellow', fontFamily: 'Arial', fontSize: '20px' }} x='156.5' y='128' id='e13_texte' transform='matrix(0.726981 0 0 0.729515 7.94195 2.31615)'>{props.clock.digits[11]}</text>
    </svg>
  )
}

export default connect(
  mapStateToProps,
  null,
)(Clock)
