import StyleKeyType from '../enums/StyleKeyType'

export class EsColor {
  public readonly id: number
  public readonly keyType: StyleKeyType
  public readonly color: string | null

  constructor(dto: IEsColorDto) {
    this.id = dto.id
    this.keyType = dto.keyType
    this.color = dto.color
  }
}

export interface IEsColorDto {
  id: number
  keyType: StyleKeyType
  color: string | null
}
