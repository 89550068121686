import { EsColors, IEsColorsDto } from '../models/colors/IEsColors'
import { mapCouponServerResponse } from './coupon'
import { get, post } from './fetch'
import { mapLastPageInfoServerResponse } from './lastPage'
import { mapQuestionnaireServerResponse } from './questionnaires'
import { mapQuestionsServerResponse } from './questions'

export const generateVisit = (key: string, url?: string) =>
  post({
    hostname: url,
    path: `experiencestreams/onthego/${key}/visit/generate`,
    data: {
      userAgent: window.navigator.userAgent,
    },
  })
    .then(res => res.json())
    .then((tempVisitKey: string) => tempVisitKey)

export const updateVisit = (
  key: string,
  visitKey: string,
  clientResponseGuid: string,
  url?: string,
): Promise<void> =>
  post({
    hostname: url,
    path: `experiencestreams/onthego/${key}/visit/${visitKey}`,
    data: {
      clientResponseGuid,
    },
  })
    .then(() => { /* not empty */ })

export const getOneFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire`,
  })
    .then(res => res.json())
    .then(mapQuestionnaireServerResponse)

export const getQuestionsFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire/questions`,
  })
    .then(res => res.json())
    .then(mapQuestionsServerResponse)

export const getOrgName = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/organization/name`,
  })
    .then(res => res.json())
    .then((orgName: string) => orgName)

export const getLastPageFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire/lastpage`,
  })
    .then(res => res.json())
    .then(mapLastPageInfoServerResponse)

export const getCustomColorsFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire/colors`,
  })
    .then(res => res.json())
    .then((x: IEsColorsDto) => new EsColors(x))

export const getCouponsFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire/coupons`,
  })
    .then(res => res.json())
    .then(mapCouponServerResponse)

export const getActiveTellUsMoreFromKey = (key: string, url?: string) =>
  get({
    hostname: url,
    path: `experiencestreams/onthego/${key}/questionnaire/tellusmore/active`,
  })
    .then(res => res.json())
    .then((active: boolean) => active)
