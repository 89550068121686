import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IClockDigitsResponse, punchEmployee } from '../../../../fetchers/punch'
import { PunchEnum } from '../../../../models/IEmployee'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators, PunchProgressionPage } from '../../../../Store/Display'
import { actionCreators as PunchActionCreators } from '../../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import Button from '../../../Shared/Button'
import PictureCardImage from '../../Questionnaire/PictureQuestion/PictureCard/PictureCardImage'
import AccessCode from './AccessCode/AccessCode'

const connectionPanelStyle: CSSProperties = {
  width: '80%',
  height: '90%',
  borderRadius: '5px',
  backgroundColor: 'rgb(0, 61, 0)',
  boxShadow: '0 0 50px 5px black',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  color: 'white',
  padding: '10px 0',
}

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    punchedEmployees: state.questionnaire.punch.punchedEmployeeList,
    language: state.language.currentLanguage,
    employee: state.questionnaire.punch.selectedEmployee,
    serverAddress: state.contextSelection.serverAddress,
    clockIndexes: state.questionnaire.punch.clock.indexes,
    enteredCode: state.questionnaire.punch.accessCode,
    clockDigits: state.questionnaire.punch.clock.digits,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {

  }

const ConnectionDial = (props: IAllProps) => {
  const tryPunchEmployee = (code: string) => {
    const currentEmployee = props.employee

    if (!currentEmployee) return

    const isEmployeePunched = props.punchedEmployees.some(x => x.employeeId === currentEmployee.employeeId)

    punchEmployee(
      {
        employeeId: currentEmployee.employeeId,
        accessCode: code,
        clockDigitResult: { targetClockDigitIndexes: props.clockIndexes } as IClockDigitsResponse,
        punchTime: new Date(),
        punchType: isEmployeePunched
          ? PunchEnum.PunchedOut
          : PunchEnum.PunchedIn,
        sendOffline: !window.navigator.onLine,
      },
    ).then(ratings => {
      if (ratings) {
        props.punchActions.changeAccessCode('')
        props.punchActions.changeEmployeeRatings(ratings)
        props.displayActions.changePunchProgressionPage(
          isEmployeePunched
            ? PunchProgressionPage.EmployeeConnected
            : PunchProgressionPage.EmployeeDisconnected,
        )
      }
    })
  }

  const verifyCode = () => {
    if (!props.employee) return

    let employeeCode = props.employee.accessCode

    props.clockIndexes.forEach(val => employeeCode += props.clockDigits[val])

    if (employeeCode === props.enteredCode) tryPunchEmployee(employeeCode)
  }

  return (
    <div className='connection-dial'>
      <div style={{ ...connectionPanelStyle }}>
        <h1 style={{ textDecoration: 'underline', fontSize: '45px', marginTop: 0 }}>
          <FormattedMessage id='auth-title-1' />
        </h1>

        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <PictureCardImage
            picture={props.employee}
            serverAddress={props.serverAddress}
            style={{
              width: '15%',
              borderRadius: '5%',
            }}
          />
          <p style={{ fontSize: '35px', margin: '15px' }}>
            <FormattedMessage id='punch-greeting' /> {props.employee && props.employee.name}
          </p>
        </div>
        <div style={{ width: '80%', display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
          <AccessCode />
        </div>
        <Button
          onClick={() => verifyCode()}
          style={{
            fontWeight: 'bold',
            height: '5vh',
            marginBlockEnd: '0.67em',
            fontSize: '20px',
            width: '30%',
          }}
        >
          <FormattedMessage id='confirm' />
        </Button>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectionDial)
