const sum = <T>(callbackfn: (value: T) => boolean, array: T[]) =>
  array
    .reduce((total, curr) =>
      callbackfn(curr)
        ? ++total
        : total,
      0,
    )

const ArrayUtil = {
  sum,
}

export default ArrayUtil
