import React, { RefObject } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Dispatch } from 'redux'
import '../../../../../css/picture-rating.css'
import { Picture } from '../../../../../models/IPicture'
import { IApplicationState } from '../../../../../Store/index'
// tslint:disable-next-line: max-line-length
import { actionCreators as SelectedPictureActionCreators, defaultState as initialSelectedPictureState } from '../../../../../Store/Questionnaire/SelectedPicture'
import { bindActionCreators } from '../../../../../utils/bindActionCreators'

const mapStateToProps = (state: IApplicationState) => {
  return {
    questionnaireState: state.questionnaire,
    selectedPicture: state.questionnaire.selectedPicture,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    selectedPictureActions: bindActionCreators(SelectedPictureActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    picture: Picture,
  }

type refType =
  | string
  | ((instance: HTMLDivElement | null) => void)
  | RefObject<HTMLDivElement>
  | null
  | undefined

const ShadowBackground = React.forwardRef((props: IAllProps, ref: refType) => {

  return <CSSTransition
    in={props.selectedPicture.displayShadowBg && props.picture.id === props.selectedPicture.id}
    timeout={500}
    classNames='fade-green'
    onExited={() => props.selectedPictureActions.changeSelectedPicture(initialSelectedPictureState)}
  >
    {
      props.picture.id === props.selectedPicture.id
        ? <div
          style={{ zIndex: 5 }}
          onClick={() => {
            if (props.selectedPicture.zoomCompleted && !props.selectedPicture.isAnimating) {
              props.selectedPictureActions.changeSelectedPicture({
                ...props.selectedPicture,
                displayShadowBg: false,
                zoomed: false,
              })
            }
          }}
          ref={ref}
        />
        : <></>
    }

  </CSSTransition>
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShadowBackground)
