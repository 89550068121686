import { AnyAction, combineReducers, Reducer } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { IApplicationState } from '..'
import ShellConnecter from '../../dependencies/shell-connecter'
import { generateVisit, getOneFromKey, getOrgName } from '../../fetchers/onTheGo'
import { getQuestionnaire } from '../../fetchers/questionnaires'
import IOnTheGoKey from '../../models/IOnTheGoKey'
import { Language } from '../../models/Language'
import { IAuth } from '../Auth'
import { languageActionCreators } from '../Language'
import { onTheGoActionCreators } from '../OnTheGo'
import * as Coupons from './Coupon'
import * as CurrentAnswer from './CurrentAnswer'
import * as LastPage from './LastPage'
import * as Punch from './Punch'
import * as QuestionnaireInfo from './QuestionnaireInfo'
import * as Questions from './Questions'
import * as SelectedPicture from './SelectedPicture'
import * as Style from './Style'

export interface IQuestionnaireState {
  coupons: Coupons.ICouponState
  currentAnswer: CurrentAnswer.ICurrentAnswerState
  lastPage: LastPage.ILastPageState
  questionnaireInfo: QuestionnaireInfo.IQuestionnaireInfoState
  questions: Questions.IQuestionsState
  selectedPicture: SelectedPicture.ISelectedPictureState
  punch: Punch.IPunchState
  style: Style.IStyleState
}

export const questionnaireActionCreators = {
  questionnaireChanged: (questionnaireGuid: string) =>
    (_: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState) => {
      const state = getState()
      const proxy = state.connection.proxy
      const isLiveConnectionActive = state.connection.isSocketActive

      if (!proxy || !isLiveConnectionActive) return

      ShellConnecter
        .device
        .getDeviceId()
        .then((id: string) => proxy.invoke(
          'UpdateQuestionnaire',
          id,
          questionnaireGuid,
        ))
    },
  changeQuestionnaire: (key: IOnTheGoKey<number, string>) =>
    (dispatch: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState) => {
      const appState = getState()
      const serverAddress = appState.contextSelection.serverAddress
      const isOnTheGo = appState.onTheGo.isOnTheGo

      let auth: IAuth | undefined

      if (appState.contextSelection.tempAuth) {
        auth = appState.contextSelection.tempAuth
      } else if (appState.auth && appState.auth.currentAuth) {
        auth = appState.auth.currentAuth
      } else if (!isOnTheGo) {
        console.error('Need to be identified to change questionnaire!')
        return Promise.resolve()
      } else if (isOnTheGo && !key.onTheGoKey) {
        console.error('Need to have an on the go key to load a questionnaire!')
        return Promise.resolve()
      }

      const questionnairePromise = isOnTheGo && key.onTheGoKey
        ? getOneFromKey(key.onTheGoKey)
        : getQuestionnaire(key.key, serverAddress, auth)

      if (key.onTheGoKey) {
        getOrgName(key.onTheGoKey).then(name => {
          dispatch({ type: 'CHANGE_ORGANIZATION_NAME', name })
        })
      }

      return questionnairePromise
        .then(questionnaire => Promise.all(
          [
            dispatch(CurrentAnswer.currentAnswerActionCreators.reset()),
            dispatch(LastPage.lastPageActionCreators.changeTellUsMoreActived(key)),
            dispatch(LastPage.lastPageActionCreators.changeLastPageInformations(key)),
            dispatch(Questions.questionsActionCreators.changeQuestions(key, questionnaire.pictureType)),
            dispatch(QuestionnaireInfo.questionnaireInfoActionCreators.changeQuestionnaireInfos(questionnaire)),
            dispatch(Style.styleActionCreators.changeCustomStyle(key)),
            dispatch(Coupons.couponsActionCreators.changeCoupons(key)),
            dispatch(Coupons.couponsActionCreators.changeCouponRetrieveModes(questionnaire.enabledCouponRetrieveModes)),
            dispatch(languageActionCreators.changeAvailableLanguage(questionnaire.enabledCultures as Language[])),
          ],
        ))
        .then(() => {
          if (!isOnTheGo || !key.onTheGoKey) return

          generateVisit(key.onTheGoKey)
            .then(tempVisitKey =>
              dispatch(onTheGoActionCreators.changeVisitKey(tempVisitKey)))
        })
        .catch(() => dispatch(QuestionnaireInfo.questionnaireInfoActionCreators.resetQuestionnaireInfos()))
    },
}

export const reducer: Reducer<IQuestionnaireState> =
  combineReducers<IQuestionnaireState>({
    coupons: Coupons.reducer,
    currentAnswer: CurrentAnswer.reducer,
    lastPage: LastPage.reducer,
    questionnaireInfo: QuestionnaireInfo.reducer,
    questions: Questions.reducer,
    selectedPicture: SelectedPicture.reducer,
    punch: Punch.reducer,
    style: Style.reducer,
  })
