import QRCode from 'qrcode.react'
import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import '../../../css/punch.css'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import { retrieveCouponUrl } from '../../../utils/url'
import Button from '../../Shared/Button'

const flexDisplayColumn: CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
}

const centerContent: CSSProperties = {
  alignItems: 'center',
  justifyContent: 'center',
}

const mapStateToProps = (state: IApplicationState) => ({
  serverAddress: state.contextSelection.serverAddress,
  clientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
  currentLanguage: state.language.currentLanguage,
  media: state.display.media,
  isOnTheGo: state.onTheGo.isOnTheGo,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    isPopup: boolean,
    close: () => void,
  }

const RetrieveCouponsQR = (props: IAllProps) => {

  if (!props.clientResponseGuid) return <></>

  const next = () => {
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)
    props.close()
  }

  return (
    <div style={{ width: '100%', height: '100%', fontSize: props.media.isAtLeastTablet ? '5vh' : '7vh' }}>
      <p style={{ marginTop: 0, marginBottom: '3vh' }}>
        <FormattedMessage id='coupon-online-qr' />
      </p>
      <div id='qr' style={{ ...flexDisplayColumn, ...centerContent }}>
        <QRCode
          size={1024}
          value={retrieveCouponUrl(props.serverAddress, props.clientResponseGuid)}
        />
      </div>
      {
        props.isPopup &&
        <div style={{ marginTop: '3vh' }}>
          <Button
            style={{
              fontSize: '3vh',
              fontWeight: 'bold',
              margin: '10px',
            }}
            onClick={props.close}
          >
            <FormattedMessage id='back' />
          </Button>
          {
            !props.isOnTheGo &&
            <Button
              style={{
                fontSize: '3vh',
                fontWeight: 'bold',
                margin: '10px',
              }}
              onClick={next}
            >
              <FormattedMessage id='next' />
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetrieveCouponsQR)
