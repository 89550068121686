import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import '../../css/button.css'
import '../../css/onthego-lastpage.css'
import { IApplicationState } from '../../Store/index'

const mapStateToProps = (state: IApplicationState) => {
  return {
    lastPageInfo: state.questionnaire.lastPage,
    languageState: state.language,
    couponToSend: state.questionnaire.coupons.couponsToRetrieve,
    serverAddress: state.contextSelection.serverAddress,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const OnTheGoLastPage = (props: IAllProps) => {

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        <div className='thank-you-message'>
          <img className='lastpage-image' src={`${props.lastPageInfo.customLogo}`} />
          <FormattedMessage id='thank-you'>{msg => <p>{msg}!</p>}</FormattedMessage>
        </div>
      </div>
      <FormattedMessage id='powered-by'>
        {
          msg =>
            <p
              className='powered-by-message'
            >
              {msg} <a
                href='https://www.experiencestream.com/fr/accueil/'
                style={{ color: 'var(--ButtonPrimary)' }}
              >
                ExperienceStream
              </a>
            </p>
        }
      </FormattedMessage>
    </>
  )
}

export default connect(
  mapStateToProps,
  null,
)(OnTheGoLastPage)
