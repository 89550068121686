import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import '../../../css/punch.css'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import { retrieveCouponUrl } from '../../../utils/url'
import Button from '../../Shared/Button'

const mapStateToProps = (state: IApplicationState) => ({
  serverAddress: state.contextSelection.serverAddress,
  currentLanguage: state.language.currentLanguage,
  displayState: state.display,
  clientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
  media: state.display.media,
  isOnTheGo: state.onTheGo.isOnTheGo,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    isPopup: boolean,
    close: () => void,
  }

const RetrieveCouponsURL = (props: IAllProps) => {

  if (!props.clientResponseGuid) return <></>

  const next = () => {
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)
    props.close()
  }

  return (
    <div style={{ width: '100%', height: '100%', fontSize: props.media.isAtLeastTablet ? '5vh' : '10vh' }}>
      <p style={{ margin: 0 }}>
        <FormattedMessage id='coupon-online-url' />
      </p>
      <p
        className='coupon-online-url-link'
        style={{
          margin: 0,
          fontFamily: 'NerisSemiBold',
          fontSize: props.media.isAtLeastTablet
            ? '3vh'
            : '5vh',
          color: 'var(--GeneralFontColor)',
        }}>
        {retrieveCouponUrl(props.serverAddress, props.clientResponseGuid)}
      </p>
      {
        props.isPopup &&
        <div style={{ marginTop: '3vh' }}>
          <Button
            style={{
              fontSize: '3vh',
              fontWeight: 'bold',
              margin: '10px',
            }}
            onClick={props.close}
          >
            <FormattedMessage id='back' />
          </Button>
          {
            !props.isOnTheGo &&
            <Button
              style={{
                fontSize: '3vh',
                fontWeight: 'bold',
                margin: '10px',
              }}
              onClick={next}
            >
              <FormattedMessage id='next' />
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetrieveCouponsURL)
