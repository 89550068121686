import React from 'react'
import { IoMdBackspace } from 'react-icons/io'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../../../../css/punch.css'
import { IEmployeePicture } from '../../../../../models/IPicture'
import { IApplicationState } from '../../../../../Store'
import { actionCreators as PunchActionCreators } from '../../../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../../../utils/bindActionCreators'
import AccessCodeDial from './AccessCodeDial'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    code: state.questionnaire.punch.accessCode,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    employee: IEmployeePicture,
  }

const AccessCode = (props: IAllProps) => {

  const removeChar = () => {
    const code = props.code

    return code.substring(0, code.length - 1)
  }

  return (
    <>
      <div className='access-code-input'>
        <input type='password' value={props.code} disabled />
        <IoMdBackspace
          size='8vh'
          className='btn-backspace'
          onClick={() => props.punchActions.changeAccessCode(removeChar())}
        />
      </div>
      <AccessCodeDial />
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccessCode)
