import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Dispatch } from 'redux'
import { IPictureQuestion } from '../../../../models/IPictureQuestion'
import { typeOfAnswerQuestion } from '../../../../models/IQuestion'
import { SelectedPictureBarType } from '../../../../models/IQuestionnaire'
import { actionCreators as DisplayActionCreators } from '../../../../Store/Display'
import { IApplicationState } from '../../../../Store/index'
import ArrayUtil from '../../../../utils/array'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import PictureRating from './PictureRating'
import PictureSlideShow from './PictureSlideShow'

const mapStateToProps = (state: IApplicationState) => {
  return {
    answers: state.questionnaire.currentAnswer.answers,
    questionnaireState: state.questionnaire,
    languageState: state.language,
    displayState: state.display,
    punchedEmployees: state.questionnaire.punch.punchedEmployeeList,
    areYouStillThereDisplayed: state.display.areYouStillThereIsDisplayed,
    media: state.display.media,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    question: IPictureQuestion,
    canAnswer: boolean,
    onClick: () => void,
  }

const PictureQuestion = (props: IAllProps) => {

  const getPictures = () =>
    props.questionnaireState.questions.pictureQuestionType === SelectedPictureBarType.Employee
      ? [...props.punchedEmployees]
      : [
        ...props
          .question
          .pictures
          .concat()
          .sort((p1, p2) => p1.id < p2.id ? -1 : 1),
      ]

  const [isAnswerChoicesCompleted, setIsAnswerChoicesCompleted] = useState(false)
  const [pictureOverflow, setPictureOverflow] = useState(false)
  const [maximumElement, setMaximumElement] = useState(0)
  const [pictures, setPictures] = useState(getPictures())
  const [fadeProp, setFadeProp] = useState(props.questionnaireState.selectedPicture.id === -1)
  const [fadePropEnded, setFadePropEnded] = useState(false)
  const [ratingFadeEnded, setRatingFadeEnded] = useState(false)
  const [takeFullSpace, setTakeFullSpace] = useState(false)
  const [passedDynamicView, setPassedDynamicView] = useState(false)

  const ref = React.createRef<HTMLDivElement>()

  const calcMaxElement = () => {
    const maxElement = Math.floor(window.innerWidth / 200)
    setMaximumElement(maxElement)
    setPictureOverflow(pictures.length > maxElement && props.media.isAtLeastTablet)
    setFadePropEnded(pictures.length <= maxElement || !props.media.isAtLeastTablet)
  }

  useEffect(() => calcMaxElement(), [pictures])

  useEffect(() => {
    if (props.areYouStillThereDisplayed || props.answers.size !== 0) return

    setPictureOverflow(false)
    setFadeProp(props.questionnaireState.selectedPicture.id === -1)
    setFadePropEnded(false)
    setRatingFadeEnded(false)
    setTakeFullSpace(false)
    calcMaxElement()
  }, [props.areYouStillThereDisplayed])

  useEffect(() => {
    if (passedDynamicView) return
    calcMaxElement()
  }, [props.media.width])

  useEffect(() => {
    setPictures(getPictures())
  }, [props.punchedEmployees])

  useEffect(() => {
    setPictures(getPictures())
  }, [props.question])

  useEffect(() => {
    const questionnaireQuestions = props.questionnaireState.questions.questions
    if (!questionnaireQuestions) return

    const answerKeys = Array.from(props.answers.keys())
    const totalAnswerChoiceAnswers = ArrayUtil
      .sum(x => typeOfAnswerQuestion(x), answerKeys)

    const totalAnswerQuestions = ArrayUtil
      .sum(x => typeOfAnswerQuestion(x), questionnaireQuestions)

    setIsAnswerChoicesCompleted(
      totalAnswerChoiceAnswers === totalAnswerQuestions,
    )
  }, [props.questionnaireState.currentAnswer.answers.size])

  useEffect(() => {
    calcMaxElement()
  }, [])

  useEffect(() => {
    const thisElem = ref.current

    if (!thisElem) return

    if (pictureOverflow) thisElem.style.setProperty('--picture-question-height', `100vh`)
    else {
      thisElem
        .style
        .setProperty(
          '--picture-question-height',
          `${isAnswerChoicesCompleted && (takeFullSpace || (pictures.length > maximumElement && !pictureOverflow))
            ? Math.min((props.questionnaireState.currentAnswer.answers.size) * 25, 75)
            : 28
          }vh`,
        )
    }
  }, [
    pictureOverflow,
    props.questionnaireState.currentAnswer.answers.size,
    isAnswerChoicesCompleted,
    takeFullSpace,
  ])

  return pictures.length > 0
    ? <div
      ref={ref}
      className='question picture-question'
      style={{
        opacity: isAnswerChoicesCompleted
          ? 1
          : 0.5,
      }}
    >
      {
        pictureOverflow &&
        <CSSTransition
          in={fadeProp}
          classNames='fade'
          timeout={750}
          onExited={() => {
            setFadePropEnded(true)
            setPictureOverflow(false)
            setPassedDynamicView(true)
          }}
          unmountOnExit
        >
          <PictureSlideShow
            question={props.question}
            pictures={pictures}
            maximumElement={maximumElement}
            canAnswer={props.canAnswer}
            onClick={() => {
              if (props.canAnswer) {
                props.onClick()
                setFadeProp(false)
              }
            }}
          />
        </CSSTransition>
      }
      <CSSTransition
        in={fadePropEnded}
        classNames='fade'
        timeout={{ enter: 750, exit: 0 }}
        onEntered={() => setRatingFadeEnded(true)}
        mountOnEnter
        unmountOnExit
      >
        <PictureRating
          question={props.question}
          pictures={pictures}
          readyForZoom={ratingFadeEnded}
          canAnswer={props.canAnswer}
          onClick={() => {
            if (!props.canAnswer) return

            props.onClick()
            setTakeFullSpace(true)
            setPassedDynamicView(true)
          }}
        />
      </CSSTransition>
    </div>
    : <></>
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PictureQuestion)
