import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IApplicationState } from '../../../../Store'
import { actionCreators as PunchActionCreators } from '../../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import CodeClock from './CodeClock'
import ConnectionDial from './ConnectionDial'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    clock: state.questionnaire.punch.clock,
    serverAddress: state.contextSelection.serverAddress,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const EmployeeConnection = (props: IAllProps) => {

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    props.punchActions.changeClock({
      ...props.clock,
      digits: new Array(12).fill(null).map(n => Math.floor(Math.random() * 10)),
    })
    setMounted(true)
  }, [])

  return mounted
    ? (
      <div className='connection-container'>
        <CodeClock />
        <ConnectionDial />
      </div>
    )
    : <></>
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeConnection)
