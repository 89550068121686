import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { SelectedPictureBarType } from '../../models/IQuestionnaire'
import { IApplicationState } from '../../Store'
import { actionCreators as DisplayActionCreators } from '../../Store/Display'
import FlashingLight, { ColorState } from './SubComponent/FlashingLight'
import LanguageDropdown from './SubComponent/LanguageDropdown/LanguageDropdown'

const mapStateToProps = (state: IApplicationState) => ({
  languageState: state.language,
  displayState: state.display,
  userClickState: state.userClick,
  contextSelection: state.contextSelection,
  questionnaireId: state.questionnaire.questionnaireInfo.id,
  connectionState: state.connection,
  punchActive: state.questionnaire.questions.pictureQuestionType === SelectedPictureBarType.Employee,
  isOnTheGo: state.onTheGo.isOnTheGo,
  media: state.display.media,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
) => ({
  displayActions: bindActionCreators(DisplayActionCreators, dispatch),
})

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const Header = (props: IAllProps) => {
  const [lightClickCount, setLightClickCount] = useState(0)

  useEffect(() => {
    if (!props.userClickState.userClick) setLightClickCount(0)
  }, [])

  const onListClick = () => {
    setLightClickCount(lightClickCount + 1)

    if (props.connectionState.isConnectedToShell && lightClickCount === 1) {
      props.displayActions.changeModalPage(0)
      props.displayActions.changeModalIsDisplayed(true)
      setLightClickCount(0)
    }
  }

  const SideBySideLights = (withOnClick: boolean) => !props.isOnTheGo &&
    <div
      style={{
        display: 'flex',
        width: '100px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '5vh',
      }}
      onClick={() => withOnClick && onListClick()}
    >
      {
        !props.userClickState.userClick && props.questionnaireId !== -1 &&
        <>
          <FlashingLight initialColorState={ColorState.First} />
          <FlashingLight initialColorState={ColorState.Second} />
        </>
      }
    </div>

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 30px',
        height: '5vh',
        backgroundColor: props.media.isMobile ? 'rgba(0, 0, 0, 0.04)' : 'unset',
      }}
    >
      {SideBySideLights(true)}
      {props.media.isMobile &&
        <div
          style={{
            width: '100%',
            alignContent: 'flex-start',
            display: 'flex',
          }}
        >
          {props.contextSelection.organization.name}
        </div>
      }
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          height: 'inherit',
        }}
      >
        {
          props.languageState.availableLanguages.length > 1 &&
          <LanguageDropdown />
        }
      </div>
      {SideBySideLights(false)}
    </div >
  )

}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header)
