import { Action, Dispatch, Reducer } from 'redux'
import { IApplicationState } from '.'
import ICouponResultDto from '../models/dtos/ICouponResultDto'
import CouponRetrieveMode from '../models/enums/CouponRetrieveMode'
import { Coupon } from '../models/ICoupon'
import { verifyQuestionResultForCoupons } from '../utils/coupon'
import { generateGuid } from '../utils/guid'

const xsSize = 600
const smSize = 960
const mdSize = 1280

export enum QuestionnaireContentPage {
  EmployeeDepartment,
  Questionnaire,
  CouponRetrieve,
  LastPage,
  TellUsMore,
  OnTheGoLastPage,
}

export enum PunchProgressionPage {
  EmployeeSelection,
  EmployeeConnection,
  EmployeeConnected,
  EmployeeDisconnected,
}

export enum RetrieveCouponsPage {
  Print,
  SMS,
  URL,
  QR,
  Picture,
}

export interface IDisplayState {
  content: number
  punchProgressionPage: PunchProgressionPage
  modalPage: number
  modalIsDisplayed: boolean
  questionnaireContent: QuestionnaireContentPage
  areYouStillThereIsDisplayed: boolean
  retrieveCouponsIsDisplayed: boolean
  retrieveCouponsPage: RetrieveCouponsPage
  media: {
    width: number,
    isAtLeastTablet: boolean,
    isDesktop: boolean,
    isTablet: boolean,
    isMobile: boolean,
    isXsMobile: boolean,
  }
}

interface IChangeDisplayContent {
  type: 'CHANGE_DISPLAY_CONTENT'
  content: number
}
interface IChangeModalPage {
  type: 'CHANGE_MODAL_PAGE'
  page: number
}
interface IChangeModalIsDisplayed {
  type: 'CHANGE_MODAL_IS_DISPLAYED'
  isDisplayed: boolean
}
interface IChangeQuestionnaireContent {
  type: 'CHANGE_QUESTIONNAIRE_CONTENT'
  content: number
}
interface IChangePunchProgressionPage {
  type: 'CHANGE_PUNCH_PROGRESSION_PAGE'
  page: PunchProgressionPage
}

interface IChangeDisplayAreYouStillThere {
  type: 'CHANGE_DISPLAY_ARE_YOU_STILL_THERE'
  isDisplayed: boolean
}

interface IChangeDisplayRetrieveCoupons {
  type: 'CHANGE_DISPLAY_RETRIEVE_COUPONS'
  isDisplayed: boolean
}

interface IChangeRetrieveCouponsPage {
  type: 'CHANGE_RETRIEVE_COUPONS_PAGE'
  page: RetrieveCouponsPage
}

interface IChangeWindowWidth {
  type: 'CHANGE_WINDOW_WIDTH',
  width: number,
}

type KnownAction =
  | IChangeDisplayContent
  | IChangeModalPage
  | IChangeModalIsDisplayed
  | IChangeQuestionnaireContent
  | IChangePunchProgressionPage
  | IChangeDisplayAreYouStillThere
  | IChangeDisplayRetrieveCoupons
  | IChangeRetrieveCouponsPage
  | IChangeWindowWidth

export const actionCreators = {
  changeContent: (content: number) =>
    ({ type: 'CHANGE_DISPLAY_CONTENT', content }),
  changeModalPage: (page: number) =>
    ({ type: 'CHANGE_MODAL_PAGE', page }),
  changeModalIsDisplayed: (isDisplayed: boolean) =>
    ({ type: 'CHANGE_MODAL_IS_DISPLAYED', isDisplayed }),
  changeQuestionnaireContent: (content: QuestionnaireContentPage) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const state = getState()

      const isOnTheGo = state.onTheGo.isOnTheGo
      const questionnaireContent = state.display.questionnaireContent
      const currentAnswers = state.questionnaire.currentAnswer.answers
      const availableCoupons = state.questionnaire.coupons.coupons
      const clientCount = state.questionnaire.lastPage.clientCount
      const questionnaireGuid = state.questionnaire.questionnaireInfo.experienceStreamGuid
      const currentLanguage = state.language.currentLanguage
      const isPrinterAvailable = state.connection.isPrinterAvailable
      const retrieveModes = state.questionnaire.coupons.couponRetrieveModes

      const mapPrintedCoupon = (coupon: Coupon, success: boolean) => ({
        couponId: coupon.id,
        barCodeValue: coupon.barCodeValue,
        trackingNumber: generateGuid(),
        experienceStreamGuid: questionnaireGuid,
        couponPrintedCulture: currentLanguage,
        printed: false,
        failedToPrint: success,
      }) as ICouponResultDto

      if (content === QuestionnaireContentPage.Questionnaire) {
        dispatch({ type: 'CHANGE_TELL_US_MORE_SURVEY_KEY', key: undefined })
      }

      if (content === QuestionnaireContentPage.CouponRetrieve) {
        const coupons = verifyQuestionResultForCoupons(
          currentAnswers,
          availableCoupons,
          clientCount,
        )

        dispatch({
          type: 'CHANGE_COUPONS_TO_RETRIEVE',
          coupons: coupons.map(x => mapPrintedCoupon(x, false)),
        })
        dispatch({ type: 'CHANGE_COUPONS_TO_PRINT', coupons })

        const printNotAvailable = !isPrinterAvailable &&
          retrieveModes.length === 1 &&
          retrieveModes.some(x => x === CouponRetrieveMode.Print)

        dispatch({
          type: 'CHANGE_QUESTIONNAIRE_CONTENT',
          content: retrieveModes.length === 0 || coupons.length === 0 || printNotAvailable
            ? QuestionnaireContentPage.LastPage
            : QuestionnaireContentPage.CouponRetrieve,
        })
      } else {
        dispatch({
          type: 'CHANGE_QUESTIONNAIRE_CONTENT',
          content: isOnTheGo &&
            content === QuestionnaireContentPage.Questionnaire &&
            (
              questionnaireContent === QuestionnaireContentPage.LastPage ||
              questionnaireContent === QuestionnaireContentPage.TellUsMore
            )
            ? QuestionnaireContentPage.OnTheGoLastPage
            : content,
        })
      }
    },
  changePunchProgressionPage: (page: PunchProgressionPage) =>
    ({ type: 'CHANGE_PUNCH_PROGRESSION_PAGE', page }),
  changeDisplayAreYouStillThereIsDisplayed: (isDisplayed: boolean) =>
    ({ type: 'CHANGE_DISPLAY_ARE_YOU_STILL_THERE', isDisplayed }),
  changeDisplayRetrieveCoupons: (isDisplayed: boolean) =>
    ({ type: 'CHANGE_DISPLAY_RETRIEVE_COUPONS', isDisplayed }),
  changeRetrieveCouponsPage: (page: RetrieveCouponsPage) =>
    ({ type: 'CHANGE_RETRIEVE_COUPONS_PAGE', page }),
  changeWindowWidth: (width: number) =>
    ({ type: 'CHANGE_WINDOW_WIDTH', width }),
}

const getCurrentMediaSize = (width: number) => {
  const isXsMobile = width <= xsSize
  const isMobile = width <= smSize
  const isTablet = smSize < width && width <= mdSize
  const isDesktop = mdSize < width

  const isAtLeastTablet = isTablet || isDesktop

  return {
    width,
    isAtLeastTablet,
    isDesktop,
    isTablet,
    isMobile,
    isXsMobile,
  }
}

const defaultState: IDisplayState = {
  content: 0,
  punchProgressionPage: PunchProgressionPage.EmployeeSelection,
  modalPage: 0,
  modalIsDisplayed: false,
  questionnaireContent: 1,
  areYouStillThereIsDisplayed: false,
  retrieveCouponsIsDisplayed: false,
  retrieveCouponsPage: RetrieveCouponsPage.SMS,
  media: getCurrentMediaSize(window.innerWidth),
}

export const reducer: Reducer<IDisplayState> =
  (state: IDisplayState | undefined, incomingAction: Action): IDisplayState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_DISPLAY_CONTENT':
        return {
          ...state,
          content: action.content,
        }
      case 'CHANGE_MODAL_PAGE':
        return {
          ...state,
          modalPage: action.page,
        }
      case 'CHANGE_MODAL_IS_DISPLAYED':
        return {
          ...state,
          modalIsDisplayed: action.isDisplayed,
        }
      case 'CHANGE_QUESTIONNAIRE_CONTENT':
        return {
          ...state,
          questionnaireContent: action.content,
        }
      case 'CHANGE_PUNCH_PROGRESSION_PAGE':
        return {
          ...state,
          punchProgressionPage: action.page,
        }
      case 'CHANGE_DISPLAY_ARE_YOU_STILL_THERE':
        return {
          ...state,
          areYouStillThereIsDisplayed: action.isDisplayed,
        }
      case 'CHANGE_DISPLAY_RETRIEVE_COUPONS':
        return {
          ...state,
          retrieveCouponsIsDisplayed: action.isDisplayed,
        }
      case 'CHANGE_RETRIEVE_COUPONS_PAGE':
        return {
          ...state,
          retrieveCouponsPage: action.page,
        }
      case 'CHANGE_WINDOW_WIDTH':
        return {
          ...state,
          media: getCurrentMediaSize(action.width),
        }
      default:
        return state
    }
  }
