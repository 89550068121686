import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Dispatch } from 'redux'
import '../../../../css/slide-show.css'
import { isEmployeePicture, Picture } from '../../../../models/IPicture'
import { IPictureQuestion } from '../../../../models/IPictureQuestion'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../../Store/Display'
// tslint:disable-next-line: max-line-length
import { actionCreators as SelectedPictureActionCreators, defaultState as initialSelectedPictureState } from '../../../../Store/Questionnaire/SelectedPicture'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import QuestionRow from '../QuestionRow'
import AnimatedCard from './AnimatedCard'

const mapStateToProps = (state: IApplicationState) => {
  return {
    answers: state.questionnaire.currentAnswer.answers,
    questions: state.questionnaire.questions.questions,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    selectedPictureActions: bindActionCreators(SelectedPictureActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    question: IPictureQuestion,
    pictures: Picture[],
    maximumElement: number,
    canAnswer: boolean,
    onClick: () => void,
  }

const PictureSlideShow = (props: IAllProps) => {
  const pictureBarContainer = React.createRef<HTMLDivElement>()

  const [mounted, setMounted] = useState(false)
  const [moving, setMoving] = useState(false)
  const [pictures, setPictures] = useState(props.pictures)

  useEffect(() => {
    setMounted(true)
    setMoving(true)
  }, [])

  useEffect(() => {
    setPictures(props.pictures)
  }, [props.pictures])

  const movePictureToEnd = () => {
    const firstPicture = pictures[0]

    const removeFirstPicture = pictures.filter((_, i) => i !== 0)
    const addFirstPicture = [...removeFirstPicture, firstPicture]

    setMoving(false)
    setPictures(addFirstPicture)
  }

  return (
    <QuestionRow
      questionName={props.question.name}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          height: '100%',
          justifyContent: 'center',
          marginTop: '10px',
        }}
        ref={pictureBarContainer}
      >
        <div
          className='picture-show'
        >
          <CSSTransition
            in={mounted && moving}
            timeout={{ enter: 1500, exit: 0 }}
            classNames='moving-div'
            onEntered={() => movePictureToEnd()}
            onExited={() => setMoving(true)}
          >
            <div
              style={{
                position: 'absolute',
                width: pictures.length * 200,
                height: '100%',
              }}
              onClick={() => props.onClick()}
            >
              {
                pictures.map((picture, i) =>
                  i < props.maximumElement + 2
                    ? <AnimatedCard
                      key={isEmployeePicture(picture) ? picture.employeeId : picture.id}
                      picture={picture}
                      index={i}
                      maxDisplayedPicture={props.maximumElement}
                      onClick={() => {
                        if (!props.canAnswer) return

                        props.selectedPictureActions.changeSelectedPicture({
                          ...initialSelectedPictureState,
                          id: picture.id,
                        })
                      }}
                    />
                    : null,
                )
              }
            </div>
          </CSSTransition>
        </div>
      </div>
    </QuestionRow>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PictureSlideShow)
