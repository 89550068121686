import useInterval from '@use-it/interval'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Dispatch } from 'redux'

import '../../../css/question-list.css'
import { Question, typeOfAnswerQuestion, typeOfPictureQuestion } from '../../../models/IQuestion'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { IApplicationState } from '../../../Store/index'
import { currentAnswerActionCreators } from '../../../Store/Questionnaire/CurrentAnswer'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import AreYouStillThere from '../../Modal/AreYouStillThere'
import Button from '../../Shared/Button'
import AnswerChoiceQuestion from './AnswerQuestion/AnswerChoiceQuestion'
import PictureQuestion from './PictureQuestion/PictureQuestion'

const containerStyle: React.CSSProperties = {
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'center',
  flexGrow: 1,
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    answers: state.questionnaire.currentAnswer.answers,
    pictureQuestionType: state.questionnaire.questions.pictureQuestionType,
    questions: state.questionnaire.questions.questions,
    questionnaireId: state.questionnaire.questionnaireInfo.id,
    responseTimerSpeed: state.questionnaire.currentAnswer.responseTimerSpeed,
    displayState: state.display,
    currentLanguage: state.language.currentLanguage,
    punch: state.questionnaire.punch,
    areYouStillThereDisplayed: state.display.areYouStillThereIsDisplayed,
    media: state.display.media,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const QuestionList = (props: IAllProps) => {
  const filterQuestions = () => {
    const pictureQuestion = props.questions.find(x => typeOfPictureQuestion(x))
    const hasPicture = pictureQuestion && typeOfPictureQuestion(pictureQuestion)
      ? pictureQuestion.pictures.length > 0
      : false

    return (
      hasPicture
        ? [...props.questions]
        : [...props.questions.filter(x => !typeOfPictureQuestion(x))]
    )
  }

  const [upProp, setUpProp] = useState(false)
  const [noScroll, setNoScroll] = useState(true)
  const [pictureMissing, setPictureMissing] = useState(false)
  const [questions, setQuestions] = useState(filterQuestions())
  const [isAtPicture, setIsAtPicture] = useState(false)

  const ref = React.createRef<HTMLDivElement>()

  useEffect(() => {
    const hasPicture = questions.some(x => typeOfPictureQuestion(x))

    setPictureMissing(!hasPicture)
    setNoScroll(!hasPicture && questions.length <= 3)
    setIsAtPicture(false)
  }, [questions])

  useEffect(() => {
    if (props.areYouStillThereDisplayed || props.answers.size !== 0) return

    setQuestions(filterQuestions())
  }, [props.areYouStillThereDisplayed])

  useEffect(() => {
    setQuestions(filterQuestions())
  }, [props.questions])

  const canRemoveQuestion = (index: number) => {
    const questionDelay = !props.media.isAtLeastTablet ? 1 : 2
    const answerSize = props.answers.size
    const answeredLastAnswerChoice = isAtPicture && index < questions.length - 2
    const isBeforeDelay = index < answerSize - questionDelay

    const canBeRemoved = isBeforeDelay || answeredLastAnswerChoice || !upProp

    return !props.media.isAtLeastTablet
      ? canBeRemoved
      : !noScroll && canBeRemoved
  }

  const mapQuestions = (question: Question, index: number) => {
    if (typeOfAnswerQuestion(question)) {
      const canAnswer =
        index === props.answers.size ||
        index === props.answers.size - 1 ||
        index === props.answers.size - 2 ||
        noScroll

      return (
        <AnswerChoiceQuestion
          key={question.id}
          canAnswer={canAnswer && !upProp}
          question={question}
          canRemove={
            canRemoveQuestion(index)
          }
          isAtLastQuestion={isAtPicture}
        />
      )
    } else if (typeOfPictureQuestion(question)) {
      const canAnswer = !upProp && index <= props.answers.size
      return <PictureQuestion
        key={question.id}
        question={question}
        canAnswer={canAnswer}
        onClick={() => {
          if (!isAtPicture) {
            updateScrollAmount(27)
            setIsAtPicture(true)
            setUpProp(props.media.isAtLeastTablet)
          }
        }}
      />
    }
  }

  useInterval(
    () => props.currentAnswerActions.increaseResponseTime(),
    props.responseTimerSpeed,
  )

  useEffect(() => {
    if (isAtPicture || upProp) return

    if (props.media.isAtLeastTablet) {
      if (
        noScroll ||
        (
          !(props.answers.size > 1 && props.answers.size < props.questions.length)
        )
      ) return

      updateScrollAmount(27)
      setUpProp(true)
    } else {
      if (noScroll && props.answers.size === props.questions.length - 1) {
        nextPage()
      } else if (noScroll) return

      updateScrollAmount(80)
      setUpProp(true)
    }

  }, [props.answers.size])

  const onEntered = () => {
    if (noScroll) return

    setUpProp(false)
  }

  const nextPage = () => {
    props.currentAnswerActions.changeResponseTimerSpeed(null)
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.CouponRetrieve)
  }

  const updateScrollAmount = (amount: number) => {
    if (!ref.current) return
    ref.current.style.setProperty('--scroll-amount', `-${noScroll ? 0 : amount}vh`)
  }

  if (questions) {
    const canGoToNextPage = (
      (
        pictureMissing &&
        props.answers.size === props.questions.length - 1
      ) ||
      props.answers.size === props.questions.length
    ) && props.questionnaireId !== -1

    const canViewNextPageButton = (
      (
        pictureMissing &&
        props.answers.size > props.questions.length - 2
      ) ||
      (
        props.answers.size >= props.questions.length - 1 &&
        (!props.media.isAtLeastTablet || isAtPicture)
      )
    ) && props.questionnaireId !== -1

    return (
      <>
        {
          props.areYouStillThereDisplayed &&
          <AreYouStillThere />
        }
        <div className={'question-list-container' + (canViewNextPageButton ? ' question-list-space' : '')}>
          <CSSTransition
            in={upProp}
            timeout={1500}
            classNames='up'
            onEntered={onEntered}
          >
            <div
              ref={ref}
              className='question-list-container-scrollable'
              onClick={() => {
                if (!props.responseTimerSpeed) {
                  props.currentAnswerActions.changeResponseTimerSpeed(1000)
                }
              }}
            >
              {
                questions.map(mapQuestions)
              }
            </div>
          </CSSTransition>
        </div>
        {
          !upProp &&
          canViewNextPageButton &&
          <Button
            onClick={nextPage}
            className='next-page-button'
            style={{
              opacity: canGoToNextPage ? 1 : 0.5,
              pointerEvents: canGoToNextPage ? 'auto' : 'none',
            }}
          >
            <FormattedMessage id='done' />
          </Button>
        }
      </>
    )
  } else {
    return <div style={containerStyle} />
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionList)
