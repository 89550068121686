import QuestionResultType from './enums/QuestionResultType'

export const isEmployeePicture = (
  picture: Picture,
): picture is IEmployeePicture => {
  return 'employeeId' in picture
}

export interface IPicture<T> {
  type: QuestionResultType,
  id: number,
  displayOrder: number,
  imageSrc: number,
  name: T,
  report: T,
  value: number | undefined,
}

export interface IEmployeePicture extends IPicture<string> {
  employeeId: number,
  accessCode: string,
}

export interface ICustomPicture extends IPicture<Map<string, string>> {
}

export type Picture = IEmployeePicture | ICustomPicture
