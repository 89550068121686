import React from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { cancelLastPunch, IClockDigitsResponse } from '../../../fetchers/punch'
import { PunchEnum } from '../../../models/IEmployee'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../Store/Display'
import { actionCreators as PunchActionCreators } from '../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import Button from '../../Shared/Button'
import PictureCardImage from '../Questionnaire/PictureQuestion/PictureCard/PictureCardImage'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    code: state.questionnaire.punch.accessCode,
    clockIndexes: state.questionnaire.punch.clock.indexes,
    ratings: state.questionnaire.punch.punchedEmployeeRatings,
    employee: state.questionnaire.punch.selectedEmployee,
    display: state.display.punchProgressionPage,
    language: state.language,
    serverAddress: state.contextSelection.serverAddress,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const EmployeeDisconnected = (props: IAllProps) => {

  const backToQuestions = () => {
    props.displayActions.changePunchProgressionPage(0)
    props.displayActions.changeContent(0)
  }

  const cancelPunch = () => {
    if (!props.employee) return

    cancelLastPunch(
      {
        employeeId: props.employee.employeeId,
        accessCode: props.code,
        clockDigitResult: { targetClockDigitIndexes: props.clockIndexes } as IClockDigitsResponse,
        punchTime: new Date(),
        punchType: PunchEnum.PunchedOut,
        sendOffline: !window.navigator.onLine,
      },
    ).then(() => backToQuestions())
  }

  return (!props.ratings || !props.employee)
    ? <></>
    : <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        color: 'white',
      }}
    >
      <p>Vous êtes... <span>DÉCONNECTÉ</span></p>
      <PictureCardImage
        picture={props.employee}
        serverAddress={props.serverAddress}
        style={{
          width: '10%',
          borderRadius: '5%',
        }}
      />
      <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
        <p>Quel a été votre résultat aujourd'hui?</p>
        <div style={{ width: '100px' }}>
          <CircularProgressbar
            value={props.ratings.averageRatingToday}
            text={`${props.ratings.averageRatingToday}%`}
            circleRatio={0.75}
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 1 / 2 + 1 / 8,
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',
              // Colors
              trailColor: 'orange',
              textColor: 'yellow',
              backgroundColor: 'orange',
              pathColor: 'yellow',
            })}
          />
        </div>
        <p>Nombre total de réponses aujourd'hui: {props.ratings && props.ratings.totalRatingToday}</p>
        <p>À bientôt!</p>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-around',
        }}
      >
        <Button
          onClick={() => cancelPunch()}
          style={{
            fontSize: '22px',
            padding: '6px 85px',
          }}
        >
          Oops!
        </Button>
        <Button
          onClick={() => {
            if (props.employee) props.punchActions.punchEmployee(props.employee.employeeId)
            backToQuestions()
          }}
          style={{
            fontSize: '22px',
            padding: '6px 85px',
          }}
        >
          Continuer
        </Button>
      </div>
    </div>
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeeDisconnected)
