import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import ShellConnecter from '../../../../dependencies/shell-connecter'
import ICouponResultDto from '../../../../models/dtos/ICouponResultDto'
import { Coupon } from '../../../../models/ICoupon'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../../Store/Display'
import { couponsActionCreators } from '../../../../Store/Questionnaire/Coupon'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import { generateGuid } from '../../../../utils/guid'
import { printCoupons } from '../../../../utils/printer'
import Button from '../../../Shared/Button'
import DownArrowSvg from './DownArrowSvg'

const mapStateToProps = (state: IApplicationState) => {
  return {
    questionnaireState: state.questionnaire,
    currentLanguage: state.language.currentLanguage,
    clientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
    couponsToPrint: state.questionnaire.coupons.couponToPrint,
    isOnTheGo: state.onTheGo.isOnTheGo,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    couponActions: bindActionCreators(couponsActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    isPopup: boolean,
    close: () => void,
  }

const RetrieveCouponsPrint = (props: IAllProps) => {

  const [printError, setPrintError] = useState(false)

  if (!props.clientResponseGuid) return <></>

  const mapPrintedCoupon = (coupon: Coupon, success: boolean) => {
    const couponGuid = generateGuid()
    const couponWithResult = {
      couponId: coupon.id,
      barCodeValue: coupon.barCodeValue,
      trackingNumber: couponGuid,
      experienceStreamGuid: props.questionnaireState.questionnaireInfo.experienceStreamGuid,
      couponPrintedCulture: props.currentLanguage,
      printed: true,
      failedToPrint: !coupon.success && !success,
    } as ICouponResultDto

    if (!couponWithResult.failedToPrint) {
      ShellConnecter
        .logger
        .logMessage(`Successfully printed coupon [id=${couponWithResult.couponId}] [tracking=${couponGuid}]`)
    } else {
      ShellConnecter
        .logger
        .logError(`Failed to print coupon [id=${couponWithResult.couponId}] [tracking=${couponGuid}]`)
    }

    return couponWithResult
  }

  const print = () => {
    ShellConnecter.isPrinterAvailable()
      .then(() => {
        const logo = props.questionnaireState.lastPage.orgLogo
        printCoupons(props.couponsToPrint, props.currentLanguage, logo)
          .then(couponsResults => {
            const results = couponsResults.map(coupon => mapPrintedCoupon(coupon, coupon.success))
            props.couponActions.changeCouponsToRetrieve(results)
            if (couponsResults.some(x => !x.success)) setPrintError(true)
          })
      })
      .catch(() => {
        const results = props.couponsToPrint.map(coupon => mapPrintedCoupon(coupon, false))
        props.couponActions.changeCouponsToRetrieve(results)
        setPrintError(true)
      })
  }

  useEffect(() => print(), [])

  const next = () => {
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)
    props.close()
  }

  return (
    <div style={{ width: '100%', height: '100%', fontSize: '5vh' }}>
      {
        printError &&
        <p style={{ margin: 0 }}>
          <FormattedMessage id='error-with-printer' />
        </p>
      }
      {
        !printError &&
        (
          <>
            <p style={{ marginTop: 0, marginBottom: '3vh' }}>
              <FormattedMessage id='coupon-printing' />
            </p>
            <DownArrowSvg />
          </>
        )
      }
      {
        props.isPopup &&
        <div style={{ marginTop: '3vh' }}>
          {/* TODO : Add it back when we find a fix so that we can't print twice */
          /* <Button
            style={{
              fontSize: '3vh',
              fontWeight: 'bold',
              margin: '10px',
            }}
            onClick={props.close}
          >
            <FormattedMessage id='back' />
          </Button> */}
          {
            !props.isOnTheGo &&
            <Button
              style={{
                fontSize: '3vh',
                fontWeight: 'bold',
                margin: '10px',
              }}
              onClick={next}
            >
              <FormattedMessage id='next' />
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetrieveCouponsPrint)
