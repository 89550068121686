import React, { UIEvent, useEffect, useState } from 'react'

import { getOrganizations } from '../../fetchers/organization'
import ILocation from '../../models/ILocation'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../css/App.css'
import { IApplicationState } from '../../Store'
import { actionCreators as ContextSelectionActionCreators } from '../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators } from '../../Store/Display'
import { bindActionCreators } from '../../utils/bindActionCreators'

const matFormFieldUnderline: React.CSSProperties = {
  height: '1px',
  width: '100%',
  pointerEvents: 'none',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    contextSelectionState: state.contextSelection,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    contextSelectionActions: bindActionCreators(ContextSelectionActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const OrganizationList = (props: IAllProps) => {
  const [organizations, setOrganizations] = useState([] as ILocation[])
  const [fetching, setFetching] = useState(false)
  const [startIndex, setStartIndex] = useState(0)

  const fetchOrganizations = () => {
    const tempAuth = props.contextSelectionState.tempAuth
    const serverAddress = props.contextSelectionState.serverAddress
    if (!tempAuth) return

    setFetching(true)
    getOrganizations({ startIndex, size: 10 }, serverAddress, tempAuth)
      .then(orgs => {
        if (organizations.length === 0 && orgs.length === 1) {
          selectOrganization(orgs[0].id, orgs[0].name)
        } else {
          setOrganizations([...organizations, ...orgs])
          setFetching(orgs.length < 10 ? true : false)
          setStartIndex(startIndex + orgs.length)
        }
      })
  }

  const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
    const divScroll = event.currentTarget
    const ratio = ((divScroll.offsetHeight + divScroll.scrollTop) / divScroll.scrollHeight) * 100
    if (!fetching && ratio >= 80) {
      fetchOrganizations()
    }
  }

  useEffect(() => fetchOrganizations(), [])

  const mapContent = (item: { id: number, name: string }) =>
    <div key={item.id}>
      <div
        onClick={() => selectOrganization(item.id, item.name)}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <p style={{ marginRight: '10px' }}>➤</p>
        <p>{item.name}</p>
      </div>
      <div style={{ ...matFormFieldUnderline, backgroundColor: 'var(--GeneralFontColor)' }} />
    </div>

  const selectOrganization = (organizationId: number, orgName: string) => {
    props.contextSelectionActions.changeOrganization(organizationId, orgName)
      .then(() => props.displayActions.changeModalPage(2))
  }

  return (
    <>
      <div style={{ height: '20%' }}>
        <h2 style={{ margin: 0 }}><FormattedMessage id='organization' /></h2>
        <p style={{ marginBottom: '1.25em', marginTop: 0 }}><FormattedMessage id='organization-desc' /></p>
      </div>
      <div
        style={{
          height: '80%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        onScroll={event => onScrollHandler(event)}
      >
        {organizations.map(mapContent)}
      </div>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationList)
