import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { IApplicationState } from '../../../../../Store'
import { getCssColorForValue, hexToCss, RGBColor } from '../../../../../utils/color'

interface IProps {
  colors: [RGBColor, RGBColor, RGBColor],
  min: number,
  max: number,
  value: number,
  onChange: (value: number) => void,
  onAfterChange: () => void
}

interface IState {
  minColorCss: string,
  middleColorCss: string,
  valueColorCss: string,
  middleColorPercentage: number,
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> & IProps

class VerticalSlider extends Component<IAllProps, IState> {
  constructor(props: IAllProps) {
    super(props)

    this.state = {
      minColorCss: hexToCss(props.colors[0]),
      middleColorCss: hexToCss(props.colors[1]),
      valueColorCss: this.getCurrentSelectedColorCss(props.value),
      middleColorPercentage: (
        this.props.value >= this.props.max
          ? this.props.max / 2
          : this.props.max / 2 * this.props.max / this.props.value
      ),
    }
  }

  public render = () => {
    const centerValue = (this.props.max - this.props.min) / 2

    const gradientStartValue = `${this.state.minColorCss} 0%,`

    const gradientMiddleValue = this.props.value > centerValue
      ? `${this.state.middleColorCss} ${this.state.middleColorPercentage}%`
      : `${this.state.valueColorCss} 100%`

    const gradientEndValue = this.props.value > centerValue
      ? `,${this.state.valueColorCss} 100%`
      : ''

    const railStyle = this.props.media.isAtLeastTablet ?
      {
        marginLeft: -5,
      } :
      {
        marginLeft: 0,
      }

    const trackStyle = this.props.media.isAtLeastTablet ?
      {
        width: 32,
        marginLeft: -16,
      } :
      {
        width: 10,
        marginLeft: 0,
      }

    const handleStyle = this.props.media.isAtLeastTablet ?
      {
        height: 40,
        width: 40,
        marginLeft: -20,
      } :
      {
        height: 20,
        width: 20,
      }

    return (
      <Slider

        vertical
        value={this.props.value}
        min={this.props.min}
        max={this.props.max}
        onChange={this.doOnChange}
        onAfterChange={this.props.onAfterChange}

        railStyle={{
          ...railStyle,
          width: 10,
          backgroundColor: 'white',
          borderRadius: 5,
        }}

        trackStyle={{
          ...trackStyle,
          background: `linear-gradient(
            to top,
            ${gradientStartValue}
            ${gradientMiddleValue}
            ${gradientEndValue}
          )`,
          borderRadius: 0,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
        }}

        handleStyle={{
          ...handleStyle,
          marginBottom: -15,
          backgroundColor: this.state.valueColorCss,
          border: 'none',
          borderRadius: '50%',
        }}
      />
    )
  }

  private doOnChange = (value: number) => {
    // Adjust the color here.
    const valueColorCss = this.getCurrentSelectedColorCss(value)

    this.setState(previousState => ({
      ...previousState,
      valueColorCss,
      middleColorPercentage: this.props.max / 2 * this.props.max / value,
    }))

    this.props.onChange(value)
  }

  private getCurrentSelectedColorCss = (current: number) =>
    getCssColorForValue(
      this.props.min,
      this.props.max,
      current,
      this.props.colors,
    )
}

export default connect(mapStateToProps, null)(VerticalSlider)
