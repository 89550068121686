import { Action, Reducer } from 'redux'
import { IEmployeeRatings } from '../../models/IEmployee'
import { IEmployeePicture } from '../../models/IPicture'

export interface IClock {
  digits: number[]
  indexes: number[]
}

export interface IPunchState {
  employeeList: IEmployeePicture[]
  punchedEmployeeList: IEmployeePicture[]
  clock: IClock
  accessCode: string
  selectedEmployee?: IEmployeePicture
  punchedEmployeeRatings?: IEmployeeRatings
}

interface IChangeEmployeeList {
  type: 'CHANGE_EMPLOYEE_LIST'
  employees: IEmployeePicture[]
}
interface IChangePunchedEmployeeList {
  type: 'CHANGE_PUNCHED_EMPLOYEE_LIST'
  punchedEmployees: IEmployeePicture[]
}
interface IChangeClock {
  type: 'CHANGE_CLOCK'
  clock: IClock
}
interface IChangeAccessCode {
  type: 'CHANGE_ACCESS_CODE'
  code: string
}
interface IChangeSelectedEmployee {
  type: 'CHANGE_SELECTED_EMPLOYEE'
  employee: IEmployeePicture
}
interface IResetSelectedEmployee {
  type: 'RESET_SELECTED_EMPLOYEE'
}
interface IChangeEmployeeRatings {
  type: 'CHANGE_EMPLOYEE_RATINGS'
  ratings: IEmployeeRatings
}
interface IPunchEmployee {
  type: 'PUNCH_EMPLOYEE'
  employeeId: number
}

export type KnownAction = IChangeEmployeeList | IChangePunchedEmployeeList
  | IChangeClock | IChangeAccessCode | IChangeSelectedEmployee
  | IResetSelectedEmployee | IChangeEmployeeRatings | IPunchEmployee

export const actionCreators = {
  changeEmployeeList: (employees: IEmployeePicture[]) =>
    ({ type: 'CHANGE_EMPLOYEE_LIST', employees }),
  changeClock: (clock: IClock) =>
    ({ type: 'CHANGE_CLOCK', clock }),
  changePunchedEmployeeList: (punchedEmployees: IEmployeePicture[]) =>
    ({ type: 'CHANGE_PUNCHED_EMPLOYEE_LIST', punchedEmployees }),
  changeAccessCode: (code: string) =>
    ({ type: 'CHANGE_ACCESS_CODE', code }),
  changeSelectedEmployee: (employee: IEmployeePicture) =>
    ({ type: 'CHANGE_SELECTED_EMPLOYEE', employee }),
  resetSelectedEmployee: () =>
    ({ type: 'RESET_SELECTED_EMPLOYEE' }),
  changeEmployeeRatings: (ratings: IEmployeeRatings) =>
    ({ ype: 'CHANGE_EMPLOYEE_RATINGS', ratings }),
  punchEmployee: (employeeId: number) =>
    ({ type: 'PUNCH_EMPLOYEE', employeeId }),
}

export const defaultState: IPunchState = {
  employeeList: [],
  punchedEmployeeList: [],
  clock: {
    digits: [],
    indexes: [],
  },
  accessCode: '',
}

export const reducer: Reducer<IPunchState> = (state: IPunchState | undefined, incomingAction: Action): IPunchState => {
  if (state === undefined) return defaultState

  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'CHANGE_EMPLOYEE_LIST':
      return {
        ...state,
        employeeList: action.employees,
      }
    case 'CHANGE_PUNCHED_EMPLOYEE_LIST':
      return {
        ...state,
        punchedEmployeeList: action.punchedEmployees,
      }
    case 'CHANGE_CLOCK':
      return {
        ...state,
        clock: action.clock,
      }
    case 'CHANGE_ACCESS_CODE':
      return {
        ...state,
        accessCode: action.code,
      }
    case 'CHANGE_SELECTED_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: action.employee,
      }
    case 'RESET_SELECTED_EMPLOYEE':
      return {
        ...state,
        selectedEmployee: undefined,
      }
    case 'PUNCH_EMPLOYEE':
      const punchedEmployees = state.punchedEmployeeList

      const employee = state.employeeList.find(x => x.employeeId === action.employeeId)

      return {
        ...state,
        punchedEmployeeList: punchedEmployees.some(x => x.employeeId === action.employeeId)
          ? punchedEmployees.filter(x => x.employeeId !== action.employeeId)
          : employee
            ? [...punchedEmployees, { ...employee, id: employee.employeeId }]
            : [...punchedEmployees],
      }
    default:
      return state
  }
}
