import { Action, Reducer } from 'redux'

import IQuestionnaire from '../../models/IQuestionnaire'

export interface IQuestionnaireInfoState {
  id: number,
  name: string,
  description: string,
  experienceStreamGuid: string,
}

export interface IChangeQuestionnaireInfo {
  type: 'CHANGE_QUESTIONNAIRE_INFOS'
  infos: IQuestionnaireInfoState
}
export interface IChangeQuestionnaireId {
  type: 'CHANGE_QUESTIONNAIRE_ID'
  id: number
}
export interface IChangeQuestionnaireName {
  type: 'CHANGE_QUESTIONNAIRE_NAME'
  name: string
}
export interface IChangeQuestionnaireDescription {
  type: 'CHANGE_QUESTIONNAIRE_DESCRIPTION'
  description: string
}
export interface IChangeQuestionnaireGuid {
  type: 'CHANGE_QUESTIONNAIRE_GUID'
  guid: string
}
export interface IChangeQuestionnaireLogos {
  type: 'CHANGE_QUESTIONNAIRE_LOGOS'
  logos: {
    default: string,
    custom: string,
  }
}
interface IResetQuestionnaireInfos {
  type: 'RESET_QUESTIONNAIRE_INFOS'
}

export type KnownAction = IChangeQuestionnaireId | IChangeQuestionnaireName
  | IChangeQuestionnaireDescription | IChangeQuestionnaireGuid
  | IChangeQuestionnaireLogos | IChangeQuestionnaireInfo | IResetQuestionnaireInfos

export const questionnaireInfoActionCreators = {
  changeQuestionnaireInfos: (questionnaire: IQuestionnaire) => ({
    type: 'CHANGE_QUESTIONNAIRE_INFOS',
    infos: {
      id: questionnaire.id,
      description: questionnaire.description,
      experienceStreamGuid: questionnaire.experienceStreamGuid,
      name: questionnaire.name,
    },
  }),
  resetQuestionnaireInfos: () =>
    ({ type: 'RESET_QUESTIONNAIRE_INFOS' }),
}

export const defaultState: IQuestionnaireInfoState = {
  id: -1,
  name: '',
  description: '',
  experienceStreamGuid: '',
}

export const reducer: Reducer<IQuestionnaireInfoState> =
  (state: IQuestionnaireInfoState | undefined, incomingAction: Action): IQuestionnaireInfoState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_QUESTIONNAIRE_INFOS':
        return {
          ...state,
          ...action.infos,
        }
      case 'RESET_QUESTIONNAIRE_INFOS':
        return defaultState
      default:
        return state
    }
  }
