import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IEmployeePicture } from '../../../../models/IPicture'
import { IApplicationState } from '../../../../Store'
import EmployeePicture from './EmployeePicture'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    employees: state.questionnaire.punch.employeeList,
    display: state.display.punchProgressionPage,
    language: state.language,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const EmployeePictureSelection = (props: IAllProps) => {

  const doMapEmploye = (employee: IEmployeePicture) =>
    <EmployeePicture
      key={employee.employeeId}
      employee={employee}
    />

  return (
    <div className='picture-selection-container container-scrollable'
      style={{
        position: 'relative',
        marginTop: '10px',
        maxHeight: '60vh',
        maxWidth: 'calc(100% - 200px)',
      }}>
      <div>
        {
          props.employees.map(doMapEmploye)
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeePictureSelection)
