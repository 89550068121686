import { Action, Dispatch, Reducer } from 'redux'

import ShellConnecter from '../../dependencies/shell-connecter'
import { mapToResultToSave, sendResponse } from '../../fetchers/responses'
import ICouponResultDto from '../../models/dtos/ICouponResultDto'
import { generateGuid } from '../../utils/guid'
import { QuestionnaireContentPage } from '../Display'
import { IApplicationState } from '../index'
import { Question, QuestionResult, typeOfAnswerQuestion, typeOfPictureQuestion } from './../../models/IQuestion'
import { lastPageActionCreators as LastPageActionCreators } from './LastPage'

export interface ICurrentAnswerState {
  answers: Map<Question, QuestionResult[]>
  responseTime: number
  responseTimerSpeed: number | null
  ClientResponeGuid: string | undefined
  phoneNumber: string | undefined
  selectedDepartment: number | undefined
}

export interface IChangeAnswer {
  type: 'CHANGE_ANSWER'
  question: Question
  answer: QuestionResult
}
export interface IResetAnswers {
  type: 'RESET_ANSWERS'
}
export interface IIncreaseResponseTime {
  type: 'INCREASE_RESPONSE_TIME'
}
export interface IResetResponseTime {
  type: 'RESET_RESPONSE_TIME'
}
export interface IChangeResponseTimerSpeed {
  type: 'CHANGE_RESPONSE_TIMER_SPEED'
  speed: number | null
}

export interface IChangeClientResponseGuid {
  type: 'CHANGE_CLIENT_RESPONSE_GUID'
  guid: string | undefined
}

export interface IChangePhoneNumber {
  type: 'CHANGE_PHONE_NUMBER'
  phoneNumber: string | undefined
}

export interface IChangeSelectedDepartment {
  type: 'CHANGE_SELECTED_DEPARTMENT'
  departmentId: number | undefined
}

export type KnownAction =
  | IChangeAnswer
  | IResetAnswers
  | IIncreaseResponseTime
  | IResetResponseTime
  | IChangeResponseTimerSpeed
  | IChangeClientResponseGuid
  | IChangePhoneNumber
  | IChangeSelectedDepartment

export const currentAnswerActionCreators = {
  changeAnswer: (question: Question, answer: QuestionResult) =>
    ({ type: 'CHANGE_ANSWER', question, answer }),
  resetAnswers: () =>
    ({ type: 'RESET_ANSWERS' }),
  increaseResponseTime: () =>
    ({ type: 'INCREASE_RESPONSE_TIME' }),
  resetResponseTime: () =>
    ({ type: 'RESET_RESPONSE_TIME' }),
  changeResponseTimerSpeed: (speed: number | null) =>
    ({ type: 'CHANGE_RESPONSE_TIMER_SPEED', speed }),
  changeClientResponseGuid: (guid: string | undefined) =>
    ({ type: 'CHANGE_CLIENT_RESPONSE_GUID', guid }),
  changePhoneNumer: (phoneNumber: string | undefined) =>
    ({ type: 'CHANGE_PHONE_NUMBER', phoneNumber }),
  changeSelectedDepartment: (departmentId: number | undefined) =>
    ({ type: 'CHANGE_SELECTED_DEPARTMENT', departmentId }),
  reset: () =>
    (dispatch: Dispatch) => {
      dispatch(currentAnswerActionCreators.resetAnswers())

      dispatch(currentAnswerActionCreators.resetResponseTime())
      dispatch(currentAnswerActionCreators.changeResponseTimerSpeed(null))

      dispatch(currentAnswerActionCreators.changePhoneNumer(undefined))
      dispatch(currentAnswerActionCreators.changeClientResponseGuid(generateGuid()))

      return Promise.resolve()
    },
  sendAnswers: (couponResult: ICouponResultDto[]) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const state = getState()
      const currentAnswers = state.questionnaire.currentAnswer
      const questionnaireId = state.questionnaire.questionnaireInfo.id
      const currentLanguage = state.language.currentLanguage
      const tellUsMoreSurveyKey = state.questionnaire.lastPage.tellUsMoreSurveyKey
      const phoneNumber = state.questionnaire.currentAnswer.phoneNumber
      const clientResponseGuid = state.questionnaire.currentAnswer.ClientResponeGuid
      const onTheGoKey = state.onTheGo.key
      const tempVisitKey = state.onTheGo.visitKey
      const hasAnswer = currentAnswers.answers.size > 0
      const selectedDepartment = state.questionnaire.currentAnswer.selectedDepartment

      const questionnaireContentPage = state.display.questionnaireContent

      const isHigherThanQuestionnairePage =
        questionnaireContentPage > QuestionnaireContentPage.Questionnaire

      if (isHigherThanQuestionnairePage && hasAnswer) {
        dispatch(LastPageActionCreators.increaseClientCount())
      }

      currentAnswerActionCreators.reset()(dispatch)

      return hasAnswer && clientResponseGuid
        ? ShellConnecter
          .device
          .getDeviceId()
          .then((deviceId: string) =>
            sendResponse(
              mapToResultToSave(
                deviceId,
                questionnaireId,
                currentAnswers.answers,
                couponResult,
                currentLanguage,
                currentAnswers.responseTime !== 0
                  ? currentAnswers.responseTime
                  : 1,
                tellUsMoreSurveyKey,
                phoneNumber
                  ? `+1${phoneNumber}`
                  : undefined,
                clientResponseGuid,
                selectedDepartment,
                tempVisitKey,
              ),
              {
                key: questionnaireId,
                onTheGoKey,
              },
            ),
          )
        : Promise.resolve()
    },
}

const defaultState: ICurrentAnswerState = {
  answers: new Map(),
  responseTime: 0,
  responseTimerSpeed: null,
  ClientResponeGuid: undefined,
  phoneNumber: undefined,
  selectedDepartment: undefined,
}

export const reducer: Reducer<ICurrentAnswerState> =
  (state: ICurrentAnswerState | undefined, incomingAction: Action): ICurrentAnswerState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_ANSWER':
        const newResults = new Map(state.answers)

        if (typeOfAnswerQuestion(action.question)) {
          newResults.set(action.question, [action.answer])
        } else if (typeOfPictureQuestion(action.question)) {
          const questionResults = newResults.get(action.question)
          if (!questionResults) {
            newResults.set(action.question, [action.answer])
          } else {
            let updatedResults
            if (questionResults.some(x => x.id === action.answer.id)) {
              updatedResults = questionResults.reduce((acc, current) => {
                let value = current

                if (action.answer.id === value.id) value = action.answer

                return [...acc, value]
              }, [] as QuestionResult[])
            } else {
              updatedResults = [...questionResults, action.answer]
            }

            newResults.set(action.question, [...updatedResults])
          }
        }

        return {
          ...state,
          answers: newResults,
        }
      case 'RESET_ANSWERS':
        return {
          ...state,
          answers: new Map(),
        }
      case 'INCREASE_RESPONSE_TIME':
        return {
          ...state,
          responseTime: state.responseTime + 1,
        }
      case 'RESET_RESPONSE_TIME':
        return {
          ...state,
          responseTime: 0,
        }
      case 'CHANGE_RESPONSE_TIMER_SPEED':
        return {
          ...state,
          responseTimerSpeed: action.speed,
        }
      case 'CHANGE_CLIENT_RESPONSE_GUID':
        return {
          ...state,
          ClientResponeGuid: action.guid,
        }
      case 'CHANGE_PHONE_NUMBER':
        return {
          ...state,
          phoneNumber: action.phoneNumber,
        }
      case 'CHANGE_SELECTED_DEPARTMENT':
        return {
          ...state,
          selectedDepartment: action.departmentId,
        }
      default:
        return state
    }
  }
