import { Action, Reducer } from 'redux'

export interface IOnTheGoState {
  isOnTheGo: boolean
  key: string | undefined
  visitKey: string | undefined
}

interface IChangeIsOnTheGo {
  type: 'CHANGE_IS_ON_THE_GO'
  isOnTheGo: boolean
}
interface IChangeKey {
  type: 'CHANGE_KEY'
  key: string | undefined
}
interface IChangeVisitKey {
  type: 'CHANGE_VISIT_KEY'
  visitKey: string | undefined
}

export type KnownAction = IChangeIsOnTheGo | IChangeKey | IChangeVisitKey

export const onTheGoActionCreators = {
  changeIsOnTheGoActive: (isOnTheGo: boolean) =>
    ({ type: 'CHANGE_IS_ON_THE_GO', isOnTheGo }),
  changeKey: (key: string | undefined) =>
    ({ type: 'CHANGE_KEY', key }),
  changeVisitKey: (visitKey: string | undefined) =>
    ({ type: 'CHANGE_VISIT_KEY', visitKey }),
}

const defaultState: IOnTheGoState = {
  isOnTheGo: false,
  key: undefined,
  visitKey: undefined,
}

export const reducer: Reducer<IOnTheGoState> =
  (state: IOnTheGoState = defaultState, incomingAction: Action): IOnTheGoState => {
    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_IS_ON_THE_GO':
        return {
          ...state,
          isOnTheGo: action.isOnTheGo,
        }
      case 'CHANGE_KEY':
        return {
          ...state,
          key: action.key,
        }
      case 'CHANGE_VISIT_KEY':
        return {
          ...state,
          visitKey: action.visitKey,
        }
      default:
        return state
    }
  }
