import QRCode from 'qrcode.react'
import React, { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import '../../../css/tellUsMore.css'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../Store/Display'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import { tellUsMoreUrl } from '../../../utils/url'

const mainDivStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  color: 'var(--GeneralFontColor)',
  fontSize: '7vh',
  fontFamily: 'JustAnotherHand',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
    surveyCode: state.questionnaire.lastPage.tellUsMoreSurveyKey,
    currentLanguage: state.language.currentLanguage,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { children: React.ReactNode }

const QrCodeTellUsMore = (props: IAllProps) => {

  return (
    <div style={mainDivStyle}>
      <p style={{ color: 'var(--ButtonPrimary)', margin: '1vh' }}>
        <FormattedMessage id='tell-us-more-qrcode' />
      </p>
      <div id='qr' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
        {
          props.surveyCode && (
            <QRCode
              size={1024}
              value={tellUsMoreUrl(props.serverAddress, props.surveyCode)}
            />
          )
        }
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QrCodeTellUsMore)
