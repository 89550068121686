import he from 'he'
import { IntlProvider } from 'react-intl'

import ShellConnecter from '../dependencies/shell-connecter'
import { Coupon } from '../models/ICoupon'
import { formatCode } from './random'
import { getMessages, Language } from './translate'
import { tellUsMoreUrl } from './url'

export const printCoupons = (
  couponsToPrint: Coupon[],
  language: Language,
  logo64: string,
) => {
  const printProcess = couponsToPrint
    .map(coupon =>
      printCoupon(coupon, language, logo64)
        .then(() => {
          return { ...coupon, success: true }
        })
        .catch(() => {
          return { ...coupon, success: false }
        }),
    )

  return Promise.all(printProcess)
}

const printCoupon = (couponInfo: Coupon, language: Language, logo64: string) => {
  const { intl } = new IntlProvider({ locale: language, messages: getMessages() }).getChildContext()

  // Fix (Oli): Since we currently can't print chinese character we change the language to english
  if (language === Language.ZH) language = Language.EN

  const messages = (intl.messages[language] as any)

  const resources = couponInfo.resourcesList.get(language)
  let body = resources
    ? he.decode(resources.body)
    : ''
  let footer = resources
    ? he.decode(resources.footer)
    : ''

  body = body.replace(/\r/g, '\n')
  footer = footer.replace(/\r/g, '\n')

  const coupon = [
    '',
    '*right*',
    getCurrentDate(language),
    '*!right*',
    '',
    '*center*',
    `*image*${logo64}*!image*`,
    '*!center*',
    '*center*',
    '-----------------------------------',
    '',
    body,
    '',
    '-----------------------------------',
    '',
    footer,
    '',
    '-----------------------------------',
    '']

  if (couponInfo.barCodeValue) {
    coupon.push(`*barcode*${couponInfo.barCodeValue}*!barcode*`, '')
  }

  if (couponInfo.couponExpires) {

    coupon.push(
      `${messages['valid-for']} ${couponInfo.daysValidAmount} ${messages.days}`,
      `${messages['expires-on']} ${getExpireDate(couponInfo.daysValidAmount, language)}`,
    )
  }

  coupon.push(
    '',
    '*!center*',
  )

  return ShellConnecter.print(coupon)
}

export const printTellUsMoreCoupon = (serverAddress: string, surveyKey: string, language: Language, logo64: string) => {
  const { intl } = new IntlProvider({ locale: language, messages: getMessages() }).getChildContext()
  const messages = (intl.messages[language] as any)

  const qrUrl = tellUsMoreUrl(serverAddress, surveyKey)

  const coupon = [
    '',
    '*right*',
    getCurrentDate(language),
    '*!right*',
    '*center*',
    '',
    `*image*${logo64}*!image*`,
    '*center*',
    '',
    '-----------------------------------',
    '',
    messages['tell-us-more'],
    '',
    messages['to-this-url'],
    '',
    `*bold*${serverAddress}tell-us-more/*!bold*`,
    '',
    messages['enter-code'],
    '',
    `*bold*${formatCode(surveyKey)}*!bold*`,
    '',
    messages.or,
    '',
    messages['qr-instruction-1'],
    '',
    `*qrcode*${qrUrl}*!qrcode*`,
    '',
    '*!center*', ,
  ]

  return ShellConnecter.print(coupon)
    .catch()
}

const getCurrentDate = (language: Language) => {
  const today = new Date()
  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  return today.toLocaleDateString(language, options)
}

const getExpireDate = (expireIn: number, language: Language) => {
  const expireDate = new Date()
  expireDate.setDate(expireDate.getDate() + expireIn)
  const options = { year: 'numeric', month: 'long', day: 'numeric' }

  return expireDate.toLocaleDateString(language, options)
}
