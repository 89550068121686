import { Action, Reducer } from 'redux'

export interface ISelectedPictureState {
  id: number,
  zoomed: boolean,
  zoomCompleted: boolean,
  isAnimating: boolean,
  displayImage: boolean,
  opacity: number,
  oldOpacity: number,
  displayShadowBg: boolean,
  mounted: boolean
}

interface IChangeZoomed {
  type: 'CHANGE_ZOOMED'
  zoomed: boolean
}
interface IChangeZoomCompleted {
  type: 'CHANGE_ZOOM_COMPLETED'
  completed: boolean
}
interface IChangeIsAnimating {
  type: 'CHANGE_IS_ANIMATING'
  isAnimating: boolean
}
interface IChangeDisplayImage {
  type: 'CHANGE_DISPLAY_IMAGE'
  displayImage: boolean
}
interface IChangeOpacity {
  type: 'CHANGE_OPACITY'
  opacity: number
}
interface IChangeOldOpacity {
  type: 'CHANGE_OLD_OPACITY'
  opacity: number
}
interface IChangeSelectedPicture {
  type: 'CHANGE_SELECTED_PICTURE'
  state: ISelectedPictureState
}

export type KnownAction = IChangeZoomed | IChangeZoomCompleted
  | IChangeIsAnimating | IChangeDisplayImage | IChangeOpacity
  | IChangeOldOpacity | IChangeSelectedPicture

export const actionCreators = {
  changeZoomed: (zoomed: boolean) =>
    ({ type: 'CHANGE_ZOOMED', zoomed }),
  changeZoomCompleted: (completed: boolean) =>
    ({ type: 'CHANGE_ZOOM_COMPLETED', completed }),
  changeIsAnimating: (isAnimating: boolean) =>
    ({ type: 'CHANGE_IS_ANIMATING', isAnimating }),
  changeDisplayImage: (displayImage: boolean) =>
    ({ type: 'CHANGE_DISPLAY_IMAGE', displayImage }),
  changeOpacity: (opacity: number) =>
    ({ type: 'CHANGE_OPACITY', opacity }),
  changeOldOpacity: (opacity: number) =>
    ({ type: 'CHANGE_OLD_OPACITY', opacity }),
  changeSelectedPicture: (state: ISelectedPictureState) =>
    ({ type: 'CHANGE_SELECTED_PICTURE', state }),
}

export const defaultState: ISelectedPictureState = {
  id: -1,
  zoomed: false,
  zoomCompleted: false,
  isAnimating: false,
  displayImage: true,
  opacity: 1,
  oldOpacity: 1,
  displayShadowBg: false,
  mounted: false,
}

export const reducer: Reducer<ISelectedPictureState> =
  (state: ISelectedPictureState | undefined, incomingAction: Action): ISelectedPictureState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_ZOOMED':
        return {
          ...state,
          zoomed: action.zoomed,
        }
      case 'CHANGE_ZOOM_COMPLETED':
        return {
          ...state,
          zoomCompleted: action.completed,
        }
      case 'CHANGE_IS_ANIMATING':
        return {
          ...state,
          isAnimating: action.isAnimating,
        }
      case 'CHANGE_DISPLAY_IMAGE':
        return {
          ...state,
          displayImage: action.displayImage,
        }
      case 'CHANGE_OPACITY':
        return {
          ...state,
          opacity: action.opacity,
        }
      case 'CHANGE_OLD_OPACITY':
        return {
          ...state,
          oldOpacity: action.opacity,
        }
      case 'CHANGE_SELECTED_PICTURE':
        return action.state
      default:
        return state
    }
  }
