export const getChangedProperties = <T>(object1: T, object2: T) => {
  const objProps = Object.getOwnPropertyNames(object1)

  const statePropsThatChanged = objProps
    .reduce(
      (acc, current) =>
        (object1 as any)[current] === (object2 as any)[current]
          ? acc
          : [...acc, current],
      [] as string[],
    )

  return statePropsThatChanged
}

export const mapsAreEquals = (m1: Map<unknown, unknown>, m2: Map<unknown, unknown>) => {
  let isEqual = true

  if (m1.size !== m2.size) isEqual = false
  if (isEqual) {
    m1.forEach((value, key) => {
      const currentM2Value = m2.get(key)
      if (!currentM2Value || value !== currentM2Value) {
        isEqual = false
        return
      }
    })
  }

  return isEqual
}

export const typeOfPromise = (object: any) =>
  object.toString() === '[object Promise]'
