import IQuestionnaire, { SelectedPictureBarType } from '../models/IQuestionnaire'
import { IAuth } from '../Store/Auth'
import { get } from './fetch'

interface IQuestionnaireServerResponse {
  id: number,
  experienceStreamId: number
  name: string,
  description: string,
  published: boolean,
  tellUsMoreValid: boolean
  experienceStreamGuid: string
  selectedPictureBar: SelectedPictureBarType
  selectedCultures: string[] | undefined
  enabledCultures: string[] | undefined
  selectedCouponRetrieveModes: string[] | undefined
}

interface IServerResponseQuestionnaires {
  items: IQuestionnaireServerResponse[]
}

export const mapQuestionnaireServerResponse = (
  questionnaire: IQuestionnaireServerResponse,
): IQuestionnaire =>
  ({
    id: questionnaire.id || questionnaire.experienceStreamId,
    name: questionnaire.name,
    description: questionnaire.description,
    published: questionnaire.published,
    tellUsMoreActivated: questionnaire.tellUsMoreValid,
    experienceStreamGuid: questionnaire.experienceStreamGuid,
    pictureType: questionnaire.selectedPictureBar || SelectedPictureBarType.None,
    enabledCultures: questionnaire.selectedCultures || [],
    enabledCouponRetrieveModes: questionnaire.selectedCouponRetrieveModes || [],
  })

export const getQuestionnaire = (
  id: number,
  url?: string,
  auth?: IAuth,
): Promise<IQuestionnaire> =>
  get({
    hostname: url,
    path: `experiencestreams/${id}`,
    auth,
  })
    .then(res => res.json())
    .then(mapQuestionnaireServerResponse)

export const getQuestionnaires =
  (
    options: { startIndex: number, size: number } = { startIndex: 0, size: 10 },
    url?: string,
    auth?: IAuth,
  ): Promise<IQuestionnaire[]> =>
    get({
      hostname: url,
      path: `experiencestreams/list-released?startIndex=${options.startIndex}&pageSize=${options.size}`,
      auth,
    })
      .then(res => res.json())
      .then((questionnaires: IServerResponseQuestionnaires) =>
        questionnaires.items.map(mapQuestionnaireServerResponse),
      )
