export enum DarkerToneAmount {
  '100%' = -100,
  '95%' = -95,
  '90%' = -90,
  '85%' = -85,
  '80%' = -80,
  '75%' = -75,
  '70%' = -70,
  '65%' = -65,
  '60%' = -60,
  '55%' = -55,
  '50%' = -50,
  '45%' = -45,
  '40%' = -40,
  '35%' = -35,
  '30%' = -30,
  '25%' = -25,
  '20%' = -20,
  '15%' = -15,
  '10%' = -10,
  '5%' = -5,
  '0%' = 0,
}

export enum LighterToneAmount {
  '100%' = 100,
  '95%' = 95,
  '90%' = 90,
  '85%' = 85,
  '80%' = 80,
  '75%' = 75,
  '70%' = 70,
  '65%' = 65,
  '60%' = 60,
  '55%' = 55,
  '50%' = 50,
  '45%' = 45,
  '40%' = 40,
  '35%' = 35,
  '30%' = 30,
  '25%' = 25,
  '20%' = 20,
  '15%' = 15,
  '10%' = 10,
  '5%' = 5,
  '0%' = 0,
}
