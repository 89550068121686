import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './components/App'
import './index.css'
import configureStore from './Store/configureStore'
import { saveState } from './utils/localStorage'

(window as any).jQuery = $
// tslint:disable-next-line: no-var-requires
require('signalr')

export const appStore = configureStore()

appStore.subscribe(() => saveState(appStore.getState()))

ReactDOM.render(
  <Provider store={appStore}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

/* Currently diseable, didn't have time to test it properly.
/* Also cause a bunch of error in the console when on. */
// serviceWorker.register()
