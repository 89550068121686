import { IAuth } from '../Store/Auth'
import { get } from './fetch'

export const getTellUsMoreActive = (id: number, url?: string, auth?: IAuth): Promise<boolean> =>
  get({
    hostname: url,
    path: `TellUsMore/${id}/Active`,
    auth,
  })
    .then(res => res.json())
    .then((active: boolean) => active)
