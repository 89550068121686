import React, { UIEvent, useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../css/App.css'
import { getBranches } from '../../fetchers/branches'
import ILocation from '../../models/ILocation'
import { IApplicationState } from '../../Store'
import { actionCreators as ContextSelectionActionCreators } from '../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators } from '../../Store/Display'
import { bindActionCreators } from '../../utils/bindActionCreators'

const matFormFieldUnderline: React.CSSProperties = {
  height: '1px',
  width: '100%',
  pointerEvents: 'none',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    contextSelectionState: state.contextSelection,
    displayState: state.display,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    contextSelectionActions: bindActionCreators(ContextSelectionActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const BranchList = (props: IAllProps) => {
  const [branches, setBranches] = useState([] as ILocation[])
  const [fetching, setFetching] = useState(false)
  const [startIndex, setStartIndex] = useState(0)

  const fetchBranches = () => {
    const tempAuth = props.contextSelectionState.tempAuth
    const serverAddress = props.contextSelectionState.serverAddress
    if (!tempAuth) return

    setFetching(true)
    getBranches({ startIndex, size: 10 }, serverAddress, tempAuth)
      .then(results => {
        if (branches.length === 0 && results.length === 1) {
          selectBranch(results[0].id, results[0].name)
        } else {
          setBranches([...branches, ...results])
          setFetching(results.length < 10 ? true : false)
          setStartIndex(startIndex + results.length)
        }
      })
  }

  const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
    const divScroll = event.currentTarget
    const ratio = ((divScroll.offsetHeight + divScroll.scrollTop) / divScroll.scrollHeight) * 100
    if (!fetching && ratio >= 80) {
      fetchBranches()
    }
  }

  useEffect(() => fetchBranches(), [])

  const mapContent = (item: { id: number, name: string }) =>
    <div key={item.id}>
      <div
        onClick={() => selectBranch(item.id, item.name)}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <p style={{ marginRight: '10px' }}>➤</p>
        <p>{item.name}</p>
      </div>
      <div style={{ ...matFormFieldUnderline, backgroundColor: 'var(--GeneralFontColor)' }} />
    </div>

  const selectBranch = (branchId: number, branchName: string) => {
    props.contextSelectionActions.changeBranch(branchId, branchName)
      .then(() => props.displayActions.changeModalPage(3))
  }

  return (
    <>
      <div style={{ height: '20%' }}>
        <h2 style={{ margin: 0 }}><FormattedMessage id='branch' /></h2>
        <p style={{ marginBottom: '1.25em', marginTop: 0 }}><FormattedMessage id='branch-desc' /></p>
      </div>
      <div
        style={{
          height: '80%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
        onScroll={event => onScrollHandler(event)}
      >
        {branches.map(mapContent)}
      </div>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BranchList)
