import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../../../../css/punch.css'
import { IEmployeePicture } from '../../../../../models/IPicture'
import { IApplicationState } from '../../../../../Store'
import { actionCreators as PunchActionCreators } from '../../../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../../../utils/bindActionCreators'
import Button from '../../../../Shared/Button'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    code: state.questionnaire.punch.accessCode,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    employee: IEmployeePicture,
  }

const AccessCodeDial = (props: IAllProps) => {

  const [buttons] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0])

  const doMapDialButton = (value: number, index: number) =>
    <Button
      key={index}
      onClick={() => props.punchActions.changeAccessCode(props.code + value)}
    >
      {value}
    </Button>

  return (
    <div className='dial-grid'>
      {
        buttons.map(doMapDialButton)
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccessCodeDial)
