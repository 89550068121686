import ShellConnecter from '../../dependencies/shell-connecter'

export const updateConnection = (proxy: any, experienceStreamGuid: string) =>
  Promise.all([
    new Promise<number>(resolve =>
      ShellConnecter.device.getCpuLoadPercent(resolve),
    ),
    new Promise<number>(resolve =>
      ShellConnecter.device.getDiskUsage(resolve),
    ),
    ShellConnecter.device.getDeviceId(),
  ]).then(([percent, diskUsage, deviceId]) => {
    const shellVersion = ShellConnecter.getVersion()
    const memUsage = ShellConnecter.device.getMemoryUsage()

    proxy.invoke('ConnectionCompleted',
      {
        computerFingerPrint: deviceId,
        questionnaireId: experienceStreamGuid,
        appVersion: process.env.REACT_APP_VERSION,
        shellVersion,
        memoryUsage: memUsage,
        cpuUsage: percent,
        diskUsage,
        isActive: true,
      },
    )
  })
