import React, { CSSProperties } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { IEmployeePicture } from '../../../../models/IPicture'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../../Store/Display'
import { actionCreators as PunchActionCreators } from '../../../../Store/Questionnaire/Punch'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import PictureCardImage from '../../Questionnaire/PictureQuestion/PictureCard/PictureCardImage'

const imageStyle: CSSProperties = {
  width: 150,
  height: 150,
  borderRadius: '50%',
  background: 'white',
}

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    punchActions: bindActionCreators(PunchActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    employee: IEmployeePicture,
  }

const EmployeePicture = (props: IAllProps) =>
  <div
    className='picture-card-container'
    onClick={() => {
      props.punchActions.changeSelectedEmployee(props.employee)
      props.displayActions.changePunchProgressionPage(1)
    }}
  >
    <div
      className='picture-card'
    >
      <div className='relative-container'>
        <PictureCardImage
          picture={props.employee}
          style={{
            ...imageStyle,
            margin: '5px',
          }}
          serverAddress={props.serverAddress}
        />
        <h1
          style={{
            margin: 0,
            lineHeight: 1,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 'inherit',
            textOverflow: 'ellipsis',
          }}
        >
          {
            props.employee.name
          }
        </h1>
      </div>
    </div>
  </div>

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeePicture)
