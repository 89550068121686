import he from 'he'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import '../../../../css/answer-choice.css'
import { IAnswerChoice } from '../../../../models/IQuestion'
import { IApplicationState } from '../../../../Store/index'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    languageState: state.language,
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> & {
  selectedAnswer: IAnswerChoice,
}

const AnswerChoiceAlternateMessage = (props: IAllProps) => (
  <FormattedMessage id='thanks-answer'>
    {
      msg => (
        <h1 className='alternate-message' style={{ display: props.media.isAtLeastTablet ? 'block' : 'none' }}>
          {msg} <span style={{ fontFamily: 'NerisSemiBold', fontSize: '5vh' }}>
            {he.decode(props.selectedAnswer.name.get(props.languageState.currentLanguage) || '')}
          </span>
        </h1>
      )
    }
  </FormattedMessage>
)

export default connect(
  mapStateToProps,
  null,
)(AnswerChoiceAlternateMessage)
