import { Action, Dispatch, Reducer } from 'redux'
import { IApplicationState } from '.'
import ShellConnecter from '../dependencies/shell-connecter'
import { login } from '../fetchers/auth'
import { updateSelectedBranch } from '../fetchers/branches'
import { updateSelectedOrganization } from '../fetchers/organization'
import { IAuth } from './Auth'

// tslint:disable-next-line: max-line-length
export const usernameRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

export interface IContextSelectionState {
  branchId: number
  organization: {
    id: number,
    name: string,
  }
  serverAddress: string
  tempAuth: IAuth | undefined
  user: {
    id: number,
    username: string,
  }
}

interface IChangeTempAuth {
  type: 'CHANGE_TEMP_AUTH'
  auth: IAuth
}
interface IChangeServerAddress {
  type: 'CHANGE_SERVER_ADDRESS'
  address: string
}
interface IChangeOrganizationId {
  type: 'CHANGE_ORGANIZATION_ID'
  id: number
}
interface IChangeOrganizationName {
  type: 'CHANGE_ORGANIZATION_NAME'
  name: string
}
interface IChangeBranchId {
  type: 'CHANGE_BRANCH_ID'
  id: number
}
interface IChangeUser {
  type: 'CHANGE_USER'
  user: {
    id: number,
    username: string,
  }
}

export type KnownAction = IChangeTempAuth | IChangeServerAddress
  | IChangeOrganizationId | IChangeOrganizationId | IChangeOrganizationName
  | IChangeBranchId | IChangeUser

export const actionCreators = {
  login: (server: string, username: string, password: string) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      if (usernameRegex.test(username)) {
        const serverAddress = server.endsWith('/') ? server : server + '/'

        return login({ serverAddress, username, password })
          .then(response => {
            dispatch(actionCreators.changeServerAddress(serverAddress))
            dispatch(actionCreators.changeUser({ id: response.id, username }))
            dispatch(actionCreators.changeTempAuth({
              token: response.token,
              expiration: response.expiration,
              refreshToken: response.refreshToken,
              refreshTokenExpiration: response.refreshTokenExpiration,
            }))
          })
      }

      return Promise.reject()
    },
  changeTempAuth: (auth?: IAuth) =>
    ({ type: 'CHANGE_TEMP_AUTH', auth }),
  changeServerAddress: (address: string) =>
    ({ type: 'CHANGE_SERVER_ADDRESS', address }),
  changeOrganizationId: (id: number) =>
    ({ type: 'CHANGE_ORGANIZATION_ID' }),
  changeOrganizationName: (name: string) =>
    ({ type: 'CHANGE_ORGANIZATION_NAME' }),
  changeOrganization: (id: number, name: string) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const appState = getState()
      const tempAuth = appState.contextSelection.tempAuth
      const serverAddress = appState.contextSelection.serverAddress
      if (tempAuth) {
        return updateSelectedOrganization(id, serverAddress, tempAuth)
          .then(response => {
            dispatch(actionCreators.changeOrganizationId(id))
            dispatch(actionCreators.changeOrganizationName(name))
            dispatch(actionCreators.changeTempAuth({
              token: response.token,
              expiration: response.expiration,
              refreshToken: response.refreshToken,
              refreshTokenExpiration: response.refreshTokenExpiration,
            }))
            ShellConnecter.logger.logMessage(`Connected on organization ${name} [id=${id}]`)
          })
      }

      return Promise.reject()
    },
  changeBranchId: (id: number) =>
    ({ type: 'CHANGE_BRANCH_ID' }),
  changeBranch: (id: number, name: string) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const appState = getState()
      const tempAuth = appState.contextSelection.tempAuth
      const serverAddress = appState.contextSelection.serverAddress
      if (tempAuth) {
        return updateSelectedBranch(id, serverAddress, tempAuth)
          .then(response => {
            dispatch(actionCreators.changeBranchId(id))
            dispatch(actionCreators.changeTempAuth({
              token: response.token,
              expiration: response.expiration,
              refreshToken: response.refreshToken,
              refreshTokenExpiration: response.refreshTokenExpiration,
            }))
            ShellConnecter.logger.logMessage(`Connected on branch ${name} [id=${id}]`)
          })
      }

      return Promise.reject()
    },
  changeUser: (user: { id: number, username: string }) =>
    ({ type: 'CHANGE_USER', user }),
}

const defaultState: IContextSelectionState = {
  tempAuth: undefined,
  serverAddress: '',
  user: {
    id: -1,
    username: '',
  },
  branchId: -1,
  organization: {
    id: -1,
    name: '',
  },
}

export const reducer: Reducer<IContextSelectionState> =
  (state: IContextSelectionState | undefined, incomingAction: Action): IContextSelectionState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_TEMP_AUTH':
        return {
          ...state,
          tempAuth: action.auth,
        }
      case 'CHANGE_SERVER_ADDRESS':
        return {
          ...state,
          serverAddress: action.address,
        }
      case 'CHANGE_ORGANIZATION_ID':
        return {
          ...state,
          organization: {
            ...state.organization,
            id: action.id,
          },
        }
      case 'CHANGE_ORGANIZATION_NAME':
        return {
          ...state,
          organization: {
            ...state.organization,
            name: action.name,
          },
        }
      case 'CHANGE_BRANCH_ID':
        return {
          ...state,
          branchId: action.id,
        }
      case 'CHANGE_USER':
        return {
          ...state,
          user: action.user,
        }
      default:
        return state
    }
  }
