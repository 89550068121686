import { Action, Dispatch, Reducer } from 'redux'
import { IApplicationState } from '..'
import { getCoupons } from '../../fetchers/coupon'
import { getCouponsFromKey } from '../../fetchers/onTheGo'
import ICouponResultDto from '../../models/dtos/ICouponResultDto'
import CouponRetrieveMode from '../../models/enums/CouponRetrieveMode'
import { Coupon } from '../../models/ICoupon'
import IOnTheGoKey from '../../models/IOnTheGoKey'

export interface ICouponState {
  coupons: Coupon[]
  couponRetrieveModes: string[]
  couponToPrint: Coupon[]
  couponsToRetrieve: ICouponResultDto[]
  hasPrinted: boolean
}

export interface IChangeCoupons {
  type: 'CHANGE_COUPONS'
  coupons: Coupon[]
}

export interface IChangeCouponRetrieveModes {
  type: 'CHANGE_COUPON_RETRIEVE_MODES'
  couponRetrieveModes: string[]
}

export interface IChangeCouponsToRetrieve {
  type: 'CHANGE_COUPONS_TO_RETRIEVE'
  coupons: ICouponResultDto[]
}

export interface IChangeCouponsToPrint {
  type: 'CHANGE_COUPONS_TO_PRINT'
  coupons: Coupon[]
}

export interface IChangeCouponsHasPrint {
  type: 'CHANGE_COUPONS_HAS_PRINT'
  hasPrinted: boolean
}

export type KnownAction =
  | IChangeCoupons
  | IChangeCouponRetrieveModes
  | IChangeCouponsToRetrieve
  | IChangeCouponsToPrint
  | IChangeCouponsHasPrint

export const couponsActionCreators = {
  changeCouponRetrieveModes: (couponRetrieveModes: string[]) =>
    ({ type: 'CHANGE_COUPON_RETRIEVE_MODES', couponRetrieveModes }),
  changeCouponsToRetrieve: (coupons: ICouponResultDto[]) =>
    ({ type: 'CHANGE_COUPONS_TO_RETRIEVE', coupons }),
  changeCoupons: (key: IOnTheGoKey<number, string>) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const appState = getState()
      const auth = appState.contextSelection.tempAuth || appState.auth.currentAuth
      const isOnTheGo = appState.onTheGo.isOnTheGo

      const couponsPromise = isOnTheGo && key.onTheGoKey
        ? getCouponsFromKey(key.onTheGoKey)
        : getCoupons(key.key, undefined, auth)

      return couponsPromise
        .then(coupons => {
          dispatch({ type: 'CHANGE_COUPONS', coupons })
        })
    },
  changeCouponsToPrint: (coupons: Coupon[]) =>
    ({ type: 'CHANGE_COUPONS_TO_PRINT', coupons }),
  changeCouponsHasPrint: (hasPrinted: boolean) =>
    ({ type: 'CHANGE_COUPONS_HAS_PRINT', hasPrinted }),
  isCouponRetrieveModeValid: (couponRetrieveMode: CouponRetrieveMode) =>
    (dispatch: Dispatch, getState: () => IApplicationState) => {
      const couponState = getState().questionnaire.coupons
      return couponState.couponRetrieveModes.some(x => x === couponRetrieveMode)
    },
  resetCoupons: () =>
    ({ type: 'CHANGE_COUPONS', coupons: [] }),
}

const defaultState: ICouponState = {
  coupons: [],
  couponRetrieveModes: [],
  couponsToRetrieve: [],
  couponToPrint: [],
  hasPrinted: false,
}

export const reducer: Reducer<ICouponState> =
  (state: ICouponState | undefined, incomingAction: Action): ICouponState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_COUPON_RETRIEVE_MODES':
        return {
          ...state,
          couponRetrieveModes: action.couponRetrieveModes,
        }
      case 'CHANGE_COUPONS':
        return {
          ...state,
          coupons: action.coupons,
        }
      case 'CHANGE_COUPONS_TO_RETRIEVE':
        return {
          ...state,
          couponsToRetrieve: action.coupons,
        }
      case 'CHANGE_COUPONS_TO_PRINT':
        return {
          ...state,
          couponToPrint: action.coupons,
        }
      case 'CHANGE_COUPONS_HAS_PRINT':
        return {
          ...state,
          couponsToRetrieve: state.couponsToRetrieve.map(coupon => ({ ...coupon, printed: action.hasPrinted })),
        }
      default:
        return state
    }
  }
