import useInterval from '@use-it/interval'
import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ShellConnecter from '../dependencies/shell-connecter'
import { getDefaultStyle } from '../fetchers/style'
import { IApplicationState } from '../Store'
import { actionCreators as ConnectionActionCreators } from '../Store/Connection/Connection'
import { actionCreators as ContextSelectionActionCreators } from '../Store/ContextSelection'
import { actionCreators as DisplayActionCreators } from '../Store/Display'
import { onTheGoActionCreators } from '../Store/OnTheGo'
import { currentAnswerActionCreators } from '../Store/Questionnaire/CurrentAnswer'
import { questionnaireActionCreators } from '../Store/Questionnaire/Questionnaire'
import { styleActionCreators } from '../Store/Questionnaire/Style'
import { actionCreators as UserClickActionCreators } from '../Store/UserClick'
import { bindActionCreators } from '../utils/bindActionCreators'
import { getConfig } from '../utils/config'
import { generateGuid } from '../utils/guid'
import { getMessages } from '../utils/translate'
import OnClickContainer from './Content/OnClickContainer'
import ViewController from './Content/ViewController'

const mapStateToProps = (state: IApplicationState) => {
  return {
    serverAddress: state.contextSelection.serverAddress,
    currentLanguage: state.language.currentLanguage,
    ClientResponseGuid: state.questionnaire.currentAnswer.ClientResponeGuid,
    isOnTheGo: state.onTheGo.isOnTheGo,
    onTheGoKey: state.onTheGo.key,
    connectionState: state.connection,
    style: state.questionnaire.style,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    connectionActions: bindActionCreators(ConnectionActionCreators, dispatch),
    contextSelectionAction: bindActionCreators(ContextSelectionActionCreators, dispatch),
    userClickActions: bindActionCreators(UserClickActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    styleActions: bindActionCreators(styleActionCreators, dispatch),
    onTheGoActions: bindActionCreators(onTheGoActionCreators, dispatch),
    questionnaireActions: bindActionCreators(questionnaireActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const App = (props: IAllProps) => {
  const [isConfigInitialized, setIsConfigInitialized] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isStyleLoaded, setIsStyleLoaded] = useState(false)

  useEffect(() => {
    if (!ShellConnecter.isConnectedToApi) {
      props.onTheGoActions.changeIsOnTheGoActive(true)
      props.onTheGoActions.changeKey(window.location.pathname.substring(1))
    }

    getConfig()
      .then(serverAddress => {
        props
          .contextSelectionAction
          .changeServerAddress(serverAddress)

        setIsConfigInitialized(true)
      })
  }, [])

  useEffect(() => {
    if (!isConfigInitialized || ShellConnecter.isConnectedToApi) {
      return
    }

    props
      .connectionActions
      .connectLiveConnection()
  }, [isConfigInitialized])

  const testConnectivity = () => {
    if (!!props.serverAddress && ShellConnecter.isConnectedToApi) {
      ShellConnecter
        .device
        .getConnectivity(props.serverAddress)
        .then((isOnline: boolean) => props.connectionActions.changeIsOnline(isOnline))
    }

    props
      .connectionActions
      .changeIsConnectedToShell(ShellConnecter.isConnectedToApi)

    ShellConnecter
      .isPrinterAvailable()
      .then(() => props.connectionActions.changeIsPrinterAvailable(true))
      .catch(() => props.connectionActions.changeIsPrinterAvailable(false))
  }

  useEffect(() => {
    if (!isConfigInitialized) return

    testConnectivity()
  }, [isConfigInitialized])

  useInterval(() => testConnectivity(), 60000)

  useEffect(() => {
    if (!isConfigInitialized) return

    if (props.style.style) {
      props
        .styleActions
        .changeStyle(props.style.style)
      setIsStyleLoaded(true)
    } else {
      getDefaultStyle(props.serverAddress)
        .then(styles => props.styleActions.changeStyle(styles))
        .then(() => setIsStyleLoaded(true))
    }
  }, [isConfigInitialized])

  useEffect(() => {
    if (!isConfigInitialized) return

    props
      .currentAnswerActions
      .changeClientResponseGuid(generateGuid())
  }, [isConfigInitialized])

  useEffect(() => {
    if (!isStyleLoaded) return

    if (!props.isOnTheGo) {
      setIsLoaded(true)
      return
    }

    props
      .questionnaireActions
      .changeQuestionnaire({
        key: -1,
        onTheGoKey: props.onTheGoKey,
      })
      .then(() => setIsLoaded(true))
  }, [isStyleLoaded])

  useEffect(() => {
    window.addEventListener('resize', (ev: UIEvent) => {
      props
        .displayActions
        .changeWindowWidth((ev.target as Window).innerWidth)
    })
  }, [isConfigInitialized])

  return (
    <IntlProvider
      locale={props.currentLanguage}
      messages={getMessages()[props.currentLanguage]}
    >
      <OnClickContainer>
        {
          isLoaded && <ViewController />
        }
      </OnClickContainer>
    </IntlProvider>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
