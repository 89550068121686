import React from 'react'
import { connect } from 'react-redux'
import { PunchProgressionPage } from '../../../Store/Display'
import { IApplicationState } from '../../../Store/index'
import EmployeeConnected from './EmployeeConnected'
import EmployeeConnection from './EmployeeConnection/EmployeeConnection'
import EmployeeDisconnected from './EmployeeDisconnected'
import EmployeSelection from './EmployeeSelection/EmployeeSelection'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    punchProgressionPage: state.display.punchProgressionPage,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const EmployeeContextSelection = (props: IAllProps) => {
  let content

  switch (props.punchProgressionPage) {
    case PunchProgressionPage.EmployeeSelection:
      content = <EmployeSelection />
      break
    case PunchProgressionPage.EmployeeConnection:
      content = <EmployeeConnection />
      break
    case PunchProgressionPage.EmployeeConnected:
      content = <EmployeeConnected />
      break
    case PunchProgressionPage.EmployeeDisconnected:
      content = <EmployeeDisconnected />
      break
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
      {content}
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(EmployeeContextSelection)
