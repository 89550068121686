import { Action, AnyAction, Reducer } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IApplicationState } from '..'
import ShellConnecter from '../../dependencies/shell-connecter'
import { setupConnectionEvents, setupProxyEvents } from './OnEvents'
import { updateConnection } from './OutEvents'

export interface IConnectionState {
  isConnectedToShell: boolean
  isOnline: boolean
  isPrinterAvailable: boolean
  isSocketActive: boolean
  proxy: any
  connection: any
}

interface IChangeIsConnectedToShell {
  type: 'CHANGE_IS_CONNECTED_TO_SHELL'
  isConnected: boolean
}
interface IChangeIsOnline {
  type: 'CHANGE_IS_ONLINE'
  isOnline: boolean
}
interface IChangeIsPrinterAvailable {
  type: 'CHANGE_IS_PRINTER_AVAILABLE'
  isAvailable: boolean
}
interface IChangeIsSocketActive {
  type: 'CHANGE_IS_SOCKET_ACTIVE'
  isActive: boolean
}
interface IChangeProxy {
  type: 'CHANGE_PROXY'
  proxy: any
}
interface IChangeConnection {
  type: 'CHANGE_CONNECTION'
  connection: any
}

type KnownAction = IChangeIsConnectedToShell | IChangeIsOnline
  | IChangeIsPrinterAvailable | IChangeIsSocketActive | IChangeProxy
  | IChangeConnection

export const actionCreators = {
  changeConnection: (connection: any) =>
    ({ type: 'CHANGE_CONNECTION', connection }),
  changeIsConnectedToShell: (isConnected: boolean) =>
    ({ type: 'CHANGE_IS_CONNECTED_TO_SHELL', isConnected }),
  changeIsOnline: (isOnline: boolean) =>
    ({ type: 'CHANGE_IS_ONLINE', isOnline }),
  changeIsPrinterAvailable: (isAvailable: boolean) =>
    ({ type: 'CHANGE_IS_PRINTER_AVAILABLE', isAvailable }),
  changeProxy: (proxy: any) =>
    ({ type: 'CHANGE_PROXY', proxy }),
  changeIsSocketActive: (isActive: boolean) =>
    ({ type: 'CHANGE_IS_SOCKET_ACTIVE', isActive }),
  connectLiveConnection: () =>
    (dispatch: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState): Promise<void> => {
      const state = getState()
      const serverAddress = state.contextSelection.serverAddress
      const currentAuth = state.auth.currentAuth
      const experienceStreamGuid = state.questionnaire.questionnaireInfo.experienceStreamGuid
      const currentConnection = state.connection.connection

      if (!currentAuth) return Promise.reject()
      if (currentConnection) {
        const connectionWithNewAuth = currentConnection
        connectionWithNewAuth.qs = `AuthorizationToken=${currentAuth.token}`
        dispatch(actionCreators.changeConnection(connectionWithNewAuth))
        return Promise.resolve()
      }

      const connection = (window as any).jQuery.hubConnection(`${serverAddress}websocket`, { useDefaultPath: false })
      connection.qs = `AuthorizationToken=${currentAuth.token}`
      const proxy = connection.createHubProxy('ExperienceStreamHub')

      dispatch(actionCreators.changeConnection(connection))
      dispatch(setupProxyEvents(proxy))
      dispatch(setupConnectionEvents(connection))

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          connection.start({ jsonp: true })
            .done(() =>
              updateConnection(proxy, experienceStreamGuid)
                .then(() => {
                  dispatch(actionCreators.changeProxy(proxy))
                  dispatch(actionCreators.changeIsSocketActive(true))
                })
                .then(() => resolve())
                .catch(() => reject()),
            )
            .fail(() => reject())
        }, 500)
      })
    },
}

const defaultState: IConnectionState = {
  isConnectedToShell: false,
  isOnline: false,
  isPrinterAvailable: false,
  isSocketActive: false,
  proxy: undefined,
  connection: undefined,
}

export const reducer: Reducer<IConnectionState> =
  (state: IConnectionState | undefined, incomingAction: Action): IConnectionState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_IS_CONNECTED_TO_SHELL':
        if (state.isConnectedToShell !== action.isConnected) {
          ShellConnecter.logger.logWarning('Connection is ' + (action.isConnected ? 'online' : 'offline'))
        }
        return {
          ...state,
          isConnectedToShell: action.isConnected,
        }
      case 'CHANGE_IS_PRINTER_AVAILABLE':
        return {
          ...state,
          isPrinterAvailable: action.isAvailable,
        }
      case 'CHANGE_IS_ONLINE':
        return {
          ...state,
          isOnline: action.isOnline,
        }
      case 'CHANGE_PROXY':
        return {
          ...state,
          proxy: action.proxy,
        }
      case 'CHANGE_IS_SOCKET_ACTIVE':
        return {
          ...state,
          isSocketActive: action.isActive,
        }
      case 'CHANGE_CONNECTION':
        return {
          ...state,
          connection: state.connection,
        }
      default:
        return state
    }
  }
