import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import ShellConnecter from '../../dependencies/shell-connecter'
import { IApplicationState } from '../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../Store/Display'
import { currentAnswerActionCreators } from '../../Store/Questionnaire/CurrentAnswer'
// tslint:disable-next-line: max-line-length
import { actionCreators as SelectedPictureActionCreators, defaultState as initialSelectedPictureState } from '../../Store/Questionnaire/SelectedPicture'
import { bindActionCreators } from '../../utils/bindActionCreators'
import Button from '../Shared/Button'

const mapStateToProps = (state: IApplicationState) => {
  return {
    displayState: state.display,
    answerCount: state.questionnaire.currentAnswer.answers.size,
    couponsToRetrieve: state.questionnaire.coupons.couponsToRetrieve,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    selectedPictureActions: bindActionCreators(SelectedPictureActionCreators, dispatch),
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const AreYouStillThere = (props: IAllProps) => {
  const [secondsBeforeClose, setSecondsBeforeClose] = useState(3)

  const closePopup = () => {
    props.displayActions.changeDisplayAreYouStillThereIsDisplayed(false)
  }

  useEffect(() => {
    if (props.answerCount === 0) {
      props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
      props.currentAnswerActions.reset()
    }

    if (props.displayState.modalIsDisplayed || props.answerCount === 0) {
      closePopup()
      return
    }

    const interval = setInterval(
      () => setSecondsBeforeClose(seconds => seconds - 1),
      1000,
    )

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (secondsBeforeClose !== 0) return

    closePopup()

    // Reset current questionnaire answer
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
    props.displayActions.changeDisplayRetrieveCoupons(false)
    props.currentAnswerActions.sendAnswers(props.couponsToRetrieve)
    props.selectedPictureActions.changeSelectedPicture(initialSelectedPictureState)

    ShellConnecter.logger.logMessage('No screen click detected after 12 seconds. Resetting.')
  }, [secondsBeforeClose])

  return (
    <div
      style={{
        backgroundColor: 'var(--BackgroundPrimary2Alpha50)',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => closePopup()}
    >
      <div
        style={{
          width: '25vw',
          height: '20vh',
          borderRadius: '10px',
          backgroundColor: 'var(--BackgroundPrimary)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          fontSize: '3vh',
          boxShadow: `0 0 10px 0 var(--BackgroundSecondary2)`,
        }}
      >
        <FormattedMessage id='are-you-still-there' />
        <p style={{ margin: 0 }}>{secondsBeforeClose}</p>
        <Button
          style={{
            fontSize: '1vw',
            padding: '10px 10px',
            width: '10vw',
            fontWeight: 'bold',
            height: '100%',
            maxHeight: '50px',
          }}
          onClick={() => closePopup()}
        >
          <FormattedMessage id='yes' />
        </Button>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AreYouStillThere)
