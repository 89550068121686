import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import '../../../../css/picture-rating.css'
import { isEmployeePicture, Picture } from '../../../../models/IPicture'
import { IPictureQuestion } from '../../../../models/IPictureQuestion'
import { IApplicationState } from '../../../../Store'
import QuestionRow from '../QuestionRow'
import PictureCardContainer from './PictureCard/PictureCardContainer'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => {
  return {
    displayState: state.display,
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & {
    question: IPictureQuestion,
    pictures: Picture[],
    readyForZoom: boolean,
    canAnswer: boolean,
    onClick: () => void,
  }

const PictureRating = (props: IAllProps) => {

  const [mounted, setMounted] = useState(false)
  const [scrollableContainer] = useState(React.createRef<HTMLDivElement>())
  const [selectedRef, setSelectedRef] = useState<HTMLDivElement | undefined>(undefined)
  const [pictures] = useState(props.pictures)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const container = scrollableContainer.current
    if (container && selectedRef) {
      container.scrollTo(0, selectedRef.offsetTop)
      setTimeout(() => setReady(true), 500)
    }

    if (props.readyForZoom) setMounted(true)
  }, [selectedRef, props.readyForZoom])

  return (
    <QuestionRow
      questionName={props.question.name}
    >
      <div
        className='picture-rating-container-scrollable'
        onClick={props.onClick}
        ref={scrollableContainer}
      >
        {
          pictures.map(picture =>
            <PictureCardContainer
              questionMounted={mounted}
              key={isEmployeePicture(picture) ? picture.employeeId : picture.id}
              selectedRef={(element: HTMLDivElement) => setSelectedRef(element)}
              question={props.question}
              picture={picture}
              canAnswer={props.canAnswer}
              scrollableContainer={scrollableContainer.current}
              readyForZoom={ready}
              setReadyForZoom={setReady}
            />,
          )
        }
      </div>
    </QuestionRow>
  )
}

export default connect(
  mapStateToProps,
  null,
)(PictureRating)
