import { typeOfPromise } from "../utils/object";

const ShellConnecter = (() => {
  let currentPromise = Promise.resolve();

  const fileChainExecution = (exec) => {
    let doResolve;

    const newPromise = new Promise(resolve => { doResolve = resolve; });

    const promise = currentPromise
      .then(() => exec())
      .finally(() => doResolve && doResolve());

    currentPromise = newPromise;

    return promise;
  };

  const Api = {
    camera: window.shellApiCamera,
    device: window.shellApiDevice,
    logger: window.shellApiLogger,
    printer: window.shellApiPrinter,
    storage: window.shellApiStorage,
    version: window.shellVersion,
  };

  const testApiConnection = () =>
    !!Api.camera ||
    !!Api.printer ||
    !!Api.device ||
    !!Api.storage ||
    !!Api.logger;

  const isConnectedToApi = testApiConnection();

  const print = (content) =>
    !!Api.printer
      ? Api.printer.print(content)
      : Promise.resolve();

  const isPrinterAvailable = () => {
    if (!!Api.printer) {

      const response = Api.printer.isAvailable();

      if (!typeOfPromise(response)) {
        return Promise.resolve(response);
      }

      return response;

    } else {
      return Promise.reject();
    }
  };

  const takePicture = () =>
    !!Api.camera
      ? Api.camera.takePicture()
      : Promise.resolve();

  const takeScreenshot = () =>
    !!Api.device
      ? Api.device.takeScreenshot()
      : Promise.resolve();

  const getDeviceId = () => {
    if (!!Api.device) {
      const deviceIdPromise = Api.device.getDeviceId();

      if (typeof deviceIdPromise !== "string") {
        return deviceIdPromise;
      } else {
        return Promise.resolve(deviceIdPromise);
      }
    } else {
      return Promise.resolve("a1274fuef34823403r8u381r81rha398348had92318ajde71");
    }
  };

  const write = (key, value) => {
    if (!!Api.storage) {
      return fileChainExecution(() => Api.storage.write(key, JSON.stringify(value)));
    } else {
      typeof value === "string"
        ? localStorage.setItem(key, value)
        : localStorage.setItem(key, JSON.stringify(value));
      return Promise.resolve();
    }
  };

  const read = (key) => {
    if (!!Api.storage) {
      return fileChainExecution(() => Api.storage.read(key));
    } else {
      let value = localStorage.getItem(key);

      if (value == null) { return Promise.resolve(value); }

      try {
        return Promise.resolve(JSON.parse(value));
      } catch {
        return Promise.resolve(value);
      }
    }
  };

  const remove = (key) => {
    if (!!Api.storage) {
      return fileChainExecution(() => Api.storage.remove(key));
    } else {
      localStorage.removeItem(key);
      return Promise.resolve();
    }
  };

  const getMemoryUsage = () =>
    !!Api.device
      ? Api.device.getMemoryUsage()
      : Promise.resolve(-1);

  const getCpuLoadPercent = (resolve) =>
    !!Api.device
      ? Api.device.getCpuLoadPercent(resolve)
      : Promise.resolve(resolve(-1));

  const getDiskUsage = (resolve) =>
    !!Api.device
      ? Api.device.getDiskUsage(resolve)
      : Promise.resolve(resolve(-1));

  const getVersion = () =>
    !!Api.version
      ? Api.version
      : -1;

  const logMessage = (message) => {
    if (!!Api.logger) {
      Api.logger.logMessage(message);
    }
  };

  const logWarning = (message) => {
    if (!!Api.logger) {
      Api.logger.logWarning(message);
    }
  };

  const logError = (message) => {
    if (!!Api.logger) {
      Api.logger.logError(message);
    }
  };

  const getConnectivity = (serverAddress) => {
    if (!!Api.device) {

      const response = Api.device.getConnectivity(serverAddress);

      if (!typeOfPromise(response)) {
        return Promise.resolve(response);
      }

      return response;

    } else {
      return Promise.reject();
    }
  };

  return {
    device: {
      getConnectivity,
      getCpuLoadPercent,
      getDeviceId,
      getDiskUsage,
      getMemoryUsage,
      takeScreenshot,
    },
    getVersion,
    isConnectedToApi,
    isPrinterAvailable,
    logger: {
      logError,
      logMessage,
      logWarning,
    },
    print,
    storage: {
      read,
      remove,
      write,
    },
    takePicture,
  };
})();

export default ShellConnecter;
