import he from 'he'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { IAnswerChoice, Question } from '../../../../models/IQuestion'
import { IApplicationState } from '../../../../Store/index'
import Button from '../../../Shared/Button'

interface IAnimationPoint {
  controlPointX1: number,
  controlPointY1: number,
  controlPointX2: number,
  controlPointY2: number,
  endPointX: number,
  endPointY: number
}

/**
 * Represent the four positions of the animation, top, left, bottom, right.
 * The points are places inside a grid represented by gridWidth * gridHeight
 */
const points = [
  {
    controlPointX1: 8,
    controlPointY1: 1,
    controlPointX2: 4,
    controlPointY2: 1,
    endPointX: 4,
    endPointY: 2,
  },
  {
    controlPointX1: 4,
    controlPointY1: 4,
    controlPointX2: 8,
    controlPointY2: 4.75,
    endPointX: 9,
    endPointY: 4.75,
  },
  {
    controlPointX1: 10,
    controlPointY1: 4.75,
    controlPointX2: 14,
    controlPointY2: 4,
    endPointX: 14,
    endPointY: 2,
  },
  {
    controlPointX1: 14,
    controlPointY1: 1,
    controlPointX2: 8,
    controlPointY2: 1,
    endPointX: 7,
    endPointY: 1.5,
  },
] as IAnimationPoint[]

const mapStateToProps = (state: IApplicationState) => {
  return {
    answers: state.questionnaire.currentAnswer.answers,
    languageState: state.language,
    displayState: state.display,
    media: state.display.media,
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & {
    answerChoice: IAnswerChoice,
    question: Question,
    onClick: (answer: IAnswerChoice) => void,
  }

const AnswerChoiceButton = (props: IAllProps) => {
  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement | null>(null)

  let animationPos = 0

  const relatedResult = props.answers.get(props.question)
  const selected = relatedResult && relatedResult[0] === props.answerChoice

  const gridWidth = 18
  const gridHeight = 10
  const lineWidth = 15

  useEffect(() => {
    if (selected) {
      requestAnimationFrame(drawAnimationTimeout)
    } else {
      clearCanvas()
    }
  }, [selected])

  /**
   * The animation is made using the bezierCurveTo function for a canvas2D.
   * The canvas is divided into a grid to have access to specific points for the
   * animation.
   *
   * Grid dimensions = gridWidth * gridHeight
   */
  const drawAnimation = () => {
    if (!canvasRef) return

    const canvas2d = canvasRef.getContext('2d')

    if (!canvas2d) return

    if (animationPos < points.length - 1) {
      requestAnimationFrame(drawAnimationTimeout)
    }

    const point = points[animationPos]
    canvas2d.strokeStyle = '#FF0000'
    canvas2d.lineWidth = lineWidth

    canvas2d.beginPath()

    if (animationPos === 0) {
      // Starting position for the animation
      canvas2d.moveTo((canvasRef.width / gridWidth) * 9, (canvasRef.height / gridHeight) * 0.5)
    } else {
      canvas2d.moveTo(
        (canvasRef.width / gridWidth) * points[animationPos - 1].endPointX,
        (canvasRef.height / gridHeight) * points[animationPos - 1].endPointY,
      )
    }

    canvas2d.bezierCurveTo(
      (canvasRef.width / gridWidth) * point.controlPointX1,
      (canvasRef.height / gridHeight) * point.controlPointY1,
      (canvasRef.width / gridWidth) * point.controlPointX2,
      (canvasRef.height / gridHeight) * point.controlPointY2,
      (canvasRef.width / gridWidth) * point.endPointX,
      (canvasRef.height / gridHeight) * point.endPointY,
    )

    canvas2d.stroke()

    animationPos += 1
  }

  const drawAnimationTimeout = () => setTimeout(() => drawAnimation(), 50)

  const clearCanvas = () => {
    if (!canvasRef) return

    const canvas2d = canvasRef.getContext('2d')

    if (!canvas2d) return

    canvas2d.clearRect(0, 0, canvasRef.width, canvasRef.height)
  }

  return (
    <div
      style={props.media.isAtLeastTablet
        ? {
          margin: '0px 1vw',
          width: '100%',
          maxWidth: '250px',
          padding: '10px',
          position: 'relative',
          flex: 1,
        }
        : {
          width: '90%',
          margin: '10px 10px',
        }}
    >
      {props.media.isAtLeastTablet &&
        <canvas
          hidden={!selected}
          ref={setCanvasRef}
          className='answer-choice-button-circle'
        />
      }
      <Button
        selected={selected}
        onClick={() => props.onClick(props.answerChoice)}
        className='answer-choice-button'
      >
        {he.decode(props.answerChoice.name.get(props.languageState.currentLanguage) || '')}
      </Button>
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(AnswerChoiceButton)
