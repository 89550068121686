import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../Store/Display'
import { IApplicationState } from '../../Store/index'
import { couponsActionCreators } from '../../Store/Questionnaire/Coupon'
import { bindActionCreators } from '../../utils/bindActionCreators'
import CouponRetrieve from './CouponRetrieve/CouponRetrieve'
import LastPage from './LastPage'
import OnTheGoLastPage from './OnTheGoLastPage'
import EmployeeDepartmentQuestionPage from './Questionnaire/EmployeeeDepartmentQuestion/EmployeeDepartmentQuestionPage'
import QuestionList from './Questionnaire/QuestionList'
import TellUsMore from './TellUsmore/TellUsMore'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => ({
  questionnaireContent: state.display.questionnaireContent,
  departmentQuestion: state.questionnaire.questions.departmentQuestion,
})

const mapDispatchToProps = (
  dispatch: Dispatch,
) => ({
  couponActions: bindActionCreators(couponsActionCreators, dispatch),
  displayActions: bindActionCreators(DisplayActionCreators, dispatch),
})

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>

const QuestionnaireController = (props: IAllProps) => {
  const contentToDisplay = props.questionnaireContent

  if (contentToDisplay === QuestionnaireContentPage.EmployeeDepartment && props.departmentQuestion) {
    return <EmployeeDepartmentQuestionPage question={props.departmentQuestion} />
  } else if (contentToDisplay === QuestionnaireContentPage.Questionnaire) return <QuestionList />
  else if (contentToDisplay === QuestionnaireContentPage.CouponRetrieve) return <CouponRetrieve />
  else if (contentToDisplay === QuestionnaireContentPage.LastPage) return <LastPage />
  else if (contentToDisplay === QuestionnaireContentPage.TellUsMore) return <TellUsMore />
  else if (contentToDisplay === QuestionnaireContentPage.OnTheGoLastPage) return <OnTheGoLastPage />
  else return <div />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionnaireController)
