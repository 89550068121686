const local = 'http://localhost:61968/'

const servers = [
  { hostname: 'es.changent.io', associatedServer: 'https://app.changent.io/' },
  { hostname: 'es.staging.beslogic.com', associatedServer: 'https://staging.beslogic.com/' },
  { hostname: 'es.matrix.beslogic.com', associatedServer: 'https://matrix.beslogic.com/' },
]

export const getConfig = () => new Promise<string>((resolve, reject) => {
  const currentHostname = window.location.hostname

  servers.forEach(server => {
    if (server.hostname === currentHostname) resolve(server.associatedServer)
  })

  resolve(local)
})
