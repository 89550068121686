import { faPlug, faPrint, faWifi } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { IApplicationState } from '../../Store'
import { actionCreators as ContextSelectionActionCreators, usernameRegex } from '../../Store/ContextSelection'
import { actionCreators as DisplayActionCreators } from '../../Store/Display'
import { questionnaireActionCreators } from '../../Store/Questionnaire/Questionnaire'
import { bindActionCreators } from '../../utils/bindActionCreators'
import Button from '../Shared/Button'
import ShellConnecter from './../../dependencies/shell-connecter'
import InputTextField from './InputTextField'

const statusIconContainerStyle: CSSProperties = {
  position: 'absolute',
  right: '5%',
  top: '5%',
  width: '15%',
  display: 'flex',
  justifyContent: 'space-evenly',
}

const mapStateToProps = (state: IApplicationState) => {
  return {
    authState: state.auth,
    connectionState: state.connection,
    languageState: state.language,
    contextSelectionState: state.contextSelection,
    questionnaireState: state.questionnaire,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => ({
  contextSelectionActions: bindActionCreators(ContextSelectionActionCreators, dispatch),
  displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  questionnaireActions: bindActionCreators(questionnaireActionCreators, dispatch),
})

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const LoginForm = (props: IAllProps) => {
  const [serverAddress, setServerAddress] = useState(props.contextSelectionState.serverAddress)
  const [username, setUsername] = useState(props.contextSelectionState.user.username)
  const [password, setPassword] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const [isDeviceIdDisplayed, setIsDeviceIdDisplayed] = useState(false)

  const doLogin = () => {
    props.contextSelectionActions.login(serverAddress, username, password)
      .then(() => props.displayActions.changeModalPage(1))
  }

  const forceUpdateQuestionnaire = () => {
    const questionnaireId = props.questionnaireState.questionnaireInfo.id

    if (questionnaireId === -1 || !props.authState.currentAuth) return

    props.questionnaireActions.changeQuestionnaire({ key: questionnaireId })
      .then(() => {
        props.displayActions.changeModalIsDisplayed(false)
      })
  }

  useEffect(() => {
    ShellConnecter.device.getDeviceId().then((id: string) => setDeviceId(id))
  }, [])

  return (
    <>
      <div style={{ ...statusIconContainerStyle }}>
        <FontAwesomeIcon icon={faPlug} color={props.connectionState.isConnectedToShell ? 'limegreen' : 'red'} />
        <FontAwesomeIcon icon={faWifi} color={props.connectionState.isOnline ? 'limegreen' : 'red'} />
        <FontAwesomeIcon icon={faPrint} color={props.connectionState.isPrinterAvailable ? 'limegreen' : 'red'} />
      </div>
      <div style={{ height: '20%' }}>
        <h2 style={{ margin: 0 }}><FormattedMessage id='login' /></h2>
      </div>
      <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
        {
          process.env.REACT_APP_DEPLOYMENT_MODE === 'development' &&
          <InputTextField
            name='serverAddress'
            placeHolder='server-address'
            required={true} type='text'
            color='#ffa726'
            onChange={event => setServerAddress(event.currentTarget.value)}
            defaultValue={serverAddress} />
        }
        <InputTextField
          name='userName'
          placeHolder='username'
          required={true}
          type='text'
          color='#ffa726'
          onChange={event => setUsername(event.currentTarget.value)}
          defaultValue={username}
          validationRegex={{ regex: usernameRegex, errorMessage: 'Adresse courriel invalide' }} />
        <InputTextField
          name='password'
          placeHolder='password'
          required={true}
          type='password'
          color='#ffa726'
          onChange={event => setPassword(event.currentTarget.value)}
          defaultValue={password}
          submitOnEnterDown={() => doLogin()}
          style={{ marginBottom: 0 }} />
        <p
          style={{ margin: '5px 0', textAlign: 'center', opacity: isDeviceIdDisplayed ? 100 : 0 }}
          onClick={() => setIsDeviceIdDisplayed(!isDeviceIdDisplayed)}>
          <u>{deviceId}</u>
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={() => forceUpdateQuestionnaire()}
            style={{ width: '45%', fontWeight: 'bold', height: '5vh' }}
          >
            <FormattedMessage id='force-update' />
          </Button>
          <Button
            onClick={() => doLogin()}
            style={{ width: '45%', fontWeight: 'bold', height: '5vh' }}
          >
            <FormattedMessage id='login-action' />
          </Button>
        </div>
      </div>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm)
