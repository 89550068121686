import ILogin from '../models/ILogin'
import IToken from '../models/IToken'
import IUser from '../models/IUser'
import { post } from './fetch'

export const login = (form: ILogin): Promise<(IUser & IToken)> =>
  post({
    hostname: form.serverAddress,
    path: 'Login',
    sendTokenInRequest: false,
    data: form,
  })
    .then(res => {
      if (res.status === 200) {
        return res.json()
      } else if (res.status === 400) {
        return Promise.reject(res)
      }
    })
