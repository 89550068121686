import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import '../../../css/phone-dial.css'
import '../../../css/punch.css'
import { IApplicationState } from '../../../Store'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../../Store/Display'
import { currentAnswerActionCreators } from '../../../Store/Questionnaire/CurrentAnswer'
import { bindActionCreators } from '../../../utils/bindActionCreators'
import Button from '../../Shared/Button'

const mapStateToProps = (state: IApplicationState) => ({
  isOnTheGo: state.onTheGo.isOnTheGo,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    isPopup: boolean,
    close: () => void,
  }

const RetrieveCouponsSMS = (props: IAllProps) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [buttons] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0])

  const doMapDialButton = (value: number, index: number) =>
    <button
      key={index}
      id={`d${value}`}
      className='dial-button'
      onClick={() => {
        if (phoneNumber.length <= 9) {
          setPhoneNumber(current => current.concat(value.toString()))
        }
      }}
    >
      {value}
    </button>

  useEffect(() => {
    return () => {
      if (phoneNumber.length !== 10) return

      props.currentAnswerActions.changePhoneNumer(phoneNumber)
    }
  }, [phoneNumber])

  const maskPhoneNumber = () => {
    const convert = (value: string | undefined) => value ? value : <>&ensp;</>

    return (
      <span style={{ color: 'inherit', fontFamily: 'inherit' }}>
        <span
          style={{ marginRight: '1vh', borderBottom: '2px solid' }}
        >
          ({convert(phoneNumber[0])}{convert(phoneNumber[1])}{convert(phoneNumber[2])})
        </span>&ensp;
        <span
          style={{ borderBottom: '2px solid' }}
        >
          {convert(phoneNumber[3])}{convert(phoneNumber[4])}{convert(phoneNumber[5])}
        </span>-
        <span
          style={{ borderBottom: '2px solid' }}
        >
          {convert(phoneNumber[6])}{convert(phoneNumber[7])}{convert(phoneNumber[8])}{convert(phoneNumber[9])}
        </span>
      </span>
    )
  }

  const next = () => {
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.LastPage)
    props.close()
  }

  const backSpace = () => {
    if (!phoneNumber) return

    setPhoneNumber(phoneNumber.slice(0, -1))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontSize: '3vh',
      }}
    >
      <p className='coupon-retrieve-sms-title' style={{ margin: 0 }}>
        <FormattedMessage id='coupon-online-sms' />
      </p>
      <div
        style={{
          width: '90%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p className='dial-phone-number' style={{ margin: '1vh', color: 'var(--ButtonPrimary)' }}>
          {
            phoneNumber.length === 0
              ? <FormattedMessage id='empty-phone-number' />
              : maskPhoneNumber()
          }
        </p>
      </div>
      <div
        style={{ margin: 'inherit' }}
        className='dial-container'
      >
        {
          buttons.map(doMapDialButton)
        }
        <button id='d10' className='dial-button' onClick={backSpace}>
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
        </button>
      </div>
      {
        props.isPopup &&
        <div style={{ marginTop: '3vh' }}>
          {
            (!props.isOnTheGo || phoneNumber.length !== 10) &&
            <Button
              style={{
                fontSize: '3vh',
                fontWeight: 'bold',
                margin: '10px',
              }}
              onClick={props.close}
            >
              <FormattedMessage id='back' />
            </Button>
          }
          {
            phoneNumber.length === 10 &&
            <Button
              style={{
                fontSize: '3vh',
                fontWeight: 'bold',
                margin: '10px',
              }}
              onClick={next}
            >
              <FormattedMessage id='send' />
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetrieveCouponsSMS)
