import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { IApplicationState } from '../../../../Store'
import EmployeePictureSelection from './EmployeePictureSelection'
import './EmployeeSelection.css'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    display: state.display.punchProgressionPage,
    language: state.language,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const EmployeeContextSelection = (props: IAllProps) =>
  <div className='selection-page'>
    <h2 style={{ fontSize: '3vh' }}>
      <FormattedMessage id='punch-employee-selection' />
    </h2>
    <EmployeePictureSelection />
  </div>

export default connect(
  mapStateToProps,
  null,
)(EmployeeContextSelection)
