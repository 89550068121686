// tslint:disable: max-line-length
import { Coupon, CouponType, IAppreciationCouponsServer, IEvangelistCouponsServer, IServiceRecoveryCouponsServer } from '../models/ICoupon'
import { IAuth } from '../Store/Auth'
import { get } from './fetch'
// tslint:enable: max-line-length

interface ICouponServerResponse {
  appreciationCoupons: IAppreciationCouponsServer[]
  evangelistCoupons: IEvangelistCouponsServer[]
  serviceRecoveryCoupons: IServiceRecoveryCouponsServer[]
}

const mappingAppreciationCoupons = (appreciationsCoupons: IAppreciationCouponsServer[]) =>
  appreciationsCoupons.map((coupon: IAppreciationCouponsServer) => ({
    id: coupon.id,
    couponType: CouponType.AppreciationCoupon,
    alwaysPrintWhenConditionMet: coupon.alwaysPrintWhenConditionMet,
    couponExpires: coupon.couponExpires,
    barCodeValue: coupon.barCodeValue,
    daysValidAmount: coupon.daysValidAmount,
    displayOrder: coupon.displayOrder,
    organizationLogo: coupon.organizationLogo,
    resourcesList: new Map(
      coupon
        .resourcesList
        .map(res =>
          [
            res.culture,
            {
              body: res.body,
              footer: res.footer,
            },
          ] as [string, { body: string, footer: string }],
        ),
    ),
    printInterval: coupon.printInterval,
    printed: undefined,
    success: undefined,
  }))

const mappingEvangelistCoupons = (evangelistCoupons: IEvangelistCouponsServer[]) =>
  evangelistCoupons.map((coupon: IEvangelistCouponsServer) => ({
    id: coupon.id,
    couponType: CouponType.EvangelistCoupon,
    alwaysPrintWhenConditionMet: coupon.alwaysPrintWhenConditionMet,
    couponExpires: coupon.couponExpires,
    barCodeValue: coupon.barCodeValue,
    daysValidAmount: coupon.daysValidAmount,
    displayOrder: coupon.displayOrder,
    organizationLogo: coupon.organizationLogo,
    resourcesList: new Map(
      coupon
        .resourcesList
        .map(res =>
          [
            res.culture,
            {
              body: res.body,
              footer: res.footer,
            },
          ] as [string, { body: string, footer: string }],
        ),
    ),
    questionIds: coupon.questionIds,
    printed: undefined,
    success: undefined,
  }))

const mappingServiceRecoveryCoupons = (serveRecoveryCoupons: IServiceRecoveryCouponsServer[]) =>
  serveRecoveryCoupons.map((coupon: IServiceRecoveryCouponsServer) => ({
    id: coupon.id,
    couponType: CouponType.ServiceRecoveryCoupon,
    alwaysPrintWhenConditionMet: coupon.alwaysPrintWhenConditionMet,
    couponExpires: coupon.couponExpires,
    barCodeValue: coupon.barCodeValue,
    daysValidAmount: coupon.daysValidAmount,
    displayOrder: coupon.displayOrder,
    organizationLogo: coupon.organizationLogo,
    resourcesList: new Map(
      coupon
        .resourcesList
        .map(res =>
          [
            res.culture,
            {
              body: res.body,
              footer: res.footer,
            },
          ] as [string, { body: string, footer: string }],
        ),
    ),
    questionIds: coupon.questionIds,
    printed: undefined,
    success: undefined,
  }))

export const mapCouponServerResponse = (
  coupon: ICouponServerResponse,
): Coupon[] => [
    ...mappingAppreciationCoupons(coupon.appreciationCoupons),
    ...mappingEvangelistCoupons(coupon.evangelistCoupons),
    ...mappingServiceRecoveryCoupons(coupon.serviceRecoveryCoupons),
  ]

export const getCoupons = (id: number, url?: string, auth?: IAuth): Promise<Coupon[]> =>
  get({
    hostname: url,
    path: `experiencestreams/${id}/coupons`,
    auth,
  })
    .then(res => res.json())
    .then(mapCouponServerResponse)

export const getImage = (imageId: string) =>
  !!imageId
    ? get({ path: `images/${imageId}` })
      .then(res => {
        return res.arrayBuffer().then(buffer => {
          return `data:${res.headers.get('content-type')};base64,${arrayBufferToBase64(buffer)}`
        })
      })
    : Promise.resolve('')

const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = ''
  const bytes = [].slice.call(new Uint8Array(buffer))

  bytes.forEach(b => binary += String.fromCharCode(b))

  return window.btoa(binary)
}
