import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import '../../../../css/punch.css'
import { IApplicationState } from '../../../../Store'
import Clock from './Clock'

const mapStateToProps = (state: IApplicationState, props: { params: string }) => {
  return {
    clock: state.questionnaire.punch.clock,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {

  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const CodeClock = (props: IAllProps) =>
  <div className='connection-clock'>
    <div style={{ width: '60%', height: '60%' }}>
      <Clock />
    </div>
  </div>

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CodeClock)
