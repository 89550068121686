import he from 'he'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import '../../css/button.css'
import '../../css/lastpage.css'
import { actionCreators as DisplayActionCreators, QuestionnaireContentPage } from '../../Store/Display'
import { IApplicationState } from '../../Store/index'
import { currentAnswerActionCreators } from '../../Store/Questionnaire/CurrentAnswer'
import { emptyLastPageResource, lastPageActionCreators } from '../../Store/Questionnaire/LastPage'
import { bindActionCreators } from '../../utils/bindActionCreators'
import { generateCode } from '../../utils/random'
import { tellUsMoreUrl } from '../../utils/url'
import Button from '../Shared/Button'

const mapStateToProps = (state: IApplicationState) => {
  return {
    lastPageInfo: state.questionnaire.lastPage,
    currentLanguage: state.language.currentLanguage,
    responseTime: state.questionnaire.currentAnswer.responseTime,
    couponToSend: state.questionnaire.coupons.couponsToRetrieve,
    serverAddress: state.contextSelection.serverAddress,
    questionnaireId: state.questionnaire.questionnaireInfo.id,
    isOnTheGo: state.onTheGo.isOnTheGo,
    isPrinterAvailable: state.connection.isPrinterAvailable,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
    lastPageActions: bindActionCreators(lastPageActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

const LastPage = (props: IAllProps) => {
  const [responseTime] = useState(props.responseTime)

  const lastPageInfoTextLines = props
    .lastPageInfo
    .resourcesMap
    .get(props.currentLanguage) ||
    emptyLastPageResource

  const exit = () => {
    props.currentAnswerActions.sendAnswers(props.couponToSend)
    props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
  }

  const changeToTellUsMore = () => {
    const code = generateCode(props.questionnaireId)
    props.lastPageActions.changeTellUsMoreSurveyKey(code)

    const answerPromise = props
      .currentAnswerActions
      .sendAnswers(props.couponToSend)

    if (props.isOnTheGo) {
      answerPromise.then(() => {
        const url = tellUsMoreUrl(props.serverAddress, code)
        const opener = window.open()

        if (opener != null) {
          opener.location.href = url
        } else {
          window.location.href = url
        }

        props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.Questionnaire)
      })
    } else {
      props.displayActions.changeQuestionnaireContent(QuestionnaireContentPage.TellUsMore)
    }
  }

  return (
    <div className='lastpage-container'>
      <div style={{ width: '90%' }}>
        <p style={{ margin: 0 }}>
          <FormattedMessage id='that-took'>
            {
              msg =>
                <span>
                  {msg} <span style={{ color: 'var(--GeneralFontColor)' }}>{responseTime || 1} sec.</span>
                </span>
            }
          </FormattedMessage>
        </p>
        <p style={{ margin: 0 }}>
          {he.decode(lastPageInfoTextLines.thankYouComment)}
        </p>
      </div>
      <div
        className='more-input-questions'
      >
        {
          props.lastPageInfo.tellUsMoreActivated &&
          <p style={{ margin: '0' }}>
            <FormattedMessage id='more-inputs-question' />
          </p>
        }
        <div
          className='lastpage-options'
        >
          <Button
            onClick={exit}
          >
            <FormattedMessage id='exit' />
          </Button>
          {
            props.lastPageInfo.tellUsMoreActivated &&
            <Button
              onClick={() => changeToTellUsMore()}
            >
              <FormattedMessage id='more-inputs' />
            </Button>
          }
        </div>
      </div>
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LastPage)
