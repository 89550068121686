import React, { useState } from 'react'
import { connect } from 'react-redux'

import '../../../../css/languageDropdown.css'
import { IApplicationState } from '../../../../Store'
import LanguageDropdownOption from './LanguageDropdownOption'

const mapStateToProps = (state: IApplicationState) => {
  return {
    language: state.language,
    media: state.display.media,
  }
}

type IAllProps = ReturnType<typeof mapStateToProps>

const LanguageDropdown = (props: IAllProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasMultipleLanguages = props.language.availableLanguages.length > 1

  return (
    <div
      className='dropdown-content'
    >
      {
        isOpen || props.media.isAtLeastTablet
          ? props
            .language
            .availableLanguages
            .concat()
            .sort((a, _) => {
              if (props.media.isAtLeastTablet) return 0
              return a === props.language.currentLanguage ? -1 : 1
            })
            .map(lang =>
              <LanguageDropdownOption
                key={lang}
                lang={lang}
                isOpen={true}
                onClick={() => setIsOpen(false)}
              />,
            )
          : <LanguageDropdownOption
            lang={props.language.currentLanguage}
            isOpen={isOpen || !hasMultipleLanguages}
            onClick={() => setIsOpen(true)}
          />
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
)(LanguageDropdown)
