export const retrieveCouponUrl = (
  address: string,
  clientResponseGuid: string,
) => address.endsWith('/')
    ? `${address}c/${clientResponseGuid}`
    : `${address}/c/${clientResponseGuid}`

export const tellUsMoreUrl = (
  address: string,
  surveyKey: string,
) => address.endsWith('/')
    ? `${address}tell-us-more/${surveyKey}`
    : `${address}/tell-us-more/${surveyKey}`
