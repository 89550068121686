import { Action, AnyAction, Reducer } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { IApplicationState } from '.'

export interface IAuth {
  token: string
  expiration: string
  refreshToken: string
  refreshTokenExpiration: Date
}

export interface IAuthState {
  currentAuth: IAuth | undefined
}

interface IChangeCurrentAuth {
  type: 'CHANGE_CURRENT_AUTH'
  auth: IAuth
}

export type KnownAction = IChangeCurrentAuth

export const authActionCreators = {
  changeCurrentAuth: (auth: IAuth) =>
    (dispatch: ThunkDispatch<AnyAction, IApplicationState, any>, getState: () => IApplicationState) =>
      new Promise((resolve, reject) => {
        dispatch({ type: 'CHANGE_CURRENT_AUTH', auth })
        resolve()
      }),
}

const defaultState: IAuthState = {
  currentAuth: undefined,
}

export const reducer: Reducer<IAuthState> = (state: IAuthState | undefined, incomingAction: Action): IAuthState => {
  if (state === undefined) return defaultState

  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'CHANGE_CURRENT_AUTH':
      return {
        ...state,
        currentAuth: action.auth,
      }
    default:
      return state
  }
}
