import * as Auth from './Auth'
import * as Connection from './Connection/Connection'
import * as ContextSelection from './ContextSelection'
import * as Display from './Display'
import * as Language from './Language'
import * as OnTheGo from './OnTheGo'
import * as Questionnaire from './Questionnaire/Questionnaire'
import * as UserClick from './UserClick'

export interface IApplicationState {
  auth: Auth.IAuthState
  connection: Connection.IConnectionState
  contextSelection: ContextSelection.IContextSelectionState
  display: Display.IDisplayState
  language: Language.ILanguageState
  questionnaire: Questionnaire.IQuestionnaireState
  userClick: UserClick.IUserClickState
  onTheGo: OnTheGo.IOnTheGoState
}

export const reducers = {
  auth: Auth.reducer,
  connection: Connection.reducer,
  contextSelection: ContextSelection.reducer,
  display: Display.reducer,
  language: Language.reducer,
  questionnaire: Questionnaire.reducer,
  userClick: UserClick.reducer,
  onTheGo: OnTheGo.reducer,
}
