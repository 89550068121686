import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import '../../../../css/coupon-preview.css'
import { IApplicationState } from '../../../../Store'
import { actionCreators as DisplayActionCreators } from '../../../../Store/Display'
import { bindActionCreators } from '../../../../utils/bindActionCreators'
import Button from '../../../Shared/Button'
import CouponPreview from './CouponPreview'

const mapStateToProps = (state: IApplicationState) => {
  return {
    displayState: state.display,
    coupons: state.questionnaire.coupons.coupons,
    couponsToRetrieve: state.questionnaire.coupons.couponsToRetrieve,
    media: state.display.media,
    isPrintAvailable: state.connection.isPrinterAvailable,
    isOnTheGo: state.onTheGo.isOnTheGo,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    close: () => void | undefined,
  }

const RetrieveCouponPicture = (props: IAllProps) => {
  const [selectedCouponIndex, setSelectedCouponIndex] = useState(0)

  const nextCoupon = () => setSelectedCouponIndex(
    selectedCouponIndex + 1 > props.couponsToRetrieve.length - 1
      ? 0
      : selectedCouponIndex + 1,
  )
  const previousCoupon = () => setSelectedCouponIndex(
    selectedCouponIndex - 1 < 0
      ? props.couponsToRetrieve.length - 1
      : selectedCouponIndex - 1,
  )

  return (
    <div className='coupon-retrieve-preview'>
      <div className='coupons-container'>
        {
          props
            .coupons
            .filter(x => props.couponsToRetrieve.some(y => x.id === y.couponId))
            .filter((x, i) => i === selectedCouponIndex)
            .map(coupon => <CouponPreview key={coupon.id} coupon={coupon} />)
        }
      </div>
      {
        !props.isOnTheGo &&
        <p style={{ margin: 0, fontSize: '4vh' }}>
          <FormattedMessage id={props.isPrintAvailable
            ? 'coupon-online-picture-printing'
            : 'coupon-online-picture'}
          />
        </p>
      }
      {
        <div className='coupon-preview-options'>
          {
            props.couponsToRetrieve.length > 1 &&
            <Button
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => { previousCoupon() }}
            >
              ←
            </Button>
          }
          <Button
            style={{
              fontWeight: 'bold',
            }}
            onClick={() => { props.close() }}
          >
            <FormattedMessage id='close' />
          </Button>
          {
            props.couponsToRetrieve.length > 1 &&
            <Button
              style={{
                fontWeight: 'bold',
              }}
              onClick={() => { nextCoupon() }}
            >
              →
            </Button>
          }
        </div>
      }
    </div>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RetrieveCouponPicture)
