import ShellConnecter from '../dependencies/shell-connecter'
// tslint:disable-next-line: max-line-length
import { mapAnswerQuestionToLocalStorage, mapEmployeeDepartmentQuestionToLocalStorage, mapPictureQuestionToLocalStorage } from '../fetchers/responses'
import { EsColors } from '../models/colors/IEsColors'
import IAnswerChoiceQuestion from '../models/IAnswerChoiceQuestion'
import { IPictureQuestion } from '../models/IPictureQuestion'
// tslint:disable-next-line: max-line-length
import { IAnswerQuestion, IEmployeeDepartmentQuestion, typeOfAnswerQuestion, typeOfPictureQuestion } from '../models/IQuestion'
import { IApplicationState } from '../Store'

export const read = (key: string, ls = false) => {
  if (ls) {
    const value = localStorage.getItem(key)

    if (value == null) return Promise.resolve(value)

    try {
      return Promise.resolve(JSON.parse(value))
    } catch {
      return Promise.resolve(value)
    }
  } else {
    const readPromise = ShellConnecter.storage.read(key)

    if (!readPromise) return Promise.resolve()

    try {
      if (typeof readPromise !== 'string') {
        return (readPromise as Promise<any>).then(res => {
          try {
            return Promise.resolve(JSON.parse(res))
          } catch {
            return Promise.resolve(res)
          }
        })
      } else {
        return Promise.resolve(JSON.parse(readPromise))
      }
    } catch {
      return Promise.resolve()
    }
  }
}

export const save = <T>(key: string, value: T, ls = false) => {
  if (ls) {
    typeof value === 'string'
      ? localStorage.setItem(key, value)
      : localStorage.setItem(key, JSON.stringify(value))
    return Promise.resolve()
  } else {
    const savePromise = ShellConnecter.storage.write(key, value)

    return typeof savePromise === typeof Promise
      ? savePromise as Promise<void>
      : Promise.resolve()
  }
}

export const remove = (key: string, ls = false) => {
  if (ls) {
    localStorage.removeItem(key)
    return Promise.resolve()
  } else {
    const removePromise = ShellConnecter.storage.remove(key)

    return typeof removePromise === typeof Promise
      ? removePromise as Promise<void>
      : Promise.resolve()
  }
}

export const loadState = () => {
  try {
    const savedState = localStorage.getItem('state')
    return savedState ? formatStateForApp(JSON.parse(savedState)) : undefined
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: IApplicationState) => {
  localStorage.setItem('state', JSON.stringify(formatStateForStorage(state)))
}

const formatStateForApp = (state: IApplicationState): IApplicationState => ({
  ...state,
  contextSelection: {
    ...state.contextSelection,
    organization: state.contextSelection.organization ?
      state.contextSelection.organization :
      {
        id: -1,
        name: '',
      },
  },
  language: state.language,
  questionnaire: {
    ...state.questionnaire,
    coupons: {
      ...state.questionnaire.coupons,
      coupons: state.questionnaire.coupons.coupons.map(coupon => ({
        ...coupon,
        resourcesList: new Map(coupon.resourcesList),
      })),
    },
    lastPage: {
      ...state.questionnaire.lastPage,
      resourcesMap: new Map(state.questionnaire.lastPage.resourcesMap),
    },
    questions: {
      ...state.questionnaire.questions,
      questions: state.questionnaire.questions.questions.map(question => {
        if (typeOfAnswerQuestion(question)) {
          return mapAnswerChoiceQuestion(question)
        } else if (typeOfPictureQuestion(question)) {
          return mapPictureQuestion(question)
        } else {
          return mapEmployeeDepartmentQuestion(question)
        }
      }),
    },
    style: {
      style: state.questionnaire.style.style
        ? new EsColors(state.questionnaire.style.style)
        : undefined,
    },
    punch: {
      employeeList: state.questionnaire.punch.employeeList,
      punchedEmployeeList: state.questionnaire.punch.punchedEmployeeList,
      clock: state.questionnaire.punch.clock,
      selectedEmployee: undefined,
      punchedEmployeeRatings: undefined,
      accessCode: '',
    },
  },
})

const formatStateForStorage = (state: IApplicationState) => ({
  contextSelection: {
    ...state.contextSelection,
  },
  language: state.language,
  questionnaire: {
    coupons: {
      ...state.questionnaire.coupons,
      coupons: state.questionnaire.coupons.coupons.map(coupon => ({
        ...coupon,
        resourcesList: Array.from(coupon.resourcesList),
      })),
    },
    lastPage: {
      ...state.questionnaire.lastPage,
      resourcesMap: Array.from(state.questionnaire.lastPage.resourcesMap),
    },
    questionnaireInfo: {
      ...state.questionnaire.questionnaireInfo,
    },
    questions: {
      ...state.questionnaire.questions,
      questions: state.questionnaire.questions.questions.map(question => {
        if (typeOfAnswerQuestion(question)) {
          return mapAnswerQuestionToLocalStorage(question)
        } else if (typeOfPictureQuestion(question)) {
          return mapPictureQuestionToLocalStorage(question)
        } else {
          return mapEmployeeDepartmentQuestionToLocalStorage(question)
        }
      }),
    },
    style: {
      style: state.questionnaire.style.style,
    },
    punch: {
      employeeList: state.questionnaire.punch.employeeList,
      punchedEmployeeList: state.questionnaire.punch.punchedEmployeeList,
      clock: state.questionnaire.punch.clock,
    },
  },
  auth: {
    ...state.auth,
  },
})

const mapAnswerChoiceQuestion = (question: IAnswerQuestion): IAnswerChoiceQuestion => ({
  ...question,
  name: new Map(question.name),
  answerChoices: question.answerChoices.map(answer => (
    {
      ...answer,
      name: new Map(answer.name),
      report: new Map(answer.report),
    }
  )),
})

const mapPictureQuestion = (question: IPictureQuestion): IPictureQuestion => ({
  ...question,
  name: new Map(question.name),
  pictures: question.pictures.map(picture => {
    // TODO (olibou) : Fix type for the return value being different of the actual type
    let returnPicture: any = {
      ...picture,
    }

    if (!(typeof picture.name === 'string')) {
      returnPicture = {
        ...returnPicture,
        name: new Map(returnPicture.name),
      }
    }

    if (!(typeof picture.report === 'string')) {
      returnPicture = {
        ...returnPicture,
        report: new Map(returnPicture.report),
      }
    }

    return returnPicture
  }),
})

const mapEmployeeDepartmentQuestion = (question: IEmployeeDepartmentQuestion) => ({
  ...question,
  name: new Map(question.name),
  groups: question.groups.map(x => ({
    id: x.id,
    title: new Map(x.title),
  })),
})
