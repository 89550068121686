import { Action, Reducer } from 'redux'

export interface IUserClickState {
  userClick: boolean
}

export interface IChangeUserClick {
  type: 'CHANGE_USER_CLICK'
  userClick: boolean
}

export type KnownAction = IChangeUserClick

export const actionCreators = {
  userClick: (userClick: boolean) =>
    ({ type: 'CHANGE_USER_CLICK', userClick }),
}

const defaultState: IUserClickState = {
  userClick: false,
}

export const reducer: Reducer<IUserClickState> =
  (state: IUserClickState | undefined, incomingAction: Action): IUserClickState => {
    if (state === undefined) return defaultState

    const action = incomingAction as KnownAction
    switch (action.type) {
      case 'CHANGE_USER_CLICK':
        return {
          ...state,
          userClick: action.userClick,
        }
      default:
        return state
    }
  }
