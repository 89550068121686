import StyleKeyType from '../enums/StyleKeyType'
import { EsColor, IEsColorDto } from './IEsColor'

export class EsColors {
  public readonly systemColors: EsColor[]
  public readonly organizationColors: EsColor[]
  public readonly questionnaireColors: EsColor[]

  public readonly colorCount: number

  constructor(source: IEsColorsDto | EsColors) {

    if (typeOfEsColors(source)) {
      this.colorCount = source.colorCount
      this.systemColors = source.systemColors
      this.organizationColors = source.organizationColors
      this.questionnaireColors = source.questionnaireColors
    } else {
      this.colorCount = source.systemColors.length

      this.systemColors = source
        .systemColors
        .map(x => new EsColor(x))
        .sort((a, b) => a.keyType - b.keyType)

      this.organizationColors = this
        .fillOfNullOrColor(source.organizationColors)
        .sort((a, b) => a.keyType - b.keyType)

      this.questionnaireColors = this
        .fillOfNullOrColor(source.questionnaireColors)
        .sort((a, b) => a.keyType - b.keyType)
    }
  }

  public getColorForKey(keyType: StyleKeyType) {

    const colorForQuestionnaire = this.questionnaireColors.find(x => x.keyType === keyType)
    if (colorForQuestionnaire && colorForQuestionnaire.color) {
      return colorForQuestionnaire
    }

    const colorForOrganization = this.organizationColors.find(x => x.keyType === keyType)
    if (colorForOrganization && colorForOrganization.color) {
      return colorForOrganization
    }

    const colorForSystem = this.systemColors.find(x => x.keyType === keyType)
    if (colorForSystem && colorForSystem.color) {
      return colorForSystem
    }

    // if we can't find the key in the SystemColors throw an error
    throw new Error('Unsupported KeyType')
  }

  private fillOfNullOrColor = (colors: IEsColorDto[]) => {
    const array: EsColor[] = []
    for (let i = 0; i < this.colorCount; i++) {
      const color = colors.find(x => x.keyType === i)
      array.push(new EsColor({
        color: color ? color.color : null,
        id: color ? color.id : 0,
        keyType: i,
      }))
    }
    return array
  }
}

export interface IEsColorsDto {
  systemColors: IEsColorDto[]
  organizationColors: IEsColorDto[]
  questionnaireColors: IEsColorDto[]
}

const typeOfEsColors = (object: any): object is EsColors =>
  object.colorCount !== undefined
