import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { Dispatch } from 'redux'
import { Picture } from '../../../../../models/IPicture'
import { IPictureQuestion } from '../../../../../models/IPictureQuestion'
import { IApplicationState } from '../../../../../Store'
import { currentAnswerActionCreators } from '../../../../../Store/Questionnaire/CurrentAnswer'
import { actionCreators as SelectedPictureActionCreators } from '../../../../../Store/Questionnaire/SelectedPicture'
import { bindActionCreators } from '../../../../../utils/bindActionCreators'
import PictureCard from './PictureCard'
import ShadowBackground from './ShadowBackground'

const mapStateToProps = (state: IApplicationState) => {
  return {
    questionnaireState: state.questionnaire,
    answers: state.questionnaire.currentAnswer.answers,
    questions: state.questionnaire.questions.questions,
    selectedPicture: state.questionnaire.selectedPicture,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    selectedPictureActions: bindActionCreators(SelectedPictureActionCreators, dispatch),
    currentAnswerActions: bindActionCreators(currentAnswerActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    question: IPictureQuestion,
    picture: Picture,
    scrollableContainer: HTMLDivElement,
    selectedRef: (instance: HTMLDivElement | undefined) => void,
    questionMounted: boolean,
    readyForZoom: boolean,
    canAnswer: boolean,
    setReadyForZoom: (ready: boolean) => void,
  }

const PictureCardContainer = (props: IAllProps) => {
  const [pictureCardContainer] = useState(React.createRef<HTMLDivElement>())
  const [opacity, setOpacity] = useState(1)
  const [rating, setRating] = useState<number | undefined>(undefined)

  useEffect(() => {
    setRating(undefined)
  }, [props.answers])

  const setXYPos = () => {
    const container = pictureCardContainer.current

    if (!container) return

    const containerBounds = container.getBoundingClientRect()

    container.style.setProperty('--posX', `${containerBounds.left}px`)
    container.style.setProperty('--posY', `${containerBounds.top}px`)
  }

  const zoomIn = () => {
    if (props.selectedPicture.isAnimating) return

    setXYPos()

    props
      .selectedPictureActions
      .changeSelectedPicture({
        ...props.selectedPicture,
        id: props.picture.id,
        zoomed: true,
        displayShadowBg: true,
        oldOpacity: props.selectedPicture.opacity,
        opacity: 1,
      })
  }

  const zoomOut = () => {
    setXYPos()

    if (rating != null) {
      props
        .currentAnswerActions
        .changeAnswer(
          props.question,
          {
            ...props.picture,
            value: rating,
          },
        )
    }

    props.selectedPictureActions.changeSelectedPicture(
      {
        ...props.selectedPicture,
        zoomCompleted: false,
        isAnimating: true,
      },
    )
  }

  const zoomedOut = () => props
    .selectedPictureActions
    .changeSelectedPicture({
      ...props.selectedPicture,
      isAnimating: false,
    })

  const hideOnScroll = () => {
    const container = pictureCardContainer.current
    const scrollableContainer = props.scrollableContainer
    if (container && scrollableContainer) {
      const pictureElementBounds = container.getBoundingClientRect()
      const scrollableContainerBounds = scrollableContainer.getBoundingClientRect()

      let ratio = 1
      if (
        pictureElementBounds.top <
        scrollableContainerBounds.top
      ) {
        ratio =
          pictureElementBounds.top /
          scrollableContainerBounds.top
      } else if (
        pictureElementBounds.bottom >
        scrollableContainerBounds.bottom
      ) {
        ratio =
          1 - ((pictureElementBounds.bottom - scrollableContainerBounds.bottom) / 150)
      } else {
        ratio = 1
      }
      setOpacity(ratio)
    }
  }

  const doSetRating = (value: number | undefined) => {
    if (
      props.selectedPicture.isAnimating ||
      !props.selectedPicture.zoomCompleted
    ) {
      return
    }

    if (value != null) setRating(value)

    props
      .selectedPictureActions
      .changeSelectedPicture({
        ...props.selectedPicture,
        zoomed: false,
        displayShadowBg: false,
      })
  }

  useEffect(() => {
    if (
      pictureCardContainer.current &&
      props.selectedPicture.id === props.picture.id
    ) {
      props.selectedRef(pictureCardContainer.current)
    }

    if (props.scrollableContainer) {
      props.scrollableContainer.addEventListener('scroll', hideOnScroll)
      hideOnScroll()
    }
  }, [props.readyForZoom])

  useEffect(() => {
    if (props.selectedPicture.id === props.picture.id && props.readyForZoom) {
      setTimeout(() => zoomIn(), 250)
    }
  }, [props.readyForZoom])

  const onImageClick = () => {
    if (
      !props.questionnaireState.selectedPicture.zoomed &&
      !props.questionnaireState.selectedPicture.isAnimating &&
      props.questionnaireState.questions.questions &&
      props.answers.size >= props.questions.length - 1 &&
      props.canAnswer
    ) {
      props.selectedPictureActions.changeSelectedPicture({
        ...props.selectedPicture,
        displayShadowBg: !props.selectedPicture.displayShadowBg,
      })
      zoomIn()
    }
  }

  return (
    <>
      <ShadowBackground
        picture={props.picture}
      />
      <CSSTransition
        in={
          props.selectedPicture.zoomed &&
          props.selectedPicture.id === props.picture.id &&
          props.questionMounted
        }
        classNames='zoom'
        timeout={750}
        onEnter={() => {
          props
            .selectedPictureActions
            .changeSelectedPicture({
              ...props.selectedPicture,
              isAnimating: true,
            })
        }}
        onEntered={() => props.selectedPictureActions.changeSelectedPicture({
          ...props.selectedPicture,
          zoomCompleted: true,
          isAnimating: false,
        })}
        onExit={zoomOut}
        onExited={zoomedOut}
      >
        <div
          className='picture-card-container'
          onClick={onImageClick}
          style={{
            opacity: props.selectedPicture.id === props.picture.id
              ? 1
              : opacity,
          }}
          ref={pictureCardContainer}
        >
          <PictureCard
            question={props.question}
            picture={props.picture}
            setRating={doSetRating}
          />
        </div>
      </CSSTransition>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PictureCardContainer)
