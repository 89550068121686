import { ActionCreatorsMapObject, bindActionCreators as reduxBindActionCreators, Dispatch } from 'redux'

import { ThunkAction } from 'redux-thunk'

export const bindActionCreators = <A, M extends ActionCreatorsMapObject<A>>(
  actionCreators: M,
  dispatch: Dispatch,
): {
    [N in keyof M]: ReturnType<M[N]> extends ThunkAction<any, any, any, any>
    ? (...args: Parameters<M[N]>) => ReturnType<ReturnType<M[N]>>
    : M[N]
  } => reduxBindActionCreators(actionCreators, dispatch) as any
