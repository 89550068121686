// tslint:disable: max-line-length
import QuestionResultType from '../models/enums/QuestionResultType'
import { ICustomPicture, IEmployeePicture, Picture } from '../models/IPicture'
import { IPictureQuestion } from '../models/IPictureQuestion'
import { IAnswerChoice, IAnswerQuestion, IEmployeeDepartment, IEmployeeDepartmentQuestion, Question } from '../models/IQuestion'
import { IAuth } from '../Store/Auth'
import { get } from './fetch'
// tslint:enable: max-line-length

interface IQuestionServerResponse {
  id: number
  displayOrder: number
  resourcesList: Array<{
    name: string,
    culture: string,
  }>
  type: string
}

interface IAnswerQuestionServerResponse extends IQuestionServerResponse {
  answerChoices: IAnswerChoiceServerResponse[]
}

interface IEmployeeDepartmentServerResponse {
  id: number
  isDepartment: boolean
  resourcesList: Array<{
    title: string,
    culture: string,
  }>
  selectedCultures: string[]
}

interface IEmployeeDepartmenQuestionServerResponse extends IQuestionServerResponse {
  groups: IEmployeeDepartmentServerResponse[]
}

interface IAnswerChoiceServerResponse {
  id: number,
  answerChoiceType: number
  displayOrder: number
  resourcesList: Array<{
    name: string,
    culture: string,
    report: string,
  }>
}

interface IPictureServerResponse {
  id: number,
  displayOrder: number,
  imageSrc: number,
}

interface ICustomPictureServerResponse extends IPictureServerResponse {
  resourcesList: Array<{
    name: string,
    culture: string,
    report: string,
  }>
}

interface IEmployeePictureServerResponse extends IPictureServerResponse {
  name: string,
  employeeId: number,
}

interface IServerQuestionsResponse {
  questions: IQuestionServerResponse[]
}

interface IPictureQuestionServerResponse extends IQuestionServerResponse {
  pictures: Array<ICustomPictureServerResponse | IEmployeePictureServerResponse>
}

const isTypeOfAnswerQuestionServerResponse = (
  question: IQuestionServerResponse,
): question is IAnswerQuestionServerResponse => {
  return 'answerChoices' in question
}

const isTypeOfEmployeeDepartmentServerResponse = (
  question: IQuestionServerResponse,
): question is IEmployeeDepartmenQuestionServerResponse => {
  return 'groups' in question
}

const isTypeOfPictureQuestionServerResponse = (
  question: IQuestionServerResponse,
): question is IPictureQuestionServerResponse => {
  return 'pictures' in question
}

const isTypeOfEmployeePictureServerResponse = (
  picture: IPictureServerResponse,
): picture is IEmployeePictureServerResponse => {
  return 'name' in picture
}

const isTypeOfCustomPictureServerResponse = (
  picture: IPictureServerResponse,
): picture is ICustomPictureServerResponse => {
  return 'resourcesList' in picture
}

const answerQuestionMapper = (question: IAnswerQuestionServerResponse): IAnswerQuestion => {
  const nameMap =
    question.resourcesList.map(
      res => [res.culture, res.name]) as Array<[string, string]>

  return {
    id: question.id,
    name: new Map(nameMap),
    displayOrder: question.displayOrder,
    answerChoices: answerChoicesMapper(question.answerChoices),
  }
}

const employeeDepartmentQuestionMapper =
  (question: IEmployeeDepartmenQuestionServerResponse): IEmployeeDepartmentQuestion => {
    const nameMap =
      question.resourcesList.map(
        res => [res.culture, res.name]) as Array<[string, string]>

    return {
      id: question.id,
      displayOrder: question.displayOrder,
      groups: question.groups.map(x => ({
        id: x.id,
        title: new Map(x.resourcesList.map(
          res => [res.culture, res.title]) as Array<[string, string]>),
      })) as IEmployeeDepartment[],
      name: new Map(nameMap),
    }
  }

const employeePictureMapper = (picture: IEmployeePictureServerResponse) => ({
  type: QuestionResultType.EmployeePictureChoice,
  id: picture.id,
  employeeId: picture.employeeId,
  displayOrder: picture.displayOrder,
  imageSrc: picture.imageSrc,
  name: picture.name,
  report: picture.name,
}) as IEmployeePicture

const customPictureMapper = (picture: ICustomPictureServerResponse) => ({
  type: QuestionResultType.CustomPictureChoice,
  id: picture.id,
  displayOrder: picture.displayOrder,
  imageSrc: picture.imageSrc,
  name: new Map(
    picture
      .resourcesList
      .map(res => [res.culture, res.name]) as Array<[string, string]>,
  ),
  report: new Map(
    picture
      .resourcesList
      .map(res => [res.culture, res.report]) as Array<[string, string]>,
  ),
}) as ICustomPicture

const pictureQuestionMapper = (
  question: IPictureQuestionServerResponse,
): IPictureQuestion => {
  const nameMap = question
    .resourcesList
    .map(res => [res.culture, res.name]) as Array<[string, string]>

  const pictureMap = question
    .pictures
    .reduce(
      (acc, current) => {
        if (isTypeOfEmployeePictureServerResponse(current)) {
          return [
            ...acc,
            employeePictureMapper(current),
          ]
        }

        if (isTypeOfCustomPictureServerResponse(current)) {
          return [
            ...acc,
            customPictureMapper(current),
          ]
        }

        return acc
      },
      [] as Picture[],
    )

  return {
    id: question.id,
    name: new Map(nameMap),
    displayOrder: question.displayOrder,
    pictures: pictureMap,
  }
}

// TODO (alexsass) : This could be converted to a ".map()".
const answerChoicesMapper = (
  serverAnswerChoices: IAnswerChoiceServerResponse[],
): IAnswerChoice[] => {
  return serverAnswerChoices.reduce((answers, currentAnswer) => {
    const nameMap = currentAnswer
      .resourcesList
      .map(res => [res.culture, res.name]) as Array<[string, string]>

    const reportMap = currentAnswer
      .resourcesList
      .map(res => [res.culture, res.report]) as Array<[string, string]>

    const answer = {
      type: QuestionResultType.AnswerChoice,
      id: currentAnswer.id,
      name: new Map(nameMap),
      report: new Map(reportMap),
      answerChoiceType: currentAnswer.answerChoiceType,
      displayOrder: currentAnswer.displayOrder,
    }

    return [
      ...answers,
      answer,
    ]
  }, [] as IAnswerChoice[])
}

export const mapQuestionsServerResponse = (
  questionsResponse: IServerQuestionsResponse,
): Question[] =>
  questionsResponse
    .questions
    .reduce(
      (questions, currentQuestion) => {
        if (isTypeOfAnswerQuestionServerResponse(currentQuestion)) {
          const question = answerQuestionMapper(currentQuestion)
          return [
            ...questions,
            question,
          ]
        } else if (isTypeOfPictureQuestionServerResponse(currentQuestion)) {
          const question = pictureQuestionMapper(currentQuestion)
          return [
            ...questions,
            question,
          ]
        } else if (isTypeOfEmployeeDepartmentServerResponse(currentQuestion)) {
          const question = employeeDepartmentQuestionMapper(currentQuestion)
          return [
            ...questions,
            question,
          ]
        }

        return questions
      },
      [] as Question[],
    )

export const getQuestions = (id: number, url?: string, auth?: IAuth) =>
  get({
    hostname: url,
    path: `experiencestream/questions/${id}`,
    auth,
  })
    .then(res => res.json())
    .then(mapQuestionsServerResponse)
