import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { IApplicationState } from '../../Store'
import { actionCreators as DisplayActionCreators } from '../../Store/Display'
import ContextSelectionModal from '../Modal/ContextSelectionModal'

const mapStateToProps = (state: IApplicationState) => {
  return {
    displayState: state.display,
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
) => {
  return {
    displayActions: bindActionCreators(DisplayActionCreators, dispatch),
  }
}

type IAllProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & { children: React.ReactNode }

const Modal = (props: IAllProps) => {

  const [counter, setCounter] = useState(0)

  const content = <ContextSelectionModal />

  return props.displayState.modalIsDisplayed
    ? <div
      onClick={() => {
        if (counter >= 1) {
          props.displayActions.changeModalPage(0)
          props.displayActions.changeModalIsDisplayed(false)
          setCounter(0)
        } else {
          setCounter(c => c + 1)
        }
      }}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        zIndex: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <div
        onClick={event => event.stopPropagation()}
        style={{
          width: '30%',
          height: '50%',
          display: 'flex', flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {content}
      </div>
    </div>
    : <></>
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal)
