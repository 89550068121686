enum StyleKeyType {
  BackgroundPrimary,
  BackgroundSecondary,
  ButtonPrimary,
  ButtonSecondary,
  GeneralFontColor,
  ButtonFontColor,
}

export default StyleKeyType
