// tslint:disable: max-line-length
import { Coupon, IAppreciationCouponsClient, IEvangelistCouponsClient, IServiceRecoveryCouponsClient, typeofIAppreciationCoupons, typeofIEvangelistCoupons, typeofIServiceRecoveryCoupons } from '../models/ICoupon'
import { IAnswerChoice, Question, QuestionResult, QuestionResultSentiment, typeOfAnswerQuestion } from '../models/IQuestion'
// tslint:enable: max-line-length

export const verifyQuestionResultForCoupons = (
  responses: Map<Question, QuestionResult[]>,
  coupons: Coupon[],
  clientCount: number,
) => {
  const mappedCoupons = coupons.map(coupon => ({
    ...coupon,
    resourcesList: new Map(coupon.resourcesList),
  }))

  if (mappedCoupons.length > 0) {
    const results = Array.from(responses)
      .filter(questionnaireResult =>
        typeOfAnswerQuestion(questionnaireResult[0]) &&
        !!(questionnaireResult[1][0]),
      )
      .map(questionnaireResult => [
        questionnaireResult[0],
        questionnaireResult[1][0] as IAnswerChoice,
      ] as [Question, IAnswerChoice])

    const orderedCoupons = mappedCoupons
      .concat()
      .sort((a, b) => {
        if (a.displayOrder < b.displayOrder) return -1
        if (a.displayOrder > b.displayOrder) return 1
        return 0
      })

    const couponsThatNeedPrinting = orderedCoupons.reduce(
      (acc, current) => {
        return !verifyCoupon(current, results, clientCount, acc.length)
          ? [...acc]
          : [
            ...acc,
            current,
          ]
      },
      [] as Coupon[],
    )

    return couponsThatNeedPrinting
  }
  return []
}

export const verifyCoupon = (
  coupon: Coupon,
  questionResults: Array<[Question, IAnswerChoice]>,
  clientCount: number,
  couponsToPrintCount: number,
) =>
  (
    coupon.alwaysPrintWhenConditionMet ||
    couponsToPrintCount === 0
  ) &&
  (
    (
      typeofIAppreciationCoupons(coupon) &&
      verifyAppreciationCoupons(coupon, clientCount)
    ) ||
    (
      typeofIEvangelistCoupons(coupon) &&
      verifyEvangelistCoupons(coupon, questionResults)
    ) ||
    (
      typeofIServiceRecoveryCoupons(coupon) &&
      verifyServiceRecoveryCoupons(coupon, questionResults)
    )
  )

const verifyAppreciationCoupons = (
  coupon: IAppreciationCouponsClient,
  clientCount: number,
) => {
  if (coupon.printInterval === 0) return false

  return (clientCount % coupon.printInterval) === 0
}

const verifyEvangelistCoupons = (
  coupon: IEvangelistCouponsClient,
  questionResults: Array<[Question, IAnswerChoice]>,
) =>
  coupon.questionIds.length > 0 &&
  questionResults
    .some(questionResult =>
      coupon.questionIds.some(questionId =>
        questionId === questionResult[0].id &&
        questionResult[1].answerChoiceType === QuestionResultSentiment.Good),
    )

const verifyServiceRecoveryCoupons = (
  coupon: IServiceRecoveryCouponsClient,
  questionResults: Array<[Question, IAnswerChoice]>,
) =>
  coupon.questionIds.length > 0 &&
  questionResults
    .some(questionResult =>
      coupon.questionIds.some(questionId => questionId === questionResult[0].id &&
        questionResult[1].answerChoiceType === QuestionResultSentiment.Bad),
    )
