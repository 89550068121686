import React from 'react'
import { connect } from 'react-redux'
import { EsColor } from '../../models/colors/IEsColor'
import { EsColors } from '../../models/colors/IEsColors'
import { IApplicationState } from '../../Store'
import { isExperienceStreamColor } from '../../utils/color'
import Header from '../Header/Header'
import Modal from '../Modal/Modal'
import Content from './Content'

const mapStateToProps = (state: IApplicationState, _: { params: string }) => ({
  style: state.questionnaire.style,
})

type IAllProps =
  & ReturnType<typeof mapStateToProps>

const ViewController = (props: IAllProps) => {

  const style = props.style.style

  const isEsBackgroundNeeded = (colors: EsColors) => {
    const currentColors: EsColor[] = []
    for (let i = 0; i < colors.colorCount; i++) {
      currentColors.push(colors.getColorForKey(i))
    }

    return isExperienceStreamColor(currentColors)
  }

  const needEsBackground = !style
    ? false
    : isEsBackgroundNeeded(style)

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      className={needEsBackground ? 'es-background' : ''}
    >
      <Modal />
      <Header />
      <Content />
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
)(ViewController)
